import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../../../JsonQuery';
import { TFilter } from '../../../../types/filters';
import { IFilterList } from '../../types';

export const FILTERS_SUBURBAN: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.House),
    list: [
      'houseArea',
      'landArea',
      'price',
      'geo',
      // Мистическая галочка
      'fromMKAD',
      'suburbanHouseType',
      'suburbanFloors',
      'suburbanConstructionType',
      'landStatus',
      'suburbanBathroom', // Объединить
      'suburbanUtilities', // Объединить
      'possessor',
      'electronicTrading',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'houseArea',
      'landArea',
      'price',
      'geo',
      // Мистическая галочка
      'fromMKAD',
      'suburbanFloors',
      'suburbanConstructionType',
      'landStatus',
      'suburbanBathroom', // Объединить
      'suburbanUtilities', // Объединить
      'possessor',
      'electronicTrading',
      'containWords',
      'excludeWords',

      // OLD
      // 'suburbanFacilities',
      // 'bedrooms',
      // 'dealType',
      // 'undergroundTime',
      // 'heatingType',
      // 'suburbanBathroom',
      // 'constructionYear',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'rentalPeriod',
      'price',
      'houseArea',
      'landArea',
      'geo',
      'fromMKAD',
      'dealType',
      'rentTerms',
      'bedrooms',
      'suburbanFloors',
      'suburbanConstructionType',
      'electronicTrading',
      'containWords',
      'excludeWords',

      // OLD
      // 'undergroundTime',
      // 'facilities',
      // 'suburbanFacilities',
      // 'bathType',
      // 'suburbanBathroom',
      // 'repair',
      // 'heatingType',
      // 'constructionYear',
      // 'rentTerms',
      // 'livingConditions',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.House),
    list: [
      'price',
      'houseArea',
      'landArea',
      'geo',
      'fromMKAD',
      'dealType',
      'bedrooms',
      'suburbanFloors',
      'suburbanConstructionType',
      'electronicTrading',
      'containWords',
      'excludeWords',

      // OLD
      // 'undergroundTime',
      // 'facilities',
      // 'livingConditions',
      // 'suburbanFacilities',
      // 'bathType',
      // 'suburbanBathroom',
      // 'repair',
      // 'heatingType',
      // 'constructionYear',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Land),
    list: [
      'landArea',
      'price',
      'geo',
      'fromMKAD',
      'landStatus',
      'suburbanUtilities',
      'possessor',
      'electronicTrading',
      'containWords',
      'excludeWords',

      // OLD
      // 'undergroundTime',
      // 'houseArea',
    ],
  },
];

export const REDUCED_DAILYRENT_SUBURBAN_FILTERS: TFilter[] = [
  'dates',
  'onlineBooking',
  'beds',
  'price',
  'bedrooms',
  'geo',
  'livingConditions',
  'dealType',
  'suburbanFacilities',
  'suburbanBathroom',
  'houseArea',
  'suburbanFloors',
  'containWords',
];
