import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EBalconyType, EFacilityType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IFacility<T> {
  label: string;
  value: T;
}

const FACILITIES: IFacility<EFacilityType>[] = [
  { label: 'Кондиционер', value: EFacilityType.Conditioner },
  { label: 'Без мебели', value: EFacilityType.WithoutFurniture },
  { label: 'Интернет', value: EFacilityType.Internet },
  { label: 'Холодильник', value: EFacilityType.Fridge },
  { label: 'Комнатная мебель', value: EFacilityType.RoomFurniture },
  { label: 'Телефон', value: EFacilityType.Phone },
  { label: 'Посудомоечная машина', value: EFacilityType.Dishwasher },
  { label: 'Кухонная мебель', value: EFacilityType.KitchenFurniture },
  { label: 'Телевизор', value: EFacilityType.TV },
  { label: 'Стиральная машина', value: EFacilityType.Washer },
];

export const BATH_FACILITIES: IFacility<EFacilityType>[] = [
  { label: 'Ванна', value: EFacilityType.Bath },
  { label: 'Душ', value: EFacilityType.Shower },
];
export const BALCONY_FACILITIES: IFacility<EBalconyType>[] = [
  { label: 'Балкон', value: EBalconyType.Balcony },
  { label: 'Лоджия', value: EBalconyType.Loggia },
];

const DAILYRENT_FACILITIES: IFacility<EFacilityType | EBalconyType>[] = [
  { label: 'Холодильник', value: EFacilityType.Fridge },
  { label: 'Кондиционер', value: EFacilityType.Conditioner },
  { label: 'Мебель в комнатах', value: EFacilityType.RoomFurniture },
  { label: 'Кухня', value: EFacilityType.KitchenFurniture },
  { label: 'Стиральная машина', value: EFacilityType.Washer },
  { label: 'Интернет', value: EFacilityType.Internet },
  { label: 'Телевизор', value: EFacilityType.TV },
  { label: 'Посудомоечная машина', value: EFacilityType.Dishwasher },
  ...BALCONY_FACILITIES,
  ...BATH_FACILITIES,
];

interface IFacilitiesProps {
  value: NonEmptyArray<EFacilityType | EBalconyType> | null;
  showDailyrentFacilities?: boolean;
  onChange(value: NonEmptyArray<EFacilityType | EBalconyType> | null): void;
}

export const Facilities: React.FC<IFacilitiesProps> = ({ value, showDailyrentFacilities, onChange }) => {
  const facilities = showDailyrentFacilities ? DAILYRENT_FACILITIES : FACILITIES;

  const handleFacilitiesChange = (facilities: NonEmptyArray<EFacilityType | EBalconyType> | null) => {
    let nextFacilities = facilities;

    if (nextFacilities && value && nextFacilities.length > value.length) {
      const selectedFacility = nextFacilities[nextFacilities.length - 1];

      if (selectedFacility === EFacilityType.WithoutFurniture) {
        nextFacilities = nextFacilities.filter(
          v => ![EFacilityType.RoomFurniture, EFacilityType.KitchenFurniture].includes(v as EFacilityType),
        ) as NonEmptyArray<EFacilityType>;
      } else if (
        [EFacilityType.RoomFurniture, EFacilityType.KitchenFurniture].includes(selectedFacility as EFacilityType)
      ) {
        nextFacilities = nextFacilities.filter(
          v => v !== EFacilityType.WithoutFurniture,
        ) as NonEmptyArray<EFacilityType>;
      }
    }

    onChange(nextFacilities);
  };

  const filterTitle = showDailyrentFacilities ? 'В квартире есть' : 'Удобства';

  return (
    <Filter id="filter_facilities" label={filterTitle}>
      <CheckboxButtonGroup options={facilities} value={value} onChange={handleFacilitiesChange} />
    </Filter>
  );
};
