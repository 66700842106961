import * as React from 'react';

import { SaveSearchContainer as SaveSearch, useSaveSearchContext } from '../../../packages/SaveSearchModal';
import { SaveSearchButton } from '../../components/SaveSearchButton';

export const SaveSearchContainer: React.FC = () => {
  const { onOpen } = useSaveSearchContext();

  return (
    <>
      <SaveSearchButton onClick={onOpen} />
      <SaveSearch />
    </>
  );
};
