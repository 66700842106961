import * as React from 'react';

import { trackClick } from './tracking';
import { BannerApplicationDownload } from '../../components/BannerApplicationDownload';
import { useApplicationContext } from '../../utils/applicationContext';

export const BannerApplicationDownloadContainer: React.FC = () => {
  const {
    config,
    custom: { mobileDeviceType },
  } = useApplicationContext();
  const linkAppGallery = config.get<string>('applicationLinks.appGallery');
  const linkAppStore = config.get<string>('applicationLinks.appStore');
  const linkGooglePlay = config.get<string>('applicationLinks.googlePlay');
  const isBannerApplicationDownloadEnabled =
    config.get<boolean>('mobile-filters-frontend.BannerApplicationDownload.Enabled') || false;

  const handleClick = React.useCallback(() => {
    trackClick();
  }, []);

  if (!isBannerApplicationDownloadEnabled) {
    return null;
  }

  return (
    <BannerApplicationDownload
      mobileDeviceType={mobileDeviceType}
      linkAppGallery={linkAppGallery}
      linkAppStore={linkAppStore}
      linkGooglePlay={linkGooglePlay}
      onClick={handleClick}
    />
  );
};
