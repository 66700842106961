import { IApplicationState } from '../../types/redux';
import { ISpecialPromo } from '../../types/specialPromos';

export function selectCurrentSpecialPromo(state: IApplicationState): ISpecialPromo | null {
  return (
    state.specialPromos.items.find(
      specialPromo =>
        specialPromo.regionId === state.regionMeta.id ||
        (state.regionMeta.parentId && specialPromo.regionId === state.regionMeta.parentId),
    ) || null
  );
}
