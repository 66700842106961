import { ca } from '@cian/analytics';

export function trackClearClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'clean',
    label: '',
  });
}

export function trackBackClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'close',
    label: '',
  });
}
