import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../../../api-models/common/json_query';
import { ConstructionType } from '../../../components/filters/ConstructionType';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const ConstructionTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const constructionType = getTermsValue('house_material')(jsonQuery);

  const handleChange = React.useCallback(
    (constructionType: NonEmptyArray<EHouseMaterial> | null) => {
      onChange({ action: 'setConstructionTypes', arguments: [constructionType] });
    },
    [onChange],
  );

  return <ConstructionType offerType={offerType} value={constructionType} onChange={handleChange} />;
};
