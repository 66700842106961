import { ILogger } from '@cian/logger';
import loadable from '@loadable/component';
import * as React from 'react';

import { LoadableErrorBoundary, LoadableFallback } from '../../../../../../../LoadableHelpers';
import { ModalWindow } from '../../../../../../../ModalWindow';
import { HeaderBackable } from '../../../../../components/ModalWindow';

export interface IRegionSelectContainerModalProps {
  logger: ILogger;
  open: boolean;
  onClose(): void;
}

const RegionSelectContainerLoadable = loadable(() => import('./RegionSelectContainer'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const RegionSelectContainerModal: React.FC<IRegionSelectContainerModalProps> = ({ logger, open, onClose }) => {
  const modalWindowRef = React.createRef<ModalWindow>();

  const handleRetry = React.useCallback(() => {
    RegionSelectContainerLoadable.load();
  }, []);

  const handleScrollableChange = React.useCallback(() => {
    if (modalWindowRef.current) {
      modalWindowRef.current.scrollToTop();
    }
  }, [modalWindowRef]);

  return (
    <ModalWindow
      ref={modalWindowRef}
      header={<HeaderBackable title="Введите регион или город" onBack={onClose} />}
      open={open}
      onClose={onClose}
      portal
    >
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <RegionSelectContainerLoadable onQueryChange={handleScrollableChange} onClose={onClose} />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
