import { ca } from '@cian/analytics';

import { IRegionMetaState } from '../../types/regionMeta';

export const trackRegionExpansion = (regionMeta: IRegionMetaState) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'extended',
    category: 'extend_region',
    page: {
      pageType: 'Filter',
      extra: {
        extend_region: {
          previous_region: regionMeta.expansibleRegion,
          current_region: regionMeta.id,
        },
      },
    },
  });
};
