import { ca } from '@cian/analytics';
import { EActiveView } from '@cian/authentication-widget';

const VIEW_TO_LABEL_MAPPING: { [key in EActiveView]: string } = {
  [EActiveView.AccountSelect]: 'accountSelect',
  [EActiveView.AccountSelectError]: 'accountSelectError',
  [EActiveView.Agreement]: 'agreement',
  [EActiveView.AuthenticationRegistration]: 'authenticationRegistration',
  [EActiveView.ConfirmPhone]: 'confirmPhone',
  [EActiveView.ConfirmSelectedAccount]: 'confirmSelectedAccount',
  [EActiveView.EmailSent]: 'emailSent',
  [EActiveView.PhoneAuth]: 'phoneAuth',
  [EActiveView.PhoneAuthValidateSms]: 'phoneAuthValidateSms',
  [EActiveView.Restore]: 'restore',
  [EActiveView.SocialCreate]: 'socialCreate',
  [EActiveView.SocialMerge]: 'socialMerge',
  [EActiveView.TokenExpiredError]: 'tokenExpiredError',
  [EActiveView.TwoFactorWithPhone]: 'twoFactorWithPhone',
  [EActiveView.TwoFactorPhoneValidateSms]: 'twoFactorPhoneValidateSms',
  [EActiveView.TwoFactorPhoneValidateCall]: 'twoFactorPhoneValidateCall',
  [EActiveView.TwoFactorAttemptsExhausted]: 'twoFactorAttemptsExhausted',
  [EActiveView.TwoFactorWithEmail]: 'twoFactorWithEmail',
  [EActiveView.PhoneAuthValidateCall]: 'phoneAuthValidateCall',
  [EActiveView.RememberedAccountsSelect]: 'latestAccountSelect',
  [EActiveView.EnterPhoneNumber]: 'enterPhoneNumber',
};

export function trackClose(view: EActiveView) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'close',
    label: VIEW_TO_LABEL_MAPPING[view] || '',
  });
}

export function trackShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'AuthBlock',
    action: 'show',
  });
}
