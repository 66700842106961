import * as React from 'react';

import { EBalconyType, EFacilityType, JsonQuery, NonEmptyArray } from '../../../../../JsonQuery';
import { Facilities } from '../../../components/filters/Facilities';
import { useContext } from '../../../utils/useContext';

const isBalcony = (value: EFacilityType | EBalconyType): value is EBalconyType => {
  return Object.values(EBalconyType).includes(value as EBalconyType);
};

const isBath = (value: EFacilityType | EBalconyType): value is EFacilityType => {
  return [EFacilityType.Shower, EFacilityType.Bath].includes(value as EFacilityType);
};

interface IGetFilterValueArgs {
  isDailyrentUrban: boolean;
  facilityTypes: NonEmptyArray<EFacilityType> | null;
  balconyTypes: NonEmptyArray<EBalconyType> | null;
  bathTypes: NonEmptyArray<EFacilityType> | null;
}

/**
 * Если посутка, возвращает комбинированные значения удобств, включая ванные и балконы.
 * @param isDailyrentUrban
 * @param facilityTypes
 * @param balconyTypes
 * @param bathTypes
 */
const getFilterValue = ({ isDailyrentUrban, facilityTypes, balconyTypes, bathTypes }: IGetFilterValueArgs) => {
  let value: NonEmptyArray<EFacilityType | EBalconyType> | null = facilityTypes;

  if (!isDailyrentUrban) {
    return value as NonEmptyArray<EFacilityType> | null;
  }

  if (balconyTypes && balconyTypes.length) {
    value = value ? (value.concat(balconyTypes) as NonEmptyArray<EBalconyType>) : balconyTypes;
  }

  if (bathTypes && bathTypes.length) {
    value = value ? (value.concat(bathTypes) as NonEmptyArray<EFacilityType>) : bathTypes;
  }

  return value;
};

export const FacilitiesContainer: React.FC = () => {
  const { jsonQuery, onChange, areDailyrentRoomFiltersActive, areDailyrentFlatFiltersActive } = useContext();
  const facilityTypes = new JsonQuery(jsonQuery).getFacilityTypes();
  const balconyTypes = new JsonQuery(jsonQuery).getBalconyTypes();
  const bathTypes = new JsonQuery(jsonQuery).getBathTypes();

  const showDailyrentFacilities = areDailyrentRoomFiltersActive || areDailyrentFlatFiltersActive;

  const handleDailyrentFacilitiesChange = React.useCallback(
    (facilityTypes: NonEmptyArray<EFacilityType | EBalconyType> | null) => {
      if (!facilityTypes) {
        return onChange(
          { action: 'setBalconyTypes', arguments: [null] },
          { action: 'setBathTypes', arguments: [null] },
          { action: 'setFacilityTypes', arguments: [null] },
        );
      }

      // получаем список удобств вроде лоджии или балкона – в посутке эти удобства объединены одним фильтром
      const balconyTypes = facilityTypes.filter(isBalcony);
      // получаем список удобств вроде душевой или ванны – в посутке эти удобства объединены одним фильтром
      const bathTypes = facilityTypes.filter(isBath);
      // исключаем из общего списка балконы и ванные
      const onlyFacilities = facilityTypes.filter(f => !isBalcony(f) && !isBath(f));

      const normalizedBalconyTypes = balconyTypes.length ? (balconyTypes as NonEmptyArray<EBalconyType>) : null;
      const normalizedBathTypes = bathTypes.length ? (bathTypes as NonEmptyArray<EFacilityType>) : null;
      const normalizedFacilities = onlyFacilities.length ? (onlyFacilities as NonEmptyArray<EFacilityType>) : null;

      onChange(
        { action: 'setBalconyTypes', arguments: [normalizedBalconyTypes] },
        { action: 'setBathTypes', arguments: [normalizedBathTypes] },
        { action: 'setFacilityTypes', arguments: [normalizedFacilities] },
      );
    },
    [onChange],
  );

  const handleFacilityTypesChange = React.useCallback(
    (facilityTypes: NonEmptyArray<EFacilityType> | null) => {
      onChange({ action: 'setFacilityTypes', arguments: [facilityTypes] });
    },
    [onChange],
  );

  const handler = showDailyrentFacilities ? handleDailyrentFacilitiesChange : handleFacilityTypesChange;
  const value = getFilterValue({ isDailyrentUrban: showDailyrentFacilities, facilityTypes, balconyTypes, bathTypes });

  return <Facilities value={value} showDailyrentFacilities={showDailyrentFacilities} onChange={handler} />;
};
