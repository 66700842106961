import { Switch as UiKitSwitch } from '@cian/ui-kit/checkbox';
import cn from 'classnames';
import * as React from 'react';

import * as styles from './Switch.css';

interface ISwitchProps extends React.ComponentPropsWithoutRef<typeof UiKitSwitch> {
  caption?: string;
  topAligned?: boolean;
  padded?: boolean;
}

export const Switch: React.FC<ISwitchProps> = ({ caption = null, topAligned, padded = true, ...props }) => {
  const containerClassName = cn(
    styles['container'],
    caption && styles['container--with-caption'],
    topAligned && styles['container--top-aligned'],
    padded && styles['container-padded'],
  );

  const style = caption ? { '--caption': `"${caption}"` } : undefined;

  return (
    <div className={containerClassName} style={style as React.CSSProperties}>
      <UiKitSwitch {...props} />
    </div>
  );
};
