import * as React from 'react';

import { NonEmptyArray } from '../../../../../Fields';
import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { getTermValue, getUncommonProperties, TJsonQueryOptionalKeys } from '../../../../../JsonQuery';
import { ResidentialComplexesUpcomingSale } from '../../../components/filters/ResidentialComplexesUpcomingSale';
import { useContext } from '../../../utils/useContext';

const EXCLUDE_ITEMS = ['newbuilding_results_type'];

export const ResidentialComplexesUpcomingSaleContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const upcomingSale = getTermValue('is_upcoming_sale')(jsonQuery);

  const handleUpcomingSaleChange = React.useCallback(
    (salesStart: boolean | null) => {
      const modifiers: NonEmptyArray<TModifier> = [{ action: 'setUpcomingSale', arguments: [salesStart] }];

      if (salesStart) {
        const uncommonProperties = getUncommonProperties(jsonQuery);
        const items = uncommonProperties ? uncommonProperties.filter(item => !EXCLUDE_ITEMS.includes(item)) : [];
        const unshiftItems = items.length > 0 ? (items as NonEmptyArray<TJsonQueryOptionalKeys>) : null;

        if (unshiftItems?.length) {
          modifiers.unshift({ action: 'resetTerms', arguments: [unshiftItems] });
        }
      }

      onChange(...modifiers);
    },
    [jsonQuery, onChange],
  );

  return (
    <ResidentialComplexesUpcomingSale upcomingSale={upcomingSale} onUpcomingSaleChange={handleUpcomingSaleChange} />
  );
};
