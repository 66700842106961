import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { EWCType } from '../../../../../api-models/common/json_query';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter, FilterRow } from '../common/Filter';

interface IBathroom {
  label: string;
  value: EWCType;
}

const BATHROOM_TYPES: IBathroom[] = [
  { label: 'Совмещённый', value: EWCType.United },
  { label: 'Раздельный', value: EWCType.Separated },
];

interface IBathroomProps {
  bathroomType: EWCType | null;
  bathroomsCountMin: number | null;
  onBathroomTypeChange(value: EWCType | null): void;
  onBathroomsCountMinChange(value: number | null): void;
}

export const UrbanBathroom: React.FC<IBathroomProps> = ({
  bathroomType,
  bathroomsCountMin,
  onBathroomTypeChange,
  onBathroomsCountMinChange,
}) => {
  return (
    <Filter id="filter_urbanBathroom" label="Санузел">
      <FilterRow>
        <RadioButtonGroupUnselectable
          options={BATHROOM_TYPES}
          value={bathroomType}
          onChange={onBathroomTypeChange}
          spliced={false}
        />
      </FilterRow>
      <FilterRow>
        <Switch
          label="Два и более"
          checked={!!bathroomsCountMin && bathroomsCountMin > 1}
          onChange={event => onBathroomsCountMinChange(event.target.checked ? 2 : null)}
        />
      </FilterRow>
    </Filter>
  );
};
