import { ArticleHeading3, UIText1 } from '@cian/ui-kit/typography';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Notification.css';
import { ENotificationType, INotification } from '../../types/notification';

interface INotificationProps extends INotification {
  onClose(): void;
}

export const Notification: React.FC<INotificationProps> = ({ type, image, heading, message, timeout, onClose }) => {
  const timer = React.useRef<ReturnType<typeof setTimeout>>();

  const handleClose = React.useCallback(() => {
    onClose();

    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  }, [onClose]);

  React.useEffect(() => {
    if (timeout) {
      timer.current = setTimeout(() => onClose(), timeout);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(styles['container'], {
        [styles['container--error']]: type === ENotificationType.Error,
        [styles['container--light']]: type === ENotificationType.Light,
        [styles['container--success']]: type === ENotificationType.Success,
        [styles['container--warning']]: type === ENotificationType.Warning,
      })}
      role="alert"
    >
      {image && <div className={styles['image']}>{image}</div>}
      <div className={styles['content']}>
        {heading && (
          <div className={styles['heading']}>
            <ArticleHeading3>{heading}</ArticleHeading3>
          </div>
        )}
        <UIText1>{message}</UIText1>
      </div>
      <div>
        <button type="button" className={styles['close']} title="Закрыть" onClick={handleClose}>
          <IconActionClose16 display="block" color="current_color" />
        </button>
      </div>
    </div>
  );
};
