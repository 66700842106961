import { ca } from '@cian/analytics';

export const trackOpenDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    category: 'DailyrentCalendar',
    label: 'Popup',
  });
};

export const trackCloseDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click_sopr',
    category: 'DailyrentCalendar',
    label: 'ClosePopup',
  });
};

export const trackApplyDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'DailyrentCalendar',
    label: 'ConfirmDates',
  });
};

export const trackResetDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'DailyrentCalendar',
    label: 'ResetDates',
  });
};
