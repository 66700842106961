import { numberToPrettyString } from '@cian/utils';
import { useMemo } from 'react';

import { useContext } from './useContext';
import { TFieldPlaceholders, TPlaceholderKey } from '../types/placeholders';

interface IParams {
  minKey: TPlaceholderKey;
  maxKey: TPlaceholderKey;
}

export const usePlaceholders = ({ minKey, maxKey }: IParams): TFieldPlaceholders => {
  const { placeholders } = useContext();

  const min = Number(placeholders[minKey]);
  const max = Number(placeholders[maxKey]);

  return useMemo(() => {
    if (isNaN(min) && isNaN(max)) {
      return {};
    }

    return {
      placeholderMin: `от ${numberToPrettyString(min)}`,
      placeholderMax: `до ${numberToPrettyString(max)}`,
    };
  }, [max, min]);
};
