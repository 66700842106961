import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EGarageUtility, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IGarageUtility {
  label: string;
  value: EGarageUtility;
}

const GARAGE_UTILITIES: IGarageUtility[] = [
  { label: 'Электричество', value: EGarageUtility.Electricity },
  { label: 'Вода', value: EGarageUtility.Water },
  { label: 'Отопление', value: EGarageUtility.Heating },
  { label: 'Система пожаротушения', value: EGarageUtility.ExtinguishingSystem },
];

interface IGarageUtilitiesProps {
  value: NonEmptyArray<EGarageUtility> | null;
  onChange(value: NonEmptyArray<EGarageUtility> | null): void;
}

export const GarageUtilities: React.FC<IGarageUtilitiesProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_garageUtilities" label="Коммуникации">
      <CheckboxButtonGroup options={GARAGE_UTILITIES} value={value} onChange={onChange} />
    </Filter>
  );
};
