import * as React from 'react';

import { EElevatorType } from '../../../../../JsonQuery';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IElevators {
  label: string;
  value: EElevatorType;
}

const ELEVATOR_TYPES: IElevators[] = [
  { label: 'Есть любой лифт', value: EElevatorType.Any },
  { label: 'Есть грузовой', value: EElevatorType.Service },
];

interface IElevatorsProps {
  value: EElevatorType | null;
  onChange(value: EElevatorType | null): void;
}

export const Elevators: React.FC<IElevatorsProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_elevators" label="Лифт">
      <RadioButtonGroupUnselectable options={ELEVATOR_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
