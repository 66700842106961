import { IHighwaysGroup } from '../../../../../../../../Fields/shared/types/highways';
import { ETabType, ICatalogSelectOptionsGroup } from '../../../../../../components/filters/Geo';

export function getHighwaysOptions(highways: IHighwaysGroup[]): ICatalogSelectOptionsGroup[] {
  return highways.map(hg => ({
    label: `${hg.name} направление`,
    options: hg.highways.map(h => ({
      type: ETabType.Highways,
      label: h.name,
      value: [h.id],
    })),
  }));
}
