import { init as initAuthContext } from '../../../shared/services/authContext';
import { init as initEvents } from '../../../shared/services/events';
import { createApplicationState } from '../applicationState';
import { createContext } from '../createContext';
import { queryStringUpdater } from '../queryStringUpdater';
import { renderApplication } from '../renderApplication';

export function runApp() {
  const context = createContext();
  const store = createApplicationState(context);

  initAuthContext(context, store);
  initEvents(context, store);

  store.subscribe(() => queryStringUpdater(context, store.getState()));

  renderApplication({ context, store });
}
