import { without } from 'ramda';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../Fields';
import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { getRangeValue, getTermValue } from '../../../../../JsonQuery';
import { Floor } from '../../../components/filters/Floor';
import { EFloor } from '../../../constants/floors';
import { useContext } from '../../../utils/useContext';

export const FloorContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const floor = getRangeValue('floor')(jsonQuery);
  const isFirstFloor = getTermValue('is_first_floor')(jsonQuery);
  const isNotLastFloor = getTermValue('not_last_floor')(jsonQuery);

  const floors = React.useMemo(() => {
    const floors: EFloor[] = [];

    if (isFirstFloor === false) {
      floors.push(EFloor.NotFirst);
    }

    if (isNotLastFloor === true) {
      floors.push(EFloor.NotLast);
    }

    if (isNotLastFloor === false) {
      floors.push(EFloor.OnlyLast);
    }

    return floors.length > 0 ? (floors as NonEmptyArray<EFloor>) : null;
  }, [isFirstFloor, isNotLastFloor]);

  const handleFloorMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFloorMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFloorMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFloorMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleFloorsChange = React.useCallback(
    (value: NonEmptyArray<EFloor> | null) => {
      const modifiers: TModifier[] = [];

      if (!value) {
        if (isFirstFloor !== null) {
          modifiers.push({ action: 'setIsFirstFloor', arguments: [null] });
        }

        if (isNotLastFloor !== null) {
          modifiers.push({ action: 'setNotLastFloor', arguments: [null] });
        }
      } else if (floors && value.length < floors.length) {
        const removedFloor = without(value, floors)[0];

        switch (removedFloor) {
          case EFloor.NotFirst:
            modifiers.push({ action: 'setIsFirstFloor', arguments: [null] });
            break;
          case EFloor.NotLast:
          case EFloor.OnlyLast:
            modifiers.push({ action: 'setNotLastFloor', arguments: [null] });
            break;
        }
      } else {
        const addedFloor = without(floors || [], value)[0];

        switch (addedFloor) {
          case EFloor.NotFirst:
            modifiers.push({ action: 'setIsFirstFloor', arguments: [false] });
            break;
          case EFloor.NotLast:
            modifiers.push({ action: 'setNotLastFloor', arguments: [true] });
            break;
          case EFloor.OnlyLast:
            modifiers.push({ action: 'setNotLastFloor', arguments: [false] });
            break;
        }
      }

      if (modifiers.length > 0) {
        onChange(...(modifiers as NonEmptyArray<TModifier>));
      }
    },
    [floors, isFirstFloor, isNotLastFloor, onChange],
  );

  return (
    <Floor
      floor={floor}
      floors={floors}
      onFloorMinChange={handleFloorMinChange}
      onFloorMaxChange={handleFloorMaxChange}
      onFloorsChange={handleFloorsChange}
    />
  );
};
