import { TAllowedSuggestionGroup, TSuggestionGroup } from '../types/geoSuggest';

export const ALLOWED_GEO_SUGGEST_GROUPS: TAllowedSuggestionGroup[] = [
  'regionalDistricts',
  'undergrounds',
  'districts',
  'newbuildings',
  'builders',
  'villages',
  'cities',
  'railways',
  'roads',
];

export const GEO_SUGGEST_GROUPS_ORDER: TSuggestionGroup[] = [
  'addresses',
  'regionalDistricts',
  'undergrounds',
  'districts',
  'newbuildings',
  'builders',
  'villages',
  'cities',
  'railways',
  'roads',
];

export const GEO_SUGGEST_GROUP_NAMES: { [key in TSuggestionGroup]: string } = {
  cities: 'Город',
  addresses: 'Адреса',
  railways: 'ЖД Станции',
  districts: 'Район',
  newbuildings: 'Жилые комплексы',
  builders: 'Застройщики',
  roads: 'Шоссе',
  undergrounds: 'Метро',
  villages: 'Котеджные поселки',
  regionalDistricts: 'Район',
};

export const MIN_SYMBOL_COUNT_FOR_SEARCH = 3;

export const GEO_OBJECT_TYPES_MAP: { [key in TAllowedSuggestionGroup]: string } = {
  districts: 'district',
  cities: 'location',
  newbuildings: 'newobject',
  builders: 'builder',
  railways: 'railway',
  roads: 'highway',
  undergrounds: 'underground',
  villages: 'village',
  regionalDistricts: 'regional_district_id',
};
