import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../api-models/common/json_query';

export function trackHotelsBannerClick(jsonQuery: IJsonQuery) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'change_offer_type',
    label: 'hotel',
    sc: jsonQuery,
  });
}
