import * as React from 'react';

import { EKitchenStove } from '../../../../../JsonQuery';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IKitchenStoveOption {
  label: string;
  value: EKitchenStove;
}

const KITCHEN_STOVE_OPTIONS: IKitchenStoveOption[] = [
  { label: 'Газовая', value: EKitchenStove.Gas },
  { label: 'Электрическая', value: EKitchenStove.Electric },
];

interface IKitchenStoveProps {
  value: EKitchenStove | null;
  onChange(value: EKitchenStove | null): void;
}

export const KitchenStove: React.FC<IKitchenStoveProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_kitchenStove" label="Кухонная плита">
      <RadioButtonGroupUnselectable options={KITCHEN_STOVE_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
