import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { NBSP } from '../../../constants/symbols';

interface IGoodPriceProps {
  value: boolean | null;
  onChange(event: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

export const GoodPrice: React.FC<IGoodPriceProps> = ({ value, onChange }) => {
  return (
    <Switch
      checked={!!value}
      padded={false}
      onChange={onChange}
      label="Хорошая цена"
      caption={`Оцениваем параметры из${NBSP}объявления и${NBSP}сравниваем цену со${NBSP}средней по${NBSP}рынку`}
    />
  );
};
