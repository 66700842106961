import * as React from 'react';

import { IJsonQueryRangeValue } from '../../../../api-models/common/json_query';
import { TFieldPlaceholders } from '../../types/placeholders';
import { useDebouncedCallback } from '../../utils/useDebouncedCallback';
import { RangeInput } from '../RangeInput';

export interface ITotalAreaProps {
  totalArea: IJsonQueryRangeValue | null;
  placeholders?: TFieldPlaceholders;
  onTotalAreaMinChange(gte: number | null): void;
  onTotalAreaMaxChange(lte: number | null): void;
}

export const TotalAreaField: React.FC<ITotalAreaProps> = ({
  totalArea,
  placeholders,
  onTotalAreaMinChange,
  onTotalAreaMaxChange,
}) => {
  const { gte: totalAreaMin = null, lte: totalAreaMax = null } = totalArea || {};

  const handleTotalAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onTotalAreaMinChange(gte);
  }, 300);

  const handleTotalAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onTotalAreaMaxChange(lte);
  }, 300);

  return (
    <RangeInput
      min={0}
      max={9999}
      joined={false}
      postfix={'м\u00B2'}
      valueMin={totalAreaMin}
      valueMax={totalAreaMax}
      onChangeMin={handleTotalAreaMinChange}
      onChangeMax={handleTotalAreaMaxChange}
      {...placeholders}
    />
  );
};
