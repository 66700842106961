import { mergeRegionsMeta, cutDistrictsIfNeeded } from './utils';
import { TJsonQuery } from '../../../packages/JsonQuery';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchExtensionRegionMetaIfNeeded } from '../fetchExtensionRegionMeta';
import { fetchRegionMetaCached } from '../fetchRegionMeta';

interface IFetchRegionMetaWithExpansionParams {
  locationId: number;
  jsonQuery: TJsonQuery;
  expansionRegionCookie?: string;
}

export const fetchRegionMetaWithExpansion = async (
  context: IApplicationContext,
  parameters: IFetchRegionMetaWithExpansionParams,
) => {
  const { locationId, jsonQuery, expansionRegionCookie } = parameters;

  const [currentRegionMeta, extensionRegionMeta] = await Promise.all([
    fetchRegionMetaCached(context, parameters),
    fetchExtensionRegionMetaIfNeeded(context, {
      regionId: locationId,
      jsonQuery,
      expansionRegionCookie,
    }),
  ]);

  const regionMeta = extensionRegionMeta ? mergeRegionsMeta(currentRegionMeta, extensionRegionMeta) : currentRegionMeta;

  return regionMeta ? cutDistrictsIfNeeded(context, { regionMeta, jsonQuery }) : null;
};
