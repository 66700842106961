import { pathOr } from 'ramda';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERepair } from '../../../../../api-models/common/json_query';
import { Repair } from '../../../components/filters/Repair';
import { useContext } from '../../../utils/useContext';

export const RepairContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let repair = pathOr<ERepair[] | null>(null, ['repair', 'value'], jsonQuery);
  if (repair && repair.length === 0) {
    repair = null;
  }

  const handleChange = React.useCallback(
    (repair: NonEmptyArray<ERepair> | null) => {
      onChange({ action: 'setRepair', arguments: [repair] });
    },
    [onChange],
  );

  return <Repair value={repair as NonEmptyArray<ERepair> | null} onChange={handleChange} />;
};
