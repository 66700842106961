import * as React from 'react';

import { EKitchenStove, getTermValue } from '../../../../../JsonQuery';
import { KitchenStove } from '../../../components/filters/KitchenStove';
import { useContext } from '../../../utils/useContext';

export const KitchenStoveContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const kitchenStove = getTermValue('kitchen_stove')(jsonQuery);

  const handleChange = React.useCallback(
    (kitchenStove: EKitchenStove | null) => {
      onChange({ action: 'setKitchenStove', arguments: [kitchenStove] });
    },
    [onChange],
  );

  return <KitchenStove value={kitchenStove} onChange={handleChange} />;
};
