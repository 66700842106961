import Fuse from 'fuse.js';
import { flatten } from 'ramda';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import { IRegionSelectOptionsGroup } from '../../../../../../components/filters/Region';

export function useAvailableOptions(
  defaultOptionGroups: IRegionSelectOptionsGroup[] | null,
  optionGroups: IRegionSelectOptionsGroup[],
  queryRaw: string | null,
): IRegionSelectOptionsGroup[] {
  const queryRef = React.useRef(queryRaw);
  queryRef.current = queryRaw;
  const [query, setQuery] = React.useState(queryRaw);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQueryChange = React.useCallback(
    debounce(300, () => {
      setQuery(queryRef.current);
    }),
    [],
  );

  const fuse = React.useMemo(
    () =>
      new Fuse(flatten(optionGroups.map(o => o.options)), {
        includeMatches: true,
        findAllMatches: true,
        keys: ['label'],
      }),
    [optionGroups],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleQueryChange, [queryRaw]);

  return React.useMemo(() => {
    const trimmedQuery = query?.trim();

    if (!trimmedQuery) {
      return defaultOptionGroups || [];
    }

    return [
      {
        options: fuse.search(trimmedQuery).map(o => ({
          ...o.item,
          matches: o.matches && o.matches.length > 0 ? (o.matches[0].indices as [number, number][]) : undefined,
        })),
      },
    ];
  }, [defaultOptionGroups, fuse, query]);
}
