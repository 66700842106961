import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './LoadableFallback.css';

export const LoadableFallback: React.FC = () => {
  return (
    <div className={styles['container']}>
      <Spinner size={24} color="icon-main-default" />
    </div>
  );
};
