import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { TotalArea } from '../../../components/filters/TotalArea';
import { useContext } from '../../../utils/useContext';
import { usePlaceholders } from '../../../utils/usePlaceholders';

export const TotalAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const totalArea = getRangeValue('total_area')(jsonQuery);
  const placeholders = usePlaceholders({ minKey: 'minTotalArea', maxKey: 'maxTotalArea' });

  const handleTotalAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setTotalAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleTotalAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setTotalAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <TotalArea
      totalArea={totalArea}
      placeholders={placeholders}
      onTotalAreaMinChange={handleTotalAreaMinChange}
      onTotalAreaMaxChange={handleTotalAreaMaxChange}
    />
  );
};
