import * as React from 'react';

import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EDecorationType } from '../../../../../JsonQuery/types/jsonQuery/jsonQueryDecorationType';
import { DecorationsList } from '../../../components/filters/DecorationsList';
import { useContext } from '../../../utils/useContext';

export const DecorationsListContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const decorationsList = getTermsValue('decorations_list')(jsonQuery);

  const handleChange = React.useCallback(
    (decorationsList: NonEmptyArray<EDecorationType> | null) => {
      const modifiers: NonEmptyArray<TModifier> = [{ action: 'setDecorationsList', arguments: [decorationsList] }];
      const isWithoutDecoration = !!decorationsList?.includes(EDecorationType.Without);
      if (isWithoutDecoration) {
        modifiers.push({ action: 'setHasFinishing', arguments: [false] });
      }
      onChange(...modifiers);
    },
    [onChange],
  );

  return <DecorationsList value={decorationsList} onChange={handleChange} />;
};
