import { IconMapPin16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { CellGroup, CellEntry } from '../../../../../Fields/shared/components/CellGroup';
import { Filter } from '../common/Filter';

interface IRegionProps {
  name: string;
  onClick(): void;
}

export const Region: React.FC<IRegionProps> = ({ name, onClick }) => {
  return (
    <Filter id="filter_region" contentPadding={false} compact>
      <CellGroup>
        <CellEntry icon={IconMapPin16} onClick={onClick}>
          {name}
        </CellEntry>
      </CellGroup>
    </Filter>
  );
};
