import { CheckboxButtonGroup } from '@cian/ui-kit/checkbox';
import { IOption as IUiKitOption } from '@cian/ui-kit/types';
import * as React from 'react';

type TBaseCheckboxButtonGroupProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxButtonGroup>,
  'options' | 'value' | 'onChange'
>;
type TValue = string | number | boolean;

interface IOption {
  value: TValue;
  label: string | number;
}

export interface IRadioButtonGroupUnselectableProps extends TBaseCheckboxButtonGroupProps {
  options: IOption[];
  value: TValue | null;
  onChange(value: TValue | null): void;
}

export function RadioButtonGroupUnselectable(props: IRadioButtonGroupUnselectableProps) {
  const { onChange, options: optionsProp, value: valueProp } = props;
  const { options, optionsMap } = React.useMemo(() => {
    return optionsProp.reduce(
      (result, item) => {
        const value = String(item.value);

        result.options.push({
          ...item,
          value,
        });

        result.optionsMap[value] = item.value;

        return result;
      },
      {
        options: [] as IUiKitOption[],
        optionsMap: {} as { [value: string]: TValue },
      },
    );
  }, [optionsProp]);

  const handleChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: string[]) => {
      onChange(value.length > 0 ? optionsMap[value[value.length - 1]] : null);
    },
    [onChange, optionsMap],
  );

  return (
    <CheckboxButtonGroup
      {...props}
      options={options}
      value={valueProp === null ? [] : [String(valueProp)]}
      onChange={handleChange}
    />
  );
}
