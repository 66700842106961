import { FDealType, FOfferType, isAvailable } from '../../../../../../JsonQuery';
import { IFilterList } from '../../types';

export const FILTERS_GARAGES: IFilterList[] = [
  {
    availability: isAvailable(FDealType.Any, FOfferType.Garage),
    list: [
      'totalArea',
      'price',
      'geo',
      'undergroundTime',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'electronicTrading',
      'containWords',
      'excludeWords',
      'aboutOffer',
    ],
  },
];
