import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentRegionIdFromRegionMeta } from '../../../../../../shared/selectors/regionMeta';
import { getIsSubsidisedMortgage } from '../../../../../JsonQuery/getIsSubsidisedMortgage';
import { SubsidisedMortgage } from '../../../components/filters/SubsidisedMortgage';
import { useContext } from '../../../utils/useContext';
import { SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY } from '../MortgagePromoSearchTypeContainer/constants';

export const SubsidisedMortgageContainer: React.FC = () => {
  const { jsonQuery, onChange, config } = useContext();
  const regionId = useSelector(selectCurrentRegionIdFromRegionMeta);

  const isSubsidisedMortgageEnabled = config.get<boolean>('subsidizedMortgage.Enabled');
  const subsidizedMortgageExcludedRegions = config.get<number[]>(SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY) ?? [];
  const { label = '', caption = '' } =
    config.get<{ label: string; caption: string }>('subsidizedMortgage.filterInfo') || {};
  const isSubsidisedMortgage = React.useMemo(() => getIsSubsidisedMortgage(jsonQuery), [jsonQuery]);

  const handleChange = React.useCallback(
    (value: boolean) => {
      onChange({ action: 'setIsSubsidisedMortgage', arguments: [value] });
    },
    [onChange],
  );

  if (!isSubsidisedMortgageEnabled || subsidizedMortgageExcludedRegions.includes(regionId)) {
    return null;
  }

  return (
    <SubsidisedMortgage
      label={label}
      caption={caption}
      isSubsidisedMortgage={isSubsidisedMortgage}
      onChange={handleChange}
    />
  );
};
