import { setCookie } from '@cian/utils/lib/browser/cookie';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeSearch, makeSearchOnMap } from './makeSearch';
import { useNeedSkipDatesModalCookie } from './useNeedSkipDatesModalCookie';
import { doChecksBeforeSearch } from '../../../actions/filters/checksBeforeSearch/doChecksBeforeSearch';
import { updateSearchMeta } from '../../../actions/searchMeta';
import { COOKIE_EXPIRES, COOKIE_NAME } from '../../../constants/filters/datesModal';
import { IApplicationState, TThunkDispatch } from '../../../types/redux';
import { ESearchMetaStatus, ISearchMetaState } from '../../../types/searchMeta';
import { useApplicationContext } from '../../../utils/applicationContext';

interface IParams {
  toMap: boolean;
  searchMeta?: ISearchMetaState | null;
}

export function useMakeSearch() {
  const { config } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const domain = config.get<string>('mobile-filters-frontend.domain') || 'cian.ru';
  const needSkipDatesModalCookie = useNeedSkipDatesModalCookie();
  const searchMetaFromState = useSelector<IApplicationState, ISearchMetaState>(state => state.searchMeta);

  return React.useCallback(
    async (params: IParams) => {
      const searchMeta = params.searchMeta || searchMetaFromState;
      const searchFunc = params.toMap ? makeSearchOnMap : makeSearch;

      // Проверки которые могут заблокировать поиск и вызвать сайд эффекты
      const isBeforeSearchChecksPassed: boolean = await dispatch(doChecksBeforeSearch());

      if (!isBeforeSearchChecksPassed) {
        return;
      }

      if (needSkipDatesModalCookie) {
        setCookie(COOKIE_NAME, 'true', { domain: '.cian.ru', expires: new Date(Date.now() + COOKIE_EXPIRES) });
      }

      if (searchMeta.status === ESearchMetaStatus.Failed) {
        const searchMeta = await dispatch(updateSearchMeta());

        if (searchMeta) {
          searchFunc({ domain, searchMeta });
        }

        return;
      }

      searchFunc({ domain, searchMeta });
    },
    [searchMetaFromState, needSkipDatesModalCookie],
  );
}
