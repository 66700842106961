// Должен соответствовать projectName
/* istanbul ignore next */
export const UNIQUE_CONFIG_KEY = 'mobile-filters-frontend';

export enum EContainerNames {
  QUICK_FILTERS = 'quick-filters-microfrontend',
  CHATBOT_FILTERS = 'chatbot-filters-microfrontend',
  TELEGRAM_BOT_FILTERS = 'telegram-bot-filters-microfrontend',
}

export type TContainerNames = `${EContainerNames}`;
