import { useEventCallback } from '@cian/react-utils';
import { IconMapPin16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { RegionalDistrictsContentContainerLoadable } from './RegionalDistrictsContentContainerLoadable';
import { useModalState } from './hooks/useModalState';
import { CellEntry } from '../../../../../../../Fields/shared/components/CellGroup';
import { LoadableErrorBoundary } from '../../../../../../../LoadableHelpers';
import { ModalWindow } from '../../../../../../../ModalWindow';
import { HeaderBackable } from '../../../../../components/ModalWindow';
import { useContext } from '../../../../../utils/useContext';

export const RegionalDistrictsContainer = () => {
  const { regionalDistricts, logger } = useContext();

  const { isOpen, onOpen, onClose } = useModalState();

  /* istanbul ignore next */
  const handleRetry = useEventCallback(() => {
    RegionalDistrictsContentContainerLoadable.load();
  });

  if (!regionalDistricts.districts.length) {
    return null;
  }

  return (
    <>
      <CellEntry icon={IconMapPin16} onClick={onOpen}>
        Район
      </CellEntry>
      <ModalWindow
        header={<HeaderBackable title="Выберите район" onBack={onClose} />}
        open={isOpen}
        onClose={onClose}
        portal
      >
        <LoadableErrorBoundary onRetry={handleRetry} logger={logger}>
          <RegionalDistrictsContentContainerLoadable {...regionalDistricts} onApply={onClose} />
        </LoadableErrorBoundary>
      </ModalWindow>
    </>
  );
};
