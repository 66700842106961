import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { ElectronicTrading } from '../../../components/filters/ElectronicTrading';
import { useContext } from '../../../utils/useContext';

export const ElectronicTradingContainer: React.FC = () => {
  const { jsonQuery, onChange, config } = useContext();
  const electronicTrading = getTermValue('electronic_trading')(jsonQuery);

  /**
   * @todo Удалить условие с фича-тогглом
   * @description Данный функционал появился в задаче CD-187928, будет удалён в задаче CD-195567
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('AudienceMobile.ElectronicTrading.Enabled');

  const handleChange = React.useCallback(
    (electronicTradingType: number | null) => {
      onChange({ action: 'setElectronicTradingType', arguments: [electronicTradingType] });
    },
    [onChange],
  );

  if (isElectronicTradingEnabled) {
    return <ElectronicTrading value={electronicTrading} onChange={handleChange} />;
  }

  return null;
};
