import { FDealType, FOfferType, isAvailableStrict } from '../../../../../../JsonQuery';
import { IFilterList } from '../../types';

export const FILTERS_RESIDENTIAL_COMPLEXES: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew),
    list: [
      'flatType',
      'residentialComplexesListingType',
      'residentialComplexesUpcomingSale',
      'roomType',
      'price',
      'totalArea',
      'residentialComplexesSpecial',
      'geo',
      'undergroundTime',
      'squareMeterArea',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'apartments',
      'completionYear',
      'decorationsList',
      'floor',
      // Подборки (2 итерация)
      'constructionType',
      'containWords',
      'excludeWords',
      'aboutOffer',

      // OLD
      // 'offerType',
      // 'layout',
      // 'ceilingHeight',
      // 'floors',
      // 'finishing',
      // 'saleType',
    ],
  },
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatOld),
    list: [
      'flatType',
      'roomType',
      'price',
      'totalArea',
      'subsidisedMortgage',
      'dealType', // Докинуть "От застройщика"
      'geo',
      'undergroundTime',
      'squareMeterArea',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'apartments',
      'flatShare',
      'floor',
      'balcony',
      'kitchenStove',
      'constructionYear',
      // Отделка в ЖК (2 итерация)
      'constructionType',
      'demolition',
      'saleType',
      'electronicTrading',
      'containWords',
      'excludeWords',
      'aboutOffer',
    ],
  },
];
