import { everyOf, FDealType, FOfferType, isAvailable, isAvailableIfKeysUnset } from '../../../../JsonQuery';
import { RoomTypeContainer } from '../../containers/filters/RoomTypeContainer';
import { IFilter } from '../../types/filters';

export const roomType: IFilter = {
  availability: everyOf(
    isAvailable(FDealType.Any, FOfferType.Flat | FOfferType.Room | FOfferType.Bed),
    isAvailableIfKeysUnset(['is_upcoming_sale']),
  ),
  component: RoomTypeContainer,
};
