import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { Bedrooms } from '../../../components/filters/Bedrooms';
import { useContext } from '../../../utils/useContext';

export const BedroomsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const bedrooms = getRangeValue('bedroom_total')(jsonQuery);

  const handleChange = React.useCallback(
    (gte: number | null, lte: number | null) => {
      onChange({ action: 'setBedrooms', arguments: [gte, lte] });
    },
    [onChange],
  );

  return <Bedrooms value={bedrooms} onChange={handleChange} />;
};
