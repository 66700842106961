import { NonEmptyArray } from '../../../Fields';

export const MOSCOW_ID = 1;
export const SPB_ID = 2;
export const MOSCOW_REGION_ID = 4593;
export const SPB_REGION_ID = 4588;
export const REGIONS_WITH_UNDERGROUND: NonEmptyArray<number> = [
  MOSCOW_ID,
  MOSCOW_REGION_ID,
  SPB_ID,
  4743,
  4777,
  4885,
  4897,
  4966,
];
export const MOSCOW_AND_REGION = -1;
export const SPB_AND_REGION = -2;
