import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { FOfferType, isUrban } from '../../../../../JsonQuery';
import { ELayoutType } from '../../../../../api-models/common/json_query';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter, FilterRow } from '../common/Filter';

interface ILayout {
  label: string;
  value: ELayoutType;
}

const LAYOUT: ILayout[] = [
  { label: 'Смежная', value: ELayoutType.UnitedRooms },
  { label: 'Изолированная', value: ELayoutType.IsolatedRooms },
];

interface ILayoutProps {
  offerType: FOfferType;
  layoutType: ELayoutType | null;
  withLayout: boolean | null;
  onLayoutTypeChange(value: ELayoutType | null): void;
  onWithLayoutChange(value: boolean | null): void;
}

export const Layout: React.FC<ILayoutProps> = ({
  offerType,
  layoutType,
  withLayout,
  onLayoutTypeChange,
  onWithLayoutChange,
}) => {
  const isLayoutTypeAvailable = isUrban(offerType);

  return (
    <Filter id="filter_layout" label="Планировка">
      {isLayoutTypeAvailable && (
        <FilterRow>
          <RadioButtonGroupUnselectable
            options={LAYOUT}
            value={layoutType}
            onChange={onLayoutTypeChange}
            spliced={false}
          />
        </FilterRow>
      )}
      <FilterRow>
        <Switch
          label="Есть схема планировки"
          checked={!!withLayout}
          onChange={event => onWithLayoutChange(event.target.checked ? true : null)}
        />
      </FilterRow>
    </Filter>
  );
};
