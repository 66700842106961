import { ca } from '@cian/analytics';

import { ETab, IRegionalDistrict } from '../../../../../../../../shared/types/regionalDistricts';

export const trackModalOpen = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: 'districts',
  });
};

export const trackTabChange = (tab: ETab) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: `districts_view_${tab}`,
  });
};

export const trackSearchClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: 'search_field',
  });
};

export const trackDistrictClick = (districtName: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: 'district_from_list',
    page: {
      extra: {
        district: districtName,
      },
      pageType: 'Filter',
      siteType: 'mobile',
    },
  });
};

export const trackDistrictsApply = (selectedDistricts: IRegionalDistrict[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: 'map_buttons_apply',
    page: {
      extra: {
        districts: JSON.stringify(selectedDistricts.map(({ name }) => name)),
      },
      pageType: 'Filter',
      siteType: 'mobile',
    },
  });
};

export const trackDistrictsReset = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'search_filers',
    action: 'click_sopr',
    label: 'map_buttons_drop',
  });
};
