import { MOSCOW_AND_REGION, SPB_AND_REGION } from '../../../../packages/Filters/shared/constants/regions';
import { TJsonQuery, isSuburban, offerTypeFromJsonQuery } from '../../../../packages/JsonQuery';
import { IApplicationContext } from '../../../types/applicationContext';
import { IRegionMetaState } from '../../../types/regionMeta';

interface ICutDistrictsIfNeededParams {
  regionMeta: IRegionMetaState;
  jsonQuery: TJsonQuery;
}

/**
 * Для МиМО и СпбЛО в загородке показываем областные районы вместо городских
 * https://jira.cian.tech/browse/HOM-556
 */
export const cutDistrictsIfNeeded = (
  { config }: IApplicationContext,
  { regionMeta, jsonQuery }: ICutDistrictsIfNeededParams,
): IRegionMetaState => {
  const isSuburbanFilters = isSuburban(offerTypeFromJsonQuery(jsonQuery));
  const regionalDistrictsForMskAndSpbEnabled = config.get('Countryside.RegionalDistrictsForMskAndSpb.Enabled');

  if (
    !isSuburbanFilters ||
    ![MOSCOW_AND_REGION, SPB_AND_REGION].includes(regionMeta.id) ||
    !regionalDistrictsForMskAndSpbEnabled
  ) {
    return regionMeta;
  }

  return {
    ...regionMeta,
    districts: null,
  };
};
