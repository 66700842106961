import { selectExperiments } from './selectExperiments';
import { AUD_GOOD_PRICE_FILTER_EXPERIMENT } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';
import { isExperimentActive } from '../../utils/experiments';

export const selectIsGoodPriceFilterEnabled = (state: IApplicationState) => {
  const experiments = selectExperiments(state);

  return isExperimentActive(experiments, AUD_GOOD_PRICE_FILTER_EXPERIMENT, 'B');
};
