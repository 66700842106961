import * as React from 'react';

import { JsonQuery } from '../../../../../JsonQuery';
import { IsEncumbranceRealty } from '../../../components/filters/IsEncumbranceRealty';
import { useContext } from '../../../utils/useContext';

export const IsEncumbranceRealtyContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const isEncumbranceRealty = new JsonQuery(jsonQuery).getIsEncumbranceRealty();

  const handleChange = React.useCallback(
    (isEncumbranceRealty: boolean | null) => {
      onChange({ action: 'setIsEncumbranceRealty', arguments: [isEncumbranceRealty] });
    },
    [onChange],
  );

  return <IsEncumbranceRealty onChange={handleChange} value={isEncumbranceRealty} />;
};
