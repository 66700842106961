import * as React from 'react';

import { RegionSelectContainerModal } from './containers/RegionSelectContainer';
import { Region } from '../../../components/filters/Region';
import { useContext } from '../../../utils/useContext';

export const RegionContainer: React.FC = () => {
  const { logger, regionMeta } = useContext();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <>
      <Region name={regionMeta.name} onClick={handleClick} />
      <RegionSelectContainerModal logger={logger} open={isOpen} onClose={handleClose} />
    </>
  );
};
