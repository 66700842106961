import { ca } from '@cian/analytics';

export const trackHouseTypeChoice = (isDacha: boolean | null) => {
  let label = 'irrelevant';

  if (typeof isDacha === 'boolean') {
    label = isDacha ? 'dacha' : 'house';
  }

  ca('eventSite', {
    name: 'oldevent',
    action: 'search_filter',
    category: 'house_type',
    label,
  });
};
