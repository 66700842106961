import { ConfigurationError } from '@cian/peperrors/shared';
import * as React from 'react';

import { getAvailableFilters } from './helpers';
import { FILTERS } from '../../constants/filters';
import { useContext } from '../../utils/useContext';

export const FiltersContainer: React.FC = () => {
  const context = useContext();
  if (!context) {
    throw new ConfigurationError({
      domain: 'Filters',
      message: "Filters used outside of it's context",
    });
  }

  const {
    jsonQuery,
    isVillageMortgageFilterEnabled,
    areDailyrentSuburbanFiltersActive,
    areDailyrentRoomFiltersActive,
    areDailyrentFlatFiltersActive,
    isMixedFiltersRedesign,
  } = context;

  const availableFilters = getAvailableFilters({
    jsonQuery,
    isVillageMortgageFilterEnabled,
    areDailyrentSuburbanFiltersActive,
    areDailyrentRoomFiltersActive,
    areDailyrentFlatFiltersActive,
    isMixedFiltersRedesign,
  });

  return (
    <>
      {availableFilters.map(filterKey => {
        const { availability, component: Component } = FILTERS[filterKey];

        if (availability && !availability(jsonQuery)) {
          return null;
        }

        return <Component key={`filter_${filterKey}`} />;
      })}
    </>
  );
};
