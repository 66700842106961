import * as React from 'react';

import { FDealType, FOfferType, getRangeValue, getTermValue } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { Price } from '../../../components/filters/Price';
import { SHRUG } from '../../../constants/shrug';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';
import { usePlaceholders } from '../../../utils/usePlaceholders';

const getLabelText = (price: IJsonQueryRangeValue | null, dealType: FDealType) => {
  const { gte: priceMin = null, lte: priceMax = null } = price || {};
  const showShrug = priceMin === 0 && priceMax === 0;

  let label = 'Цена';

  if (dealType === FDealType.RentDaily) {
    label += ' за сутки';
  }

  if (showShrug) {
    label += SHRUG;
  }

  return label;
};

export const PriceContainer: React.FC = () => {
  const { jsonQuery, onChange, isGoodPriceFilterEnabled } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();
  const price = getRangeValue('price')(jsonQuery);
  const isSquareMeterPrice = getTermValue('price_sm')(jsonQuery);
  const hasGoodPrice = getTermValue('has_good_price')(jsonQuery);
  const showHasGoodPriceFilter = dealType === FDealType.Sale && offerType === FOfferType.FlatOld;
  const label = getLabelText(price, dealType);
  const placeholders = usePlaceholders({ minKey: 'minPrice', maxKey: 'maxPrice' });

  const handlePriceMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setPriceMin', arguments: [gte] });
    },
    [onChange],
  );

  const handlePriceMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setPriceMax', arguments: [lte] });
    },
    [onChange],
  );

  const handlePriceTypeChange = React.useCallback(
    (isSquareMeterPrice: boolean | null) => {
      onChange({ action: 'setPriceType', arguments: [isSquareMeterPrice] });
    },
    [onChange],
  );

  const handleHasGoodPriceChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onChange({ action: 'setHasGoodPrice', arguments: [value || null] });
    },
    [onChange],
  );

  return (
    <Price
      label={label}
      dealType={dealType}
      offerType={offerType}
      price={price}
      isSquareMeterPrice={isSquareMeterPrice}
      onPriceMinChange={handlePriceMinChange}
      onPriceMaxChange={handlePriceMaxChange}
      onPriceTypeChange={handlePriceTypeChange}
      placeholders={placeholders}
      hasGoodPrice={hasGoodPrice}
      onHasGoodPriceChange={handleHasGoodPriceChange}
      showHasGoodPriceFilter={showHasGoodPriceFilter && isGoodPriceFilterEnabled}
    />
  );
};
