import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomsTotal } from '../../../../../api-models/common/json_query';
import { FlatRooms } from '../../../components/filters/FlatRooms';
import { useContext } from '../../../utils/useContext';

export const FlatRoomsContainer: React.FC = () => {
  const { jsonQuery, onChange, areDailyrentRoomFiltersActive } = useContext();

  const rooms = getTermsValue('rooms_count')(jsonQuery);

  const handleChange = React.useCallback(
    (value: NonEmptyArray<ERoomsTotal> | null) => {
      onChange({ action: 'setRoomsCount', arguments: [value] });
    },
    [onChange],
  );

  const label = areDailyrentRoomFiltersActive ? 'Количество комнат' : 'Комнат в квартире';

  return <FlatRooms label={label} value={rooms} onChange={handleChange} />;
};
