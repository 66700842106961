import { mergeMetaProps } from './mergeMetaProps';
import { IRegionMetaState } from '../../../types/regionMeta';

export const mergeRegionsMeta = (
  region: IRegionMetaState | null,
  area: IRegionMetaState | null,
): IRegionMetaState | null => {
  if (region && area) {
    const mergedRegionMeta =
      region.parentId === area.id
        ? {
            ...area,
            districts: mergeMetaProps(region.districts, area.districts),
            highways: mergeMetaProps(region.highways, area.highways),
            undergrounds: mergeMetaProps(region.undergrounds, area.undergrounds),
          }
        : area;

    mergedRegionMeta.expansibleRegion = region.id;

    return mergedRegionMeta;
  }

  return area || region;
};
