import { NonEmptyArray } from '../../../../../../../../Fields';
import { ETabType, TSelectedOptions } from '../../../../../../components/filters/Geo';

interface IGetCatalogSelectedOptionsParameters {
  selectedUndergrounds?: NonEmptyArray<number> | null;
  selectedDistricts?: NonEmptyArray<number> | null;
  selectedHighways?: NonEmptyArray<number> | null;
}

export function getCatalogSelectedOptions({
  selectedUndergrounds,
  selectedDistricts,
  selectedHighways,
}: IGetCatalogSelectedOptionsParameters): TSelectedOptions {
  const selectedOptions: TSelectedOptions = {};

  if (selectedUndergrounds) {
    selectedOptions[ETabType.Undergrounds] = selectedUndergrounds;
  }

  if (selectedDistricts) {
    selectedOptions[ETabType.Districts] = selectedDistricts;
  }

  if (selectedHighways) {
    selectedOptions[ETabType.Highways] = selectedHighways;
  }

  return selectedOptions;
}
