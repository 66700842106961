import * as React from 'react';

import * as styles from './UndergroundIcon.css';
import { getRegionUndergroundIcon } from '../../utils/getRegionUndergroundIcon';

interface IUndergroundIconProps {
  regionId: number[];
  color: Exclude<React.CSSProperties['color'], undefined>;
}

export const UndergroundIcon: React.FC<IUndergroundIconProps> = ({ regionId, color }) => {
  const Icon = getRegionUndergroundIcon(regionId);

  return (
    <div className={styles['container']} style={{ color }}>
      <Icon display="block" color="current_color" />
    </div>
  );
};
