import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EGarageStatus } from '../../../../../api-models/common/json_query';
import { GarageStatus } from '../../../components/filters/GarageStatus';
import { useContext } from '../../../utils/useContext';

export const GarageStatusContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const garageStatus = getTermsValue('garage_status')(jsonQuery);

  const handleChange = React.useCallback(
    (garageStatus: NonEmptyArray<EGarageStatus> | null) => {
      onChange({ action: 'setGarageStatus', arguments: [garageStatus] });
    },
    [onChange],
  );

  return <GarageStatus value={garageStatus} onChange={handleChange} />;
};
