import * as R from 'ramda';

import { NonEmptyArray } from '../../../packages/JsonQuery';

export function getReducedRegion(regionIdRaw: NonEmptyArray<number>): number {
  const regionId = [...regionIdRaw];
  regionId.sort();

  switch (true) {
    case R.equals(regionId, [1, 4593]):
      return -1;

    case R.equals(regionId, [2, 4588]):
      return -2;
  }

  return regionIdRaw[0];
}
