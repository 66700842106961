import * as React from 'react';

import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IApartments {
  label: string;
  value: boolean;
}

const APARTMENTS: IApartments[] = [
  { label: 'Не показывать апартаменты', value: false },
  { label: 'Только апартаменты', value: true },
];

interface IApartmentsProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const Apartments: React.FC<IApartmentsProps> = ({ value, onChange }) => {
  return (
    <Filter
      id="filter_apartments"
      label="Апартаменты"
      infoContent="В таком жилье нельзя прописаться и получить налоговый вычет за его покупку."
    >
      <RadioButtonGroupUnselectable
        options={APARTMENTS}
        value={value}
        onChange={(value: boolean | null) => onChange(value)}
        spliced={false}
      />
    </Filter>
  );
};
