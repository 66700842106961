import { InformationTooltip } from '@cian/ui-kit/tooltip';
import { ArticleHeading3, ArticleParagraph1 } from '@cian/ui-kit/typography';
import { IconFunctionalInformationCircle16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Filter.css';

interface IFilterProps {
  dataName?: string;
  id?: string;
  contentPadding?: boolean;
  compact?: boolean;
  label?: string;
  infoTitle?: string;
  infoContent?: React.ReactNode;
  targetRef?: React.RefObject<HTMLDivElement>;
}

export const Filter: React.FC<React.PropsWithChildren<IFilterProps>> = ({
  id,
  contentPadding = true,
  compact,
  label,
  infoTitle,
  infoContent,
  children,
  dataName,
  targetRef,
}) => {
  return (
    <div
      ref={targetRef}
      data-name={dataName}
      role="group"
      aria-labelledby={label && id ? id : undefined}
      className={classNames(styles['container'], { [styles['container--compact']]: compact })}
    >
      {!!label && (
        <div className={styles['title']}>
          <ArticleHeading3 as="div" id={id}>
            {label}
          </ArticleHeading3>
          {infoContent && (
            <div className={styles['info']}>
              <InformationTooltip
                content={
                  typeof infoContent === 'string' ? <ArticleParagraph1>{infoContent}</ArticleParagraph1> : infoContent
                }
                header={infoTitle || label}
                placement="top"
              >
                <IconFunctionalInformationCircle16 display="block" color="icon-secondary-default" />
              </InformationTooltip>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(styles['content'], {
          [styles['content--padding']]: contentPadding,
          [styles['content--labeled']]: !!label,
        })}
      >
        {children}
      </div>
    </div>
  );
};
