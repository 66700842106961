import { Select } from '@cian/ui-kit/select';
import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { EMaxPrepay } from '../../../../../api-models/common/json_query';
import { Filter, FilterRow } from '../common/Filter';

interface IPrepayOption {
  label: string;
  value: EMaxPrepay;
}

const PREPAY_OPTIONS: IPrepayOption[] = [
  { label: 'Неважно', value: EMaxPrepay.Any },
  { label: 'Не более 1 месяца', value: EMaxPrepay.Month },
  { label: 'Не более 2 месяцев', value: EMaxPrepay.TwoMonths },
  { label: 'Не более 3 месяцев', value: EMaxPrepay.ThreeMonths },
];

interface IRentTermsProps {
  prepay: EMaxPrepay | null;
  withDeposit: boolean | null;
  commissionType: number | null;
  onPrepayChange(value: number | null): void;
  onWithDepositChange(value: boolean | null): void;
  onCommissionTypeChange(value: number | null): void;
}

export const RentTerms: React.FC<IRentTermsProps> = ({
  prepay,
  withDeposit,
  commissionType,
  onPrepayChange,
  onWithDepositChange,
  onCommissionTypeChange,
}) => {
  const handleWithDepositChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onWithDepositChange(value ? false : null);
    },
    [onWithDepositChange],
  );

  const handleComissionTypeChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onCommissionTypeChange(value ? 0 : null);
    },
    [onCommissionTypeChange],
  );

  return (
    <Filter id="filter_rentTerms" label="Условия сделки">
      <FilterRow id="filter_rentTerms_prepay" label="Предоплата">
        <Select
          options={PREPAY_OPTIONS}
          value={prepay !== null ? prepay : EMaxPrepay.Any}
          onChange={(value: EMaxPrepay) => onPrepayChange(value === EMaxPrepay.Any ? null : value)}
        />
      </FilterRow>
      <FilterRow>
        <Switch label="Без залога" checked={withDeposit === false} onChange={handleWithDepositChange} />
      </FilterRow>
      <FilterRow>
        <Switch label="Без комиссии" checked={commissionType === 0} onChange={handleComissionTypeChange} />
      </FilterRow>
    </Filter>
  );
};
