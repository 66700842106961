import * as React from 'react';
import { useSelector } from 'react-redux';

import { SaveSearchProvider as Provider } from '../../../packages/SaveSearchModal';
import { selectJsonQuery } from '../../selectors/jsonQuery';
import { selectQueryString } from '../../selectors/searchMeta';
import { selectUser } from '../../selectors/user';
import { getSaveSearchMicrofrontend } from '../../services/getSaveSearchMicrofrontend';
import { useApplicationContext } from '../../utils/applicationContext';

enum ESource {
  Card = 'card',
  Listing = 'listing',
  Map = 'map',
}

export const SaveSearchModalProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const context = useApplicationContext();
  const fetchManifest = React.useCallback(async () => getSaveSearchMicrofrontend(context), [context]);

  const [title, setTitle] = React.useState<string | null>(null);
  const jsonQuery = useSelector(selectJsonQuery);
  const queryString = useSelector(selectQueryString);
  const user = useSelector(selectUser);
  /* istanbul ignore next */
  const email = React.useMemo(() => (user.isAuthenticated ? user.email : null), [user]);
  const isAuthenticated = user.isAuthenticated;

  React.useEffect(() => {
    setTitle(document.title);
  }, []);

  const params = {
    jsonQuery,
    email,
    title,
    isAuthenticated,
    source: ESource.Listing,
    analyticsParams: {
      pageType: 'Filter',
      listingType: 'list',
      queryString,
      isFromBanner: false,
    },
  };

  return (
    <Provider fetchManifest={fetchManifest} params={params}>
      {children}
    </Provider>
  );
};
