import { getGeoValue, TJsonQuery } from '../../../../../JsonQuery';
import { ISpecialPromo } from '../../../types/specialPromo';

export function getIsSpecialPromo(jsonQuery: TJsonQuery, specialPromo: ISpecialPromo | null): boolean | null {
  const geo = getGeoValue()(jsonQuery);

  if (!geo || !specialPromo) {
    return null;
  }

  return geo.some(value => value.type === 'builder' && value.id === specialPromo.developerId) || null;
}
