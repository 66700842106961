import { TJsonQuery } from '../../../packages/JsonQuery';
import { ResponseError } from '../../errors';
import { prepareGeoTags } from '../../mappers/prepareGeoTags';
import { fetchGetGeoTags, TGetGeoTagsResponse } from '../../repositories/geo-temp-layer/v1/get-geo-tags';
import { IApplicationContext } from '../../types/applicationContext';
import { TTag } from '../../types/tags';

interface IFetchGeoTagsParameters {
  jsonQuery: TJsonQuery;
  subdomain: string;
}

export async function fetchGeoTags(
  context: IApplicationContext,
  { jsonQuery, subdomain }: IFetchGeoTagsParameters,
): Promise<TTag[] | null> {
  const { httpApi } = context;

  const geoTagsData: TGetGeoTagsResponse = await fetchGetGeoTags({
    httpApi,
    parameters: {
      jsonQuery,
      subdomainName: subdomain,
    },
  });

  if (geoTagsData.statusCode !== 200) {
    throw new ResponseError({
      domain: 'fetchGeoTags',
      message: geoTagsData.response.message,
      details: {
        error: geoTagsData.response.errors,
      },
    });
  }

  return prepareGeoTags(geoTagsData.response);
}

export async function fetchGeoTagsSafe(
  context: IApplicationContext,
  parameters: IFetchGeoTagsParameters,
): Promise<TTag[] | null> {
  try {
    return await fetchGeoTags(context, parameters);
  } catch (ex) {
    const { logger } = context;

    logger.warning('[Degradation] geoTags', ex);

    return null;
  }
}
