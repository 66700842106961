import { pathOr } from 'ramda';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { EParkingType } from '../../../../../api-models/common/json_query';
import { Parking } from '../../../components/filters/Parking';
import { useContext } from '../../../utils/useContext';

export interface IParking {
  label: string;
  value: EParkingType;
}

const PARKING_TYPES: IParking[] = [
  { label: 'Наземная', value: EParkingType.Ground },
  { label: 'Многоуровневая', value: EParkingType.Multilevel },
  { label: 'Подземная', value: EParkingType.Underground },
  { label: 'На крыше', value: EParkingType.Rooftop },
];

const DAILYRENT_PARKING_TYPES: IParking[] = [
  { label: 'Наземная', value: EParkingType.Ground },
  { label: 'Подземная', value: EParkingType.Underground },
];

export const ParkingContainer: React.FC = () => {
  const { jsonQuery, onChange, areDailyrentFlatFiltersActive, areDailyrentRoomFiltersActive } = useContext();

  const showDailyrentReducedOptions = areDailyrentFlatFiltersActive || areDailyrentRoomFiltersActive;

  let parkingType = pathOr<EParkingType[] | null>(null, ['parking_type', 'value'], jsonQuery);
  if (parkingType && parkingType.length === 0) {
    parkingType = null;
  }

  const handleChange = React.useCallback(
    (parkingType: EParkingType[] | null) => {
      onChange({ action: 'setParkingType', arguments: [parkingType] });
    },
    [onChange],
  );

  const options = showDailyrentReducedOptions ? DAILYRENT_PARKING_TYPES : PARKING_TYPES;

  return (
    <Parking options={options} value={parkingType as NonEmptyArray<EParkingType> | null} onChange={handleChange} />
  );
};
