import { ArticleParagraph2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './InlineFilterSection.css';

interface IFilterSectionProps {
  title: string | null;
}

export const InlineFilterSection: React.FC<React.PropsWithChildren<IFilterSectionProps>> = ({ title, children }) => {
  return (
    <div className={styles['section']}>
      {title && (
        <div className={styles['title']}>
          <ArticleParagraph2>{title}</ArticleParagraph2>
        </div>
      )}
      <div className={styles['content']}>{children}</div>
    </div>
  );
};
