import { addDays, formatISO, parseISO, startOfToday } from 'date-fns';
import * as React from 'react';

import { trackApplyDatesModal, trackCloseDatesModal, trackOpenDatesModal, trackResetDatesModal } from './tracking';
import { formatInputValue, isJsonQueryDatesSimilar } from './utils';
import { getDateRangeValue } from '../../../../../JsonQuery';
import { IJsonQueryDateRangeValue } from '../../../../../api-models/common/json_query';
import { Dates } from '../../../components/filters/Dates';
import { DateRangeSelectModal } from '../../../components/filters/Dates/components/DateRangeSelectModal';
import { useContext } from '../../../utils/useContext';

interface IState {
  jsonQueryDates: IJsonQueryDateRangeValue | null;
  fromValue: Date | undefined;
  toValue: Date | undefined;
}

const initialState: IState = {
  jsonQueryDates: null,
  fromValue: undefined,
  toValue: undefined,
};

interface IJsonQueryDatesChanged {
  type: 'jsonQueryDatesChanged';
  jsonQueryDates: IJsonQueryDateRangeValue | null;
}

interface IRangeChanged {
  type: 'rangeChanged';
  fromValue: Date | undefined;
  toValue: Date | undefined;
}

type TActions = IJsonQueryDatesChanged | IRangeChanged;

function reducer(state: IState, action: TActions): IState {
  switch (action.type) {
    case 'rangeChanged':
      return { ...state, fromValue: action.fromValue, toValue: action.toValue };

    case 'jsonQueryDatesChanged':
      return {
        jsonQueryDates: action.jsonQueryDates,
        fromValue: action.jsonQueryDates ? parseISO(action.jsonQueryDates.gte) : undefined,
        toValue: action.jsonQueryDates ? parseISO(action.jsonQueryDates.lt) : undefined,
      };
  }
}

interface IDateContainerProps {
  initialValues?: IState;
}

export const DatesContainer: React.FC<IDateContainerProps> = ({ initialValues = initialState }) => {
  const { jsonQuery, onChange, datesModal } = useContext();
  const [state, dispatch] = React.useReducer(reducer, initialValues);

  const today = startOfToday();
  const minDate = today;
  const maxDate = addDays(today, 365);

  const handleChange = React.useCallback(
    (from: Date | undefined, to: Date | undefined) => {
      let fromValue = from;
      let toValue = to;

      if (fromValue && !toValue) {
        toValue = addDays(fromValue, 1);
      }
      if (toValue && !fromValue) {
        fromValue = addDays(toValue, -1);
      }
      if (fromValue && toValue && fromValue >= toValue) {
        toValue = addDays(fromValue, 1);
      }

      dispatch({
        type: 'rangeChanged',
        fromValue,
        toValue,
      });

      if (fromValue && toValue) {
        const gte = formatISO(fromValue, { representation: 'date' });
        const lt = formatISO(toValue, { representation: 'date' });

        if (!state.jsonQueryDates || !isJsonQueryDatesSimilar(state.jsonQueryDates, { gte, lt })) {
          onChange({ action: 'setDates', arguments: [gte, lt] });
        }
      } else if (state.jsonQueryDates) {
        onChange({ action: 'resetTerms', arguments: [['dates']] });
      }
    },
    [state, dispatch, onChange],
  );

  React.useEffect(() => {
    const jsonQueryDates = getDateRangeValue('dates')(jsonQuery);

    if (!isJsonQueryDatesSimilar(state.jsonQueryDates, jsonQueryDates)) {
      dispatch({
        type: 'jsonQueryDatesChanged',
        jsonQueryDates,
      });
    }
  }, [state, dispatch, jsonQuery]);

  React.useEffect(() => {
    const isFromGreaterThanTo = state.fromValue && state.toValue && state.fromValue >= state.toValue;
    const shouldReset = isFromGreaterThanTo;

    if (shouldReset) {
      onChange({ action: 'resetTerms', arguments: [['dates']] });
    }
  }, [state, onChange]);

  const handleClick = React.useCallback(() => {
    trackOpenDatesModal();
    datesModal.openModal();
  }, [datesModal.openModal]);

  const handleClose = React.useCallback(() => {
    trackCloseDatesModal();
    datesModal.closeModal();
  }, [datesModal.closeModal]);

  const handleApply = React.useCallback(() => {
    trackApplyDatesModal();
    datesModal.onApply();
  }, [datesModal.closeModal]);

  return (
    <>
      <Dates value={formatInputValue(state.fromValue, state.toValue)} onClick={handleClick} />
      {datesModal.isOpen && (
        <DateRangeSelectModal
          onClose={handleClose}
          initialFrom={state.fromValue}
          initialTo={state.toValue}
          onChange={handleChange}
          onApply={handleApply}
          onReset={trackResetDatesModal}
          isLoading={datesModal.isLoading}
          min={minDate}
          max={maxDate}
        />
      )}
    </>
  );
};
