import { ISuburbanFacility } from './types';
import { EFacilityType, FDealType } from '../../../../../JsonQuery';

const SUBURBAN_FACILITIES: ISuburbanFacility[] = [
  { label: 'Баня', value: EFacilityType.Sauna },
  { label: 'Гараж', value: EFacilityType.Garage },
  { label: 'Бассейн', value: EFacilityType.Pool },
];

const SALE_SUBURBAN_FACILITIES: ISuburbanFacility[] = [
  { label: 'Баня', value: EFacilityType.Sauna },
  { label: 'Гараж', value: EFacilityType.Garage },
  { label: 'Охрана', value: EFacilityType.Security },
  { label: 'Бассейн', value: EFacilityType.Pool },
];

const DAILYRENT_SUBURBAN_FACILITIES: ISuburbanFacility[] = [
  { label: 'Холодильник', value: EFacilityType.Fridge },
  { label: 'Кондиционер', value: EFacilityType.Conditioner },
  { label: 'Мебель в комнатах', value: EFacilityType.RoomFurniture },
  { label: 'Кухня', value: EFacilityType.KitchenFurniture },
  { label: 'Стиральная машина', value: EFacilityType.Washer },
  { label: 'Интернет', value: EFacilityType.Internet },
  { label: 'Телевизор', value: EFacilityType.TV },
  { label: 'Посудомоечная машина', value: EFacilityType.Dishwasher },
  { label: 'Гараж', value: EFacilityType.Garage },
  { label: 'Бассейн', value: EFacilityType.Pool },
  { label: 'Баня', value: EFacilityType.Sauna },
];

export function getSuburbanFacilitiesList(dealType: FDealType): ISuburbanFacility[] {
  if (dealType === FDealType.Sale) {
    return SALE_SUBURBAN_FACILITIES;
  }

  if (dealType === FDealType.RentDaily) {
    return DAILYRENT_SUBURBAN_FACILITIES;
  }

  return SUBURBAN_FACILITIES;
}
