import * as React from 'react';

import { getCompletionYearOptions } from './helpers';
import { useDebouncedCallback } from '../../../../../Fields';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { NonEmptyArray, ECompletionYearType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { InlineFilterSection } from '../common/InlineFilterSection';

interface IConstructionYearProps {
  constructionYear: IJsonQueryRangeValue | null;
  yearOffset: number;
  newbuildingCompletionYear: NonEmptyArray<ECompletionYearType> | null;
  mixed: boolean;
  onConstructionYearMinChange(gte: number | null): void;
  onConstructionYearMaxChange(lte: number | null): void;
  onCompletionYearChange: (completionYear: NonEmptyArray<ECompletionYearType> | null) => void;
}

export const ConstructionYear: React.FC<IConstructionYearProps> = ({
  constructionYear,
  yearOffset,
  newbuildingCompletionYear,
  mixed,
  onConstructionYearMinChange,
  onConstructionYearMaxChange,
  onCompletionYearChange,
}) => {
  const { gte: constructionYearMin = null, lte: constructionYearMax = null } = constructionYear || {};

  const completionYearOptions = getCompletionYearOptions(yearOffset);

  const handleConstructionYearMinChange = useDebouncedCallback((gte: number | null) => {
    onConstructionYearMinChange(gte);
  }, 300);

  const handleConstructionYearMaxChange = useDebouncedCallback((lte: number | null) => {
    onConstructionYearMaxChange(lte);
  }, 300);

  const rangeInput = (
    <RangeInput
      min={1336}
      max={2038}
      thousandsSeparator=""
      joined={false}
      valueMin={constructionYearMin}
      valueMax={constructionYearMax}
      onChangeMin={handleConstructionYearMinChange}
      onChangeMax={handleConstructionYearMaxChange}
    />
  );

  return (
    <Filter dataName="filter_constructionYear" label="Год постройки">
      {mixed ? (
        <>
          <InlineFilterGroup>
            <InlineFilterSection title="На вторичке">{rangeInput}</InlineFilterSection>
          </InlineFilterGroup>
          <InlineFilterGroup>
            <InlineFilterSection title="В новостройках">
              <CheckboxButtonGroup
                options={completionYearOptions}
                value={newbuildingCompletionYear}
                onChange={onCompletionYearChange}
              />
            </InlineFilterSection>
          </InlineFilterGroup>
        </>
      ) : (
        rangeInput
      )}
    </Filter>
  );
};
