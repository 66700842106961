import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { HouseArea } from '../../../components/filters/HouseArea';
import { useContext } from '../../../utils/useContext';
import { usePlaceholders } from '../../../utils/usePlaceholders';

export const HouseAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const houseArea = getRangeValue('total_area')(jsonQuery);
  const placeholders = usePlaceholders({ minKey: 'minTotalArea', maxKey: 'maxTotalArea' });

  const handleHouseAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleHouseAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <HouseArea
      houseArea={houseArea}
      placeholders={placeholders}
      onHouseAreaMinChange={handleHouseAreaMinChange}
      onHouseAreaMaxChange={handleHouseAreaMaxChange}
    />
  );
};
