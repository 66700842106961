import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { Filter } from '../common/Filter';

interface IElectronicTradingOption {
  label: string;
  value: number | null;
}

const ELECTRONIC_TRADING: IElectronicTradingOption[] = [
  { label: 'Неважно', value: null },
  { label: 'Не показывать', value: 2 },
  { label: 'Только торги и залоговая недвижимость', value: 1 },
];

interface IElectronicTradingProps {
  value: number | null;
  onChange(value: number | null): void;
}

export const ElectronicTrading: React.FC<IElectronicTradingProps> = ({ value, onChange }) => {
  return (
    <Filter dataName="filter_ElectronicTrading" label="Торги и залоговая недвижимость">
      <RadioButtonGroup spliced={false} options={ELECTRONIC_TRADING} value={value} onChange={onChange} />
    </Filter>
  );
};
