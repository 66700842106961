import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EDecorationType } from '../../../../../JsonQuery/types/jsonQuery/jsonQueryDecorationType';
import { ERepair } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { InlineFilterSection } from '../common/InlineFilterSection';

interface IOption<T> {
  label: string;
  value: T | null;
}

const REPAIRS: IOption<ERepair>[] = [
  { label: 'Неважно', value: null },
  { label: 'Без ремонта', value: ERepair.Without },
  { label: 'Косметический', value: ERepair.Cosmetic },
  { label: 'Евроремонт', value: ERepair.Euro },
  { label: 'Дизайнерский', value: ERepair.Design },
];

const DECORATIONS: IOption<EDecorationType>[] = [
  { label: 'Неважно', value: null },
  { label: 'Чистовая с мебелью', value: EDecorationType.FineWithFurniture },
  { label: 'Чистовая', value: EDecorationType.Fine },
  { label: 'Предчистовая', value: EDecorationType.PreFine },
  { label: 'Без отделки', value: EDecorationType.Without },
];

interface IRenovationProps {
  repairValue: NonEmptyArray<ERepair> | null;
  decorationValue: NonEmptyArray<EDecorationType> | null;
  onRepairChange(value: NonEmptyArray<ERepair> | null): void;
  onDecorationChange(value: NonEmptyArray<EDecorationType> | null): void;
}

export const Renovation: React.FC<IRenovationProps> = ({
  repairValue,
  decorationValue,
  onRepairChange,
  onDecorationChange,
}) => {
  return (
    <Filter dataName="filter_renovation" label="Ремонт и отделка">
      <InlineFilterGroup>
        <InlineFilterSection title="На вторичке">
          <CheckboxButtonGroup options={REPAIRS} value={repairValue} onChange={onRepairChange} />
        </InlineFilterSection>
      </InlineFilterGroup>
      <InlineFilterGroup>
        <InlineFilterSection title="В новостройках">
          <CheckboxButtonGroup options={DECORATIONS} value={decorationValue} onChange={onDecorationChange} />
        </InlineFilterSection>
      </InlineFilterGroup>
    </Filter>
  );
};
