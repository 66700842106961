import * as React from 'react';

import { FDealType, getTermsValue } from '../../../../../JsonQuery';
import { RootDealType } from '../../../components/filters/RootDealType';
import { ESpecialDealTypes } from '../../../types/specialDealTypes';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const RootDealTypeContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();
  const dealType = useDealType();
  const value = React.useMemo(() => {
    const isRent = dealType === FDealType.RentLongterm || dealType === FDealType.RentDaily;

    return isRent ? FDealType.Rent : dealType;
  }, [dealType]);
  const region = getTermsValue('region')(jsonQuery);

  const handleChange = React.useCallback(
    (dealType: FDealType | ESpecialDealTypes) => {
      if (dealType === ESpecialDealTypes.SuburbanBuildersProjects) {
        window.location.href = `/country-builders-filters/${region ? `?region=${region[0]}` : ''}`;
      } else {
        const newDealType = dealType === FDealType.Rent ? FDealType.RentLongterm : dealType;

        onChange({ action: 'setDealType', arguments: [newDealType] });
      }
    },
    [onChange, region],
  );

  return <RootDealType value={value} onChange={handleChange} />;
};
