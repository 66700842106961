import { Button } from '@cian/ui-kit/button';
import { IconProductSaveSearch16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './SaveSearchButton.css';

interface ISaveSearchButtonProps {
  onClick(): void;
}

export const SaveSearchButton: React.FC<ISaveSearchButtonProps> = ({ onClick }) => {
  return (
    <div className={styles['container']}>
      <Button
        theme="fill_secondary"
        beforeIcon={<IconProductSaveSearch16 color="icon-main-default" />}
        fullWidth
        onClick={onClick}
      >
        Сохранить поиск
      </Button>
    </div>
  );
};
