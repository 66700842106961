import { IconMapBus16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './UndergroundTime.css';
import { MaskedInput } from '../../../../../Fields/shared/components/MaskedInput';
import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { EMetroTravelType } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IUndergroundTimeOption {
  value: EMetroTravelType;
  label: string;
}

const UNDERGROUND_TRAVEL_OPTIONS: IUndergroundTimeOption[] = [
  {
    value: EMetroTravelType.MapFoot,
    label: 'Пешком',
  },
  {
    value: EMetroTravelType.MapTransport,
    label: 'На транспорте',
  },
];

const UNDERGROUND_TRAVEL_OPTIONS_SMALL: IUndergroundTimeOption[] = [
  {
    value: EMetroTravelType.MapFoot,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    label: (
      <span className={styles['icon-container']}>
        <IconMapWalk16 height={40} />
      </span>
    ),
  },
  {
    value: EMetroTravelType.MapTransport,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    label: (
      <span className={styles['icon-container']}>
        <IconMapBus16 />
      </span>
    ),
  },
];

interface IUndergroundTimeProps {
  travelType: EMetroTravelType | null;
  travelTime: number | null;
  onTravelTypeChange(type: EMetroTravelType | null): void;
  onTravelTimeChange(time: number | null): void;
}

export const UndergroundTime: React.FC<IUndergroundTimeProps> = ({
  travelType,
  travelTime,
  onTravelTypeChange,
  onTravelTimeChange,
}) => {
  const handleTravelTimeChange = useDebouncedCallback((time: string) => {
    onTravelTimeChange(time ? Number(time) : null);
  }, 300);

  const handleTravelTypeChange = React.useCallback(
    (type: EMetroTravelType | null) => {
      onTravelTypeChange(type);
    },
    [onTravelTypeChange],
  );

  return (
    <Filter id="filter_undergroundTime" label="Время до метро">
      <div className={styles['container']}>
        <div className={styles['travel-time']}>
          <MaskedInput
            placeholder="до"
            min={1}
            max={300}
            mask={Number}
            rightAdornment={<span className={styles['adornment']}>мин</span>}
            value={travelTime === null ? '' : String(travelTime)}
            onComplete={handleTravelTimeChange}
          />
        </div>
        <div className={styles['travel-type']}>
          <div className={styles['travel-type--text']}>
            <RadioButtonGroup
              options={UNDERGROUND_TRAVEL_OPTIONS}
              value={travelType || EMetroTravelType.MapFoot}
              onChange={handleTravelTypeChange}
              spliced
            />
          </div>
          <div className={styles['travel-type--icon']}>
            <RadioButtonGroup
              options={UNDERGROUND_TRAVEL_OPTIONS_SMALL}
              value={travelType || EMetroTravelType.MapFoot}
              onChange={handleTravelTypeChange}
              size="M"
              spliced
            />
          </div>
        </div>
      </div>
    </Filter>
  );
};
