import * as React from 'react';

import {
  everyOf,
  FDealType,
  FOfferType,
  getTermValue,
  isAvailable,
  isAvailableRegion,
  offerTypeFromJsonQuery,
} from '../../../../../JsonQuery';
import { DealType } from '../../../components/filters/DealType';
import { MOSCOW_ID } from '../../../constants/regions';
import { useContext } from '../../../utils/useContext';

export const DealTypeContainer: React.FC = () => {
  const {
    config,
    jsonQuery,
    onChange,
    areDailyrentRoomFiltersActive,
    areDailyrentFlatFiltersActive,
    areDailyrentSuburbanFiltersActive,
  } = useContext();
  const isByHomeowner = getTermValue('is_by_homeowner')(jsonQuery);
  const isDomRfEnabled = config.get<boolean>('ArendaDomRf.Enabled') || false;
  const isDomRfAvailable =
    isDomRfEnabled &&
    everyOf(isAvailable(FDealType.RentLongterm, FOfferType.Flat), isAvailableRegion([MOSCOW_ID]))(jsonQuery);
  const isByDomRf = getTermValue('domrf')(jsonQuery);
  const withDeposit = getTermValue('zalog')(jsonQuery);
  const fromDeveloper = getTermValue('from_developer')(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const showFromDeveloperFilter = offerType !== FOfferType.FlatNew && offerType !== FOfferType.FlatOld;

  const areDailyrentFiltersActive =
    areDailyrentRoomFiltersActive || areDailyrentFlatFiltersActive || areDailyrentSuburbanFiltersActive;

  const handleIsByHomeownerChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setIsByHomeowner', arguments: [value] });
    },
    [onChange],
  );

  const handleIsByDomRfChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setIsByDomRf', arguments: [value] });
    },
    [onChange],
  );

  const handleWithDepositChange = React.useCallback(
    (withDeposit: boolean | null) => {
      onChange({ action: 'setWithDeposit', arguments: [withDeposit] });
    },
    [onChange],
  );

  const handleFromDeveloperChange = React.useCallback(
    (fromDeveloper: boolean | null) => {
      onChange({ action: 'setFromDeveloper', arguments: [fromDeveloper] });
    },
    [onChange],
  );

  return (
    <DealType
      isFromDeveloper={fromDeveloper}
      isByHomeowner={isByHomeowner}
      domRfAvailable={isDomRfAvailable}
      isByDomRf={isByDomRf}
      onIsByHomeownerChange={handleIsByHomeownerChange}
      onIsByDomRfChange={handleIsByDomRfChange}
      showDepositFilter={areDailyrentFiltersActive}
      showFromDeveloperFilter={showFromDeveloperFilter}
      withDeposit={withDeposit}
      onWithDepositChange={handleWithDepositChange}
      onIsFromDeveloperChange={handleFromDeveloperChange}
    />
  );
};
