import { ca } from '@cian/analytics';

export const trackOpenDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'show_sopr',
    category: 'DailyrentCalendar',
    label: 'Popup',
  });
};
