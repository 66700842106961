import { createBrowserHistory } from 'history';
import { debounce } from 'throttle-debounce';

import { buildSearchString, utmParamsToString } from './utils';
import { IApplicationContext } from '../../../shared/types/applicationContext';
import { IApplicationState } from '../../../shared/types/redux';

const history = createBrowserHistory();

function queryStringUpdaterFn(context: IApplicationContext, state: IApplicationState) {
  const searchParams = window.location.search.slice(1);

  /* istanbul ignore else */
  if (searchParams !== state.searchMeta.queryString) {
    const { origin } = context.custom;

    const utmParams = utmParamsToString(searchParams);

    const newLocation = {
      pathname: history.location.pathname,
      search: buildSearchString(state.searchMeta.queryString, origin, utmParams),
      hash: history.location.hash,
      state: history.location.state,
    };

    history.replace(newLocation);
  }
}

export const queryStringUpdater = debounce(300, queryStringUpdaterFn);
