import { pathOr } from 'ramda';

import { IGetUserDataByRequestResponse } from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { TUser } from '../../types/user';

export function prepareUser(response: IGetUserDataByRequestResponse): TUser {
  const isAuthenticated = pathOr(false, ['user', 'isAuthenticated'], response.data);

  return {
    isAuthenticated,
    email: isAuthenticated ? pathOr(null, ['user', 'email'], response.data) : null,
    userId: isAuthenticated ? pathOr(null, ['user', 'userId'], response.data) : null,
  } as TUser;
}
