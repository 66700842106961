import * as React from 'react';

import { NonEmptyArray } from '../../../../../Fields';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { EFloor } from '../../../constants/floors';
import { Filter, FilterRow } from '../common/Filter';

interface IFloor {
  label: string;
  value: EFloor;
}

const FLOORS: IFloor[] = [
  { label: 'Не первый', value: EFloor.NotFirst },
  { label: 'Не последний', value: EFloor.NotLast },
  { label: 'Только последний', value: EFloor.OnlyLast },
];

interface IFloorProps {
  floor: IJsonQueryRangeValue | null;
  floors: NonEmptyArray<EFloor> | null;
  onFloorMinChange(gte: number | null): void;
  onFloorMaxChange(lte: number | null): void;
  onFloorsChange(floors: NonEmptyArray<EFloor> | null): void;
}

export const Floor: React.FC<IFloorProps> = ({ floor, floors, onFloorMinChange, onFloorMaxChange, onFloorsChange }) => {
  const { gte: floorMin = null, lte: floorMax = null } = floor || {};

  const handleFloorMinChange = useDebouncedCallback((gte: number | null) => {
    onFloorMinChange(gte);
  }, 300);

  const handleFloorMaxChange = useDebouncedCallback((lte: number | null) => {
    onFloorMaxChange(lte);
  }, 300);

  return (
    <Filter label="Этаж">
      <FilterRow>
        <RangeInput
          min={1}
          max={200}
          joined={false}
          valueMin={floorMin}
          valueMax={floorMax}
          onChangeMin={handleFloorMinChange}
          onChangeMax={handleFloorMaxChange}
        />
      </FilterRow>
      <FilterRow>
        <CheckboxButtonGroup options={FLOORS} value={floors} onChange={onFloorsChange} spliced={false} />
      </FilterRow>
    </Filter>
  );
};
