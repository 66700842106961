import * as React from 'react';

import { ECompletionYearType, FDealType, FOfferType, getRangeValue, NonEmptyArray } from '../../../../../JsonQuery';
import { getCompletionYearTypes } from '../../../../../JsonQuery/getCompletionYearTypes';
import { ConstructionYear } from '../../../components/filters/ConstructionYear';
import { AVAILABLE_COMPLETION_YEAR_TYPES } from '../../../constants/availableCompletionYearTypes';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const ConstructionYearContainer: React.FC = () => {
  const { jsonQuery, onChange, isMixedFiltersRedesign, currentYear } = useContext();

  const dealType = useDealType();
  const offerType = useOfferType();

  const newbuildingCompetitionYear = getCompletionYearTypes(jsonQuery, {
    yearOffset: currentYear,
    availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
  });

  const constructionYear = getRangeValue('house_year')(jsonQuery);

  const handleConstructionYearMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setConstructionYearMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleConstructionYearMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setConstructionYearMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleCompletionYearChange = React.useCallback(
    (completionYearTypes: NonEmptyArray<ECompletionYearType> | null) => {
      onChange({
        action: 'setCompletionYearTypes',
        arguments: [
          {
            completionYearTypes,
            yearOffset: currentYear,
            availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
          },
        ],
      });
    },
    [currentYear, onChange],
  );

  return (
    <ConstructionYear
      yearOffset={currentYear}
      constructionYear={constructionYear}
      newbuildingCompletionYear={newbuildingCompetitionYear}
      mixed={isMixedFiltersRedesign && dealType === FDealType.Sale && offerType === FOfferType.Flat}
      onConstructionYearMinChange={handleConstructionYearMinChange}
      onConstructionYearMaxChange={handleConstructionYearMaxChange}
      onCompletionYearChange={handleCompletionYearChange}
    />
  );
};
