import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { TagsInput } from '../../TagsInput';
import { Filter } from '../common/Filter';

interface IExcludeWordsProps {
  value: NonEmptyArray<string> | null;
  onChange(value: NonEmptyArray<string> | null): void;
}

export const ExcludeWords: React.FC<IExcludeWordsProps> = ({ value: values, onChange }) => {
  const handleAdd = React.useCallback(
    (value: string) => {
      onChange(values ? [...values, value] : [value]);
    },
    [values, onChange],
  );

  const handleRemove = React.useCallback(
    (value: string) => {
      let nextValue = values ? values.filter(v => v !== value) : null;
      if (nextValue && nextValue.length === 0) {
        nextValue = null;
      }

      onChange(nextValue as NonEmptyArray<string>);
    },
    [values, onChange],
  );

  return (
    <Filter id="filter_excludeWords" label="Исключить слова в описании">
      <TagsInput
        id="filter_excludeWords_tagsInput"
        placeholder="Например: переуступка"
        value={values}
        onAdd={handleAdd}
        onRemove={handleRemove}
      />
    </Filter>
  );
};
