import { pathOr } from 'ramda';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { ESaleType } from '../../../../../api-models/common/json_query';
import { SaleType } from '../../../components/filters/SaleType';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SaleTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const saleType = pathOr<NonEmptyArray<ESaleType> | null>(null, ['sost_type', 'value'], jsonQuery);
  const mortgageAvailable = pathOr<boolean | null>(null, ['ipoteka', 'value'], jsonQuery);

  const handleSaleTypeChange = React.useCallback(
    (saleType: NonEmptyArray<ESaleType> | null) => {
      onChange({ action: 'setSaleTypes', arguments: [saleType] });
    },
    [onChange],
  );

  const handleMortgageAvailableChange = React.useCallback(
    (mortgageAvailable: boolean | null) => {
      onChange({ action: 'setMortgageAvailable', arguments: [mortgageAvailable] });
    },
    [onChange],
  );

  return (
    <SaleType
      offerType={offerType}
      saleType={saleType}
      mortgageAvailable={mortgageAvailable}
      onSaleTypeChange={handleSaleTypeChange}
      onMortgageAvailableChange={handleMortgageAvailableChange}
    />
  );
};
