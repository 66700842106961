import * as React from 'react';

import { NonEmptyArray, TModifier } from '../../../../../Fields';
import { EElevatorType, getTermValue, JsonQuery } from '../../../../../JsonQuery';
import { DailyrentFloor } from '../../../components/filters/DailyrentFloor';
import { EFloor } from '../../../constants/floors';
import { useContext } from '../../../utils/useContext';

export interface IFloor {
  label: string;
  value: EFloor;
}

const FLOORS: IFloor[] = [
  { label: 'Не первый', value: EFloor.NotFirst },
  { label: 'Не последний', value: EFloor.NotLast },
];

export const DailyrentFloorContainer = () => {
  const { jsonQuery, onChange } = useContext();

  const isFirstFloor = getTermValue('is_first_floor')(jsonQuery);
  const isNotLastFloor = getTermValue('not_last_floor')(jsonQuery);
  const hasElevator = new JsonQuery(jsonQuery).getElevatorType() === EElevatorType.Any;

  const floors = React.useMemo(() => {
    const floors: EFloor[] = [];

    if (isFirstFloor === false) {
      floors.push(EFloor.NotFirst);
    }

    if (isNotLastFloor === true) {
      floors.push(EFloor.NotLast);
    }

    return floors.length > 0 ? (floors as NonEmptyArray<EFloor>) : null;
  }, [isFirstFloor, isNotLastFloor]);

  const handleFloorsChange = React.useCallback(
    (value: NonEmptyArray<EFloor> | null) => {
      const isNotFirstFloor = value?.includes(EFloor.NotFirst) || false;
      const isNotLastFloor = value?.includes(EFloor.NotLast) || false;

      const modifiers: TModifier[] = [];
      modifiers.push({ action: 'setIsFirstFloor', arguments: [isNotFirstFloor ? false : null] });
      modifiers.push({ action: 'setNotLastFloor', arguments: [isNotLastFloor || null] });

      const nonEmptyModifiers = modifiers as NonEmptyArray<TModifier>;
      onChange(...nonEmptyModifiers);
    },
    [onChange],
  );

  const handleHasElevatorChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      const elevatorType = value ? EElevatorType.Any : null;
      onChange({ action: 'setElevatorType', arguments: [elevatorType] });
    },
    [onChange],
  );

  return (
    <DailyrentFloor
      floorsOptions={FLOORS}
      floorsValue={floors}
      hasElevator={hasElevator}
      onFloorsChange={handleFloorsChange}
      onHasElevatorChange={handleHasElevatorChange}
    />
  );
};
