import { getGeoValue, getTermsValue, getTermValue, TJsonQuery } from '../../../../../../../JsonQuery';
import { TGeoValue } from '../../../../../../../api-models/common/json_query';
import { PIK_DEVELOPER_ID } from '../../../../../constants/pikPromo';
import { EGeoTagsStatus, IGeoTags, IUndergroundTag, TTag } from '../../../../../types/geoTags';
import { ISpecialPromo } from '../../../../../types/specialPromo';

const geoValueTypeToTagTypeMap: { [key in TGeoValue['type']]: TTag['type'] } = {
  builder: 'developer',
  distance: 'distance',
  district: 'district',
  highway: 'highway',
  house: 'house',
  location: 'location',
  newobject: 'residentialComplex',
  polygon: 'polygon',
  railway: 'railway',
  street: 'street',
  underground: 'underground',
  village: 'village',
  regional_district_id: 'regional_district_id',
};

export function getTags(jsonQuery: TJsonQuery, geoTags: IGeoTags, specialPromo: ISpecialPromo | null): TTag[] {
  let geo = getGeoValue()(jsonQuery) || [];

  // Фильтр КП использует отдельное поле в jsonQuery отличное от geo
  const kpId = getTermValue('kp_id')(jsonQuery);
  if (kpId) {
    geo = [{ type: 'village', id: kpId }, ...geo];
  }
  // Фильтр застройщиков использует отдельное поле в jsonQuery отличное от geo
  const buildersIds = getTermsValue('builders_ids')(jsonQuery);
  if (buildersIds) {
    geo = [{ type: 'builder', id: buildersIds[0] }, ...geo];
  }

  // Вырезаем особенный is_pik_promo, для которого есть свой фильтр
  if (
    geo.some(g => g.type === 'builder' && g.id === PIK_DEVELOPER_ID) &&
    getTermValue('is_pik_promo')(jsonQuery) !== null
  ) {
    geo = geo.filter(g => g.type !== 'builder' || g.id !== PIK_DEVELOPER_ID);
  }

  return (
    geo.map<TTag>(g => {
      if (g.type === 'distance' || g.type === 'polygon') {
        return g;
      }

      const type = geoValueTypeToTagTypeMap[g.type] as Exclude<TTag['type'], 'distance' | 'polygon'>;

      const relatedGeoTag = geoTags.items?.find(i => i.type === type && i.id === g.id) || null;

      if (type === 'underground') {
        return {
          type,
          id: g.id,
          name: getTagName(g, relatedGeoTag, specialPromo),
          color: (relatedGeoTag as IUndergroundTag)?.color || null,
          releaseYear: (relatedGeoTag as IUndergroundTag)?.releaseYear || null,
          underConstruction: (relatedGeoTag as IUndergroundTag)?.underConstruction ?? false,
        };
      }

      return { ...g, type, name: getTagName(g, relatedGeoTag, specialPromo) };
    }) || []
  );

  function getTagName(geoValue: TGeoValue, relatedGeoTag: TTag | null, specialPromo: ISpecialPromo | null): string {
    return relatedGeoTag
      ? relatedGeoTag.name
      : geoTags.status === EGeoTagsStatus.Loading
        ? 'Загрузка…'
        : getDefaultName(geoValue, specialPromo);
  }
}

function getDefaultName(geoValue: TGeoValue, specialPromo: ISpecialPromo | null): string {
  switch (geoValue.type) {
    case 'builder':
      if (specialPromo && geoValue.id === specialPromo.developerId) {
        return specialPromo.name;
      }

      return `Неизвестный застройщик #${geoValue.id}`;
    case 'district':
      return `Неизвестный район #${geoValue.id}`;
    case 'highway':
      return `Неизвестное шоссе #${geoValue.id}`;
    case 'house':
      return `Неизвестный дом #${geoValue.id}`;
    case 'location':
      return `Неизвестная локация #${geoValue.id}`;
    case 'newobject':
      return `Неизвестный ЖК #${geoValue.id}`;
    case 'railway':
      return `Неизвестная Ж/Д станция #${geoValue.id}`;
    case 'street':
      return `Неизвестная улица #${geoValue.id}`;
    case 'underground':
      return `Неизвестное метро #${geoValue.id}`;
    case 'village':
      return `Неизвестный КП #${geoValue.id}`;
    case 'regional_district_id':
      return `Неизвестный район #${geoValue.id}`;
  }

  return 'Неизвестное гео';
}
