import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray, EBalconyType } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IBalcony {
  label: string;
  value: EBalconyType;
}

const BALCONY_TYPES: IBalcony[] = [
  { label: 'С балконом', value: EBalconyType.Balcony },
  { label: 'С лоджией', value: EBalconyType.Loggia },
];

interface IBalconyProps {
  value: NonEmptyArray<EBalconyType> | null;
  onChange(value: NonEmptyArray<EBalconyType> | null): void;
}

export const Balcony: React.FC<IBalconyProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_balcony" label="Балкон/Лоджия">
      <CheckboxButtonGroup options={BALCONY_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
