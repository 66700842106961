import { Button } from '@cian/ui-kit/button';
import { UIText1 } from '@cian/ui-kit/typography';
import { IconActionChevronLeft24 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Header.css';
import { Header as HeaderComponent } from '../../../../../packages/ModalWindow';

interface IHeaderProps {
  onBack(): void;
  onClear(): void;
}

export const Header: React.FC<IHeaderProps> = ({ onBack, onClear }) => {
  const handleBackClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onBack();
    },
    [onBack],
  );

  const handleClearClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onClear();
    },
    [onClear],
  );

  return (
    <HeaderComponent
      left={
        <span className={styles['button--left']}>
          <Button
            theme="fill_white_primary"
            size="XS"
            beforeIcon={<IconActionChevronLeft24 display="block" />}
            onClick={handleBackClick}
          />
        </span>
      }
      title="Фильтры"
      right={
        <button className={classNames(styles['button'], styles['button--right'])} onClick={handleClearClick}>
          <UIText1 color="text-main-default">Очистить</UIText1>
        </button>
      }
    />
  );
};
