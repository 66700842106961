import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { selectPage } from '../../selectors/page';
import { AuthenticationModalContainer } from '../AuthenticationModalContainer';
import { FiltersContainer } from '../FiltersContainer';

export const PageContainer: React.FC = () => {
  const { title } = useSelector(selectPage);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FiltersContainer />
      <AuthenticationModalContainer />
    </>
  );
};
