import * as React from 'react';

import { EElevatorType, JsonQuery } from '../../../../../JsonQuery';
import { Elevators } from '../../../components/filters/Elevators';
import { useContext } from '../../../utils/useContext';

export const ElevatorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const elevatorType = new JsonQuery(jsonQuery).getElevatorType();

  const handleChange = React.useCallback(
    (elevatorType: EElevatorType | null) => {
      onChange({ action: 'setElevatorType', arguments: [elevatorType] });
    },
    [onChange],
  );

  return <Elevators value={elevatorType || null} onChange={handleChange} />;
};
