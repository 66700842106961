import * as React from 'react';

import { Notifications } from '../../components/Notifications';
import { INotification } from '../../types/notification';
import { useNotificationsContext } from '../../utils/notificationsContext';

export const NotificationsContainer: React.FC = () => {
  const { notifications, popNotification } = useNotificationsContext();

  const handleNotificationClose = React.useCallback(
    (notification: INotification) => {
      popNotification(notification);
    },
    [popNotification],
  );

  return <Notifications notifications={notifications} onNotificationClose={handleNotificationClose} />;
};
