import { FDealType, FOfferType, isAvailable } from '../../../../../../JsonQuery';
import { IFilterList } from '../../types';

export const REDESIGN_FILTERS_URBAN: IFilterList[] = [
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [
      'flatType',
      'roomType',
      'price',
      'totalArea',
      'squareMeterArea',
      'geo',
      'undergroundTime',
      'apartments',
      'floor',
      'floors',
      'constructionYear',
      'constructionType',
      'balcony',
      'renovation',
      'ceilingHeight',
      'elevators',
      'urbanBathroom',
      'windows',
      'parking',
      'demolition',
      'flatShare',
      'electronicTrading',
      'dealType',
      'containWords',
      'excludeWords',
      'aboutOffer',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: [
      'roomType',
      'price',
      'totalArea',
      'geo',
      'dealType',
      'undergroundTime',
      'facilities',
      'rentalPeriod',
      'livingConditions',
      'squareMeterArea',
      'repair',
      'floor',
      'layout',
      'balcony',
      'kitchenStove',
      'urbanBathroom',
      'ceilingHeight',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'apartments',
      'elevators',
      // Пандус
      'rentTerms',
      'containWords',
      'excludeWords',
      'aboutOffer',

      // OLD
      // 'bathType',
      // 'windows',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Flat),
    list: [
      'roomType',
      'price',
      'totalArea',
      'geo',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'floor',
      'balcony',
      'kitchenStove',
      'urbanBathroom',
      'ceilingHeight',
      'floors',
      'constructionYear',
      'elevators',
      // Состав съемщиков
      'containWords',
      'excludeWords',
      'aboutOffer',

      // OLD
      // 'bathType',
      // 'windows',
      // // Пандус
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Room | FOfferType.FlatShared),
    list: [
      'roomsInDeal',
      'flatRooms',
      'price',
      'totalArea',
      'dealType',
      'geo',
      'undergroundTime',
      'squareMeterArea',
      'floor',
      'layout',
      'balcony',
      'kitchenStove',
      'urbanBathroom',
      'windows',
      'ceilingHeight',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'apartments',
      'elevators',
      'saleType',
      'electronicTrading',
      'containWords',
      'excludeWords',
      'aboutOffer',

      // OLD
      // 'repair',
      // 'demolition',
      // // Пандус
      // 'parking',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Room | FOfferType.Bed),
    list: [
      'flatRooms',
      'price',
      'totalArea',
      'roomsInDeal',
      'geo',
      'dealType',
      'undergroundTime',
      'facilities',
      'rentalPeriod',
      'livingConditions',
      'squareMeterArea',
      'repair',
      'floor',
      'layout',
      'balcony',
      'kitchenStove',
      'urbanBathroom',
      'ceilingHeight',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'apartments',
      'elevators',
      // Пандус
      // Состав съемщиков
      'rentTerms',
      'containWords',
      'excludeWords',
      'aboutOffer',

      // OLD
      // 'bathType',
      // 'windows',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed),
    list: [
      'price',
      'dealType',
      'geo',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'elevators',
      // Пандус
      // Состав съемщиков
      'rentTerms',
      'containWords',
      'excludeWords',
      'aboutOffer',
    ],
  },
];
