import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { FOfferType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { TFieldPlaceholders } from '../../../types/placeholders';
import { Filter, FilterRow } from '../common/Filter';

interface ISquareMeterAreaProps {
  offerType: FOfferType;
  roomArea: IJsonQueryRangeValue;
  kitchenArea: IJsonQueryRangeValue;
  livingArea: IJsonQueryRangeValue;
  kitchenAreaPlaceholders?: TFieldPlaceholders;
  livingAreaPlaceholders?: TFieldPlaceholders;
  onRoomAreaMinChange(value: number | null): void;
  onRoomAreaMaxChange(value: number | null): void;
  onKitchenAreaMinChange(value: number | null): void;
  onKitchenAreaMaxChange(value: number | null): void;
  onLivingAreaMinChange(value: number | null): void;
  onLivingAreaMaxChange(value: number | null): void;
}

export const SquareMeterArea: React.FC<ISquareMeterAreaProps> = ({
  offerType,
  roomArea: { gte: roomAreaMin = null, lte: roomAreaMax = null },
  kitchenArea: { gte: kitchenAreaMin = null, lte: kitchenAreaMax = null },
  livingArea: { gte: livingAreaMin = null, lte: livingAreaMax = null },
  kitchenAreaPlaceholders,
  livingAreaPlaceholders,
  onRoomAreaMinChange,
  onRoomAreaMaxChange,
  onKitchenAreaMinChange,
  onKitchenAreaMaxChange,
  onLivingAreaMinChange,
  onLivingAreaMaxChange,
}) => {
  const isRoomOfferType = offerType === FOfferType.Room;

  const handleRoomAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onRoomAreaMinChange(gte);
  }, 300);

  const handleRoomAreaMaxChange = useDebouncedCallback((gte: number | null) => {
    onRoomAreaMaxChange(gte);
  }, 300);

  const handleKitchenAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onKitchenAreaMinChange(gte);
  }, 300);

  const handleKitchenAreaMaxChange = useDebouncedCallback((gte: number | null) => {
    onKitchenAreaMaxChange(gte);
  }, 300);

  const handleLivingAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onLivingAreaMinChange(gte);
  }, 300);

  const handleLivingAreaMaxChange = useDebouncedCallback((gte: number | null) => {
    onLivingAreaMaxChange(gte);
  }, 300);

  return (
    <Filter id="filter_squareMeterArea" label="Площадь">
      {isRoomOfferType && (
        <FilterRow id="filter_squareMeterArea_room" label="Комната">
          <RangeInput
            min={1}
            max={9999}
            joined={false}
            postfix={'м\u00B2'}
            valueMin={roomAreaMin}
            valueMax={roomAreaMax}
            onChangeMin={handleRoomAreaMinChange}
            onChangeMax={handleRoomAreaMaxChange}
          />
        </FilterRow>
      )}
      {!isRoomOfferType && (
        <FilterRow id="filter_squareMeterArea_living" label="Жилая">
          <RangeInput
            min={1}
            max={9999}
            joined={false}
            postfix={'м\u00B2'}
            valueMin={livingAreaMin}
            valueMax={livingAreaMax}
            onChangeMin={handleLivingAreaMinChange}
            onChangeMax={handleLivingAreaMaxChange}
            {...livingAreaPlaceholders}
          />
        </FilterRow>
      )}
      <FilterRow id="filter_squareMeterArea_kitchen" label="Кухня">
        <RangeInput
          min={1}
          max={9999}
          joined={false}
          postfix={'м\u00B2'}
          valueMin={kitchenAreaMin}
          valueMax={kitchenAreaMax}
          onChangeMin={handleKitchenAreaMinChange}
          onChangeMax={handleKitchenAreaMaxChange}
          {...kitchenAreaPlaceholders}
        />
      </FilterRow>
    </Filter>
  );
};
