import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ERentTime, getTermValue, TJsonQuery } from '../../../../../packages/JsonQuery';
import { Footer } from '../../../../components/Filters';
import { selectJsonQuery } from '../../../../selectors/jsonQuery';
import { IApplicationState } from '../../../../types/redux';
import { ISearchMetaState } from '../../../../types/searchMeta';
import { useApplicationContext } from '../../../../utils/applicationContext';
import { useMakeSearch } from '../../utils/useMakeSearch';

export const FooterContainer: React.FC = () => {
  const {
    custom: { origin },
  } = useApplicationContext();
  const searchMeta = useSelector<IApplicationState, ISearchMetaState>(state => state.searchMeta);
  const jsonQuery = useSelector<IApplicationState, TJsonQuery>(selectJsonQuery);
  const isMobile = useDeviceType() === 'phone';
  const rentalPeriod = getTermValue('for_day')(jsonQuery);
  const isDailyRent = rentalPeriod === ERentTime.Daily;
  const showOnlyMapButton = isMobile && isDailyRent;
  const makeSearch = useMakeSearch();

  /**
   * @deprecated Для ужасного фильтра "Скоро в продаже". Не использовать.
   */
  const isUpcomingSale = getTermValue('is_upcoming_sale')(jsonQuery);

  const handleSearchClick = React.useCallback(() => {
    makeSearch({ toMap: false });
  }, [makeSearch]);

  const handleSearchOnMapClick = React.useCallback(() => {
    makeSearch({ toMap: true });
  }, [makeSearch]);

  return (
    <Footer
      origin={origin}
      upcomingSale={isUpcomingSale || false}
      status={searchMeta.status}
      offersCount={searchMeta.count}
      onSearchClick={handleSearchClick}
      onSearchOnMapClick={handleSearchOnMapClick}
      showOnlyMapButton={showOnlyMapButton}
    />
  );
};
