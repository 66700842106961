import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { SuburbanFloors } from '../../../components/filters/SuburbanFloors';
import { useContext } from '../../../utils/useContext';

export const SuburbanFloorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const floors = getRangeValue('floorn')(jsonQuery);

  const handleChange = React.useCallback(
    (gte: number | null, lte: number | null) => {
      onChange({ action: 'setFloors', arguments: [gte, lte] });
    },
    [onChange],
  );

  return <SuburbanFloors value={floors} onChange={handleChange} />;
};
