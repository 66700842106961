import { ILogger } from '@cian/logger';
import loadable from '@loadable/component';
import * as React from 'react';

import { abbrLowercaseFirstLetter } from '../../../../../../../Fields/shared/utils/string';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../../../../LoadableHelpers';
import { ModalWindow } from '../../../../../../../ModalWindow';
import { HeaderBackable } from '../../../../ModalWindow';

import type { ICatalogSelectProps } from './CatalogSelect';

export interface ICatalogSelectModalProps extends Omit<ICatalogSelectProps, 'onTabChange'> {
  logger: ILogger;
  open: boolean;
  title: string;
  onClose(): void;
}

const CatalogSelectLoadable = loadable(() => import('./CatalogSelect'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const CatalogSelectModal: React.FC<ICatalogSelectModalProps> = ({
  logger,
  open,
  title,
  onClose,
  ...sharedProps
}) => {
  const modalWindowRef = React.createRef<ModalWindow>();
  const lowercasedTitle = React.useMemo(() => abbrLowercaseFirstLetter(title), [title]);

  const handleRetry = React.useCallback(() => {
    CatalogSelectLoadable.load();
  }, []);

  const handleScrollableChange = React.useCallback(() => {
    if (modalWindowRef.current) {
      modalWindowRef.current.scrollToTop();
    }
  }, [modalWindowRef]);

  return (
    <ModalWindow
      ref={modalWindowRef}
      header={<HeaderBackable title={`Выберите ${lowercasedTitle}`} onBack={onClose} />}
      open={open}
      onClose={onClose}
      portal
    >
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <CatalogSelectLoadable
          {...sharedProps}
          onTabChange={handleScrollableChange}
          onSearchChange={handleScrollableChange}
        />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
