import {
  IconMapMetroMsk16,
  IconMapMetroSpb16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  ISVGIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

const REGION_METRO_ICON_MAP = new Map<number, React.ComponentType<ISVGIconProps>>([
  [1, IconMapMetroMsk16],
  [2, IconMapMetroSpb16],
  [4743, IconMapMetroEkb16],
  [4777, IconMapMetroKzn16],
  [4885, IconMapMetroNN16],
  [4897, IconMapMetroNovosib16],
  [4966, IconMapMetroSam16],
]);

export function getRegionUndergroundIcon(regionId: number[]): React.ComponentType<ISVGIconProps> {
  const availableRegionId = regionId.find(id => REGION_METRO_ICON_MAP.has(id));

  return (availableRegionId && REGION_METRO_ICON_MAP.get(availableRegionId)) || IconMapMetroMsk16;
}
