import { IconActionChevronDownSmall16 } from '@cian/ui-kit-design-tokens/icons';
import loadable from '@loadable/component';
import * as R from 'ramda';
import * as React from 'react';

import { OfferTypeSelectModal } from './OfferTypeSelectModal';
import * as styles from './RootOfferType.css';
import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { getOfferTypeList } from '../../../../../Fields/shared/components/OfferTypeSelect/helpers';
import { getOfferTypeLabel } from '../../../../../Fields/shared/utils/offerType';
import { FDealType, FOfferType } from '../../../../../JsonQuery';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../../LoadableHelpers';
import { useHotelsExperiment } from '../../../utils/useHotelsExperiment';
import { Filter } from '../common/Filter';

interface IRootOfferTypeProps {
  dealType: FDealType;
  value: FOfferType;
  onChange(value: FOfferType): void;
}

const BannerHotelsSearchLoadable = loadable(() => import('../../BannerHotelsSearch'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const RootOfferType: React.FC<IRootOfferTypeProps> = ({ dealType, value, onChange }) => {
  const { config, logger } = useApplicationContext();
  const isSearchFlatShareEnabled = config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled');
  const { isHotelsBannerVisible, hotelsLink, onHotelsClick } = useHotelsExperiment();

  const [isOpen, setIsOpen] = React.useState(false);
  const options = React.useMemo(
    () => getOfferTypeList({ dealType, offerType: value, isSearchFlatShareEnabled }),
    [dealType, value, isSearchFlatShareEnabled],
  );
  const selectedOptions = React.useMemo(
    () =>
      R.flatten(options.map(t => t.options))
        .filter(offerType => (value & offerType.value) !== 0)
        .map(offerType => offerType.value),
    [options, value],
  );
  const label = React.useMemo(() => getOfferTypeLabel(dealType, value), [dealType, value]);

  const handleSelect = React.useCallback(
    (nextOfferTypes: FOfferType[]) => {
      const nextOfferType = nextOfferTypes.reduce(
        (previousValue, currentValue) => previousValue | currentValue,
        FOfferType.Unexpected,
      );

      if (nextOfferType !== value && (nextOfferType !== FOfferType.Flat || (value & FOfferType.Flat) === 0)) {
        onChange(nextOfferType);
      }

      setIsOpen(false);
    },
    [onChange, value],
  );

  const handleRetry = React.useCallback(() => {
    BannerHotelsSearchLoadable.load();
  }, []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Filter compact>
      <button className={styles['button']} onClick={() => setIsOpen(true)}>
        {label} <IconActionChevronDownSmall16 display="inline-block" color="icon-main-default" />
      </button>
      <OfferTypeSelectModal
        open={isOpen}
        options={options}
        selected={selectedOptions}
        onSelect={handleSelect}
        onClose={handleClose}
        banner={
          isHotelsBannerVisible && (
            <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
              <BannerHotelsSearchLoadable href={hotelsLink} onClick={onHotelsClick} />
            </LoadableErrorBoundary>
          )
        }
      />
    </Filter>
  );
};
