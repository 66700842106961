import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { SHRUG } from '../../../constants/shrug';
import { Filter } from '../common/Filter';

interface IFromMKADProps {
  distance: IJsonQueryRangeValue | null;
  onFromMKADMinChange(value: number | null): void;
  onFromMKADMaxChange(value: number | null): void;
}

export const FromMKAD: React.FC<IFromMKADProps> = ({ distance, onFromMKADMinChange, onFromMKADMaxChange }) => {
  const { gte: distanceMin = null, lte: distanceMax = null } = distance || {};

  const handleFromMKADMinChange = useDebouncedCallback((gte: number | null) => {
    onFromMKADMinChange(gte);
  }, 300);

  const handleFromMKADMaxChange = useDebouncedCallback((lte: number | null) => {
    onFromMKADMaxChange(lte);
  }, 300);

  return (
    <Filter
      id="filter_fromMKAD"
      label={`Расстояние от МКАД${distanceMin === 0 && distanceMax === 0 ? ` ${SHRUG}` : ''}`}
    >
      <RangeInput
        min={0}
        max={999}
        joined={false}
        postfix="км"
        valueMin={distanceMin}
        valueMax={distanceMax}
        onChangeMin={handleFromMKADMinChange}
        onChangeMax={handleFromMKADMaxChange}
      />
    </Filter>
  );
};
