import { useToggleState } from '@cian/react-utils';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';

import { useMortgagePromoSearchTypeOptions, useMortgagePromoSearchTypeValues } from './hooks';
import { getSubsidizedMortgageModifierValue, removeSubsidizedMortgageFromValues } from './utils';
import { getPromoSearchFilterValuesByType, getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../../../components/filters/common/Filter';
import { FilterChips } from '../../../components/filters/common/FilterChips';
import { FilterChipsCollapsed } from '../../../components/filters/common/FilterChipsCollapsed';
import { calculateVisibleOptionsLength } from '../../../utils/promoSearchTypes';
import { useContext } from '../../../utils/useContext';

export const MortgagePromoSearchTypeContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const { config, jsonQuery, promoSearchTypes, onChange } = useContext();
  const { state: isExpanded, toggle: toggleExpansion } = useToggleState(false);
  const isMobile = deviceType === 'phone';
  const allPromoSearchTypeValues = getTermsValue('promo_search_types')(jsonQuery);
  const mortgageValues = useMortgagePromoSearchTypeValues({
    allPromoSearchTypeValues,
    promoSearchTypes,
    jsonQuery,
  });
  const mortgageOptions = useMortgagePromoSearchTypeOptions({
    promoSearchTypes,
    jsonQuery,
    config,
  });

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange(
        {
          action: 'setIsSubsidisedMortgage',
          arguments: [getSubsidizedMortgageModifierValue(newValues)],
        },
        {
          action: 'setPromoSearchTypes',
          arguments: [
            {
              allValues: allPromoSearchTypeValues,
              newValues: removeSubsidizedMortgageFromValues(newValues),
              availableFilterValues: getPromoSearchFilterValuesByType('mortgage', promoSearchTypes),
            },
          ],
        },
      );
    },
    [allPromoSearchTypeValues, promoSearchTypes, onChange],
  );

  if (!promoSearchTypes) {
    return null;
  }

  const visibleOptionsLength = calculateVisibleOptionsLength(isMobile, isExpanded, mortgageOptions.length);

  return (
    <Filter id="filter_mortgagePromoSearchType" dataName="filter_mortgagePromoSearchType">
      <FilterChipsCollapsed
        label="Ипотечные программы"
        isExpanded={isExpanded}
        isMobile={isMobile}
        onToggleExpansion={toggleExpansion}
        chosenFiltersCount={mortgageValues && mortgageValues.length}
      >
        <div data-name="MortgagePromoSearchTypeChips">
          <FilterChips
            selectType="multiple"
            options={mortgageOptions.slice(0, visibleOptionsLength)}
            value={mortgageValues}
            onChange={handleChange}
          />
        </div>
      </FilterChipsCollapsed>
    </Filter>
  );
};
