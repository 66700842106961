import { DEFAULT_DISTRICTS } from './constants';
import { fetchRegionalDistrictsCached } from './fetchRegionalDistricts';
import { isSuburban, offerTypeFromJsonQuery, TJsonQuery } from '../../../packages/JsonQuery';
import { IApplicationContext } from '../../types/applicationContext';
import { IRegionalDistrictsList } from '../../types/regionalDistricts';

interface IFetchRegionalDistrictsParams {
  jsonQuery: TJsonQuery;
  locationId?: number;
}

export const fetchRegionalDistrictsIfNeeded = async (
  context: IApplicationContext,
  parameters: IFetchRegionalDistrictsParams,
): Promise<IRegionalDistrictsList> => {
  const { jsonQuery, locationId } = parameters;

  const isSuburbanFilters = isSuburban(offerTypeFromJsonQuery(jsonQuery));

  if (!isSuburbanFilters || !locationId) {
    return DEFAULT_DISTRICTS;
  }

  return fetchRegionalDistrictsCached(context, { locationId });
};
