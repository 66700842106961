import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { CellGroup, CellSwitch } from '../../../../../Fields/shared/components/CellGroup';
import { Filter } from '../common/Filter';

interface IResidentialComplexesUpcomingSaleProps {
  upcomingSale: boolean | null;
  onUpcomingSaleChange(value: boolean | null): void;
}

export const ResidentialComplexesUpcomingSale: React.FC<IResidentialComplexesUpcomingSaleProps> = ({
  upcomingSale,
  onUpcomingSaleChange,
}) => {
  const handleUpcomingSaleChange = React.useCallback(() => {
    onUpcomingSaleChange(!upcomingSale || null);
  }, [onUpcomingSaleChange, upcomingSale]);

  return (
    <Filter id="filter_residentialComplexesUpcomingSale" contentPadding={false}>
      <CellGroup>
        <CellSwitch checked={upcomingSale || false} onClick={handleUpcomingSaleChange}>
          <>
            Скоро в продаже
            <UIText2 color="gray60_100">Продажи начнутся в ближайшие месяцы</UIText2>
          </>
        </CellSwitch>
      </CellGroup>
    </Filter>
  );
};
