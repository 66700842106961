import { EGeoType } from '../../repositories/geo-temp-layer/entities/address_tag/AddressNodeSchema';
import { IGetGeoTagsResponse } from '../../repositories/geo-temp-layer/v1/get-geo-tags';
import { IPolygonTag, ISimpleTag, IUndergroundTag, TTag } from '../../types/tags';

export function prepareGeoTags(response: IGetGeoTagsResponse): TTag[] | null {
  const tags: TTag[] = [];

  if (response.geoTags.newbuildingBuildersTags && response.geoTags.newbuildingBuildersTags.length > 0) {
    for (const geoTag of response.geoTags.newbuildingBuildersTags) {
      const tag: ISimpleTag = {
        type: 'developer',
        id: geoTag.id,
        name: geoTag.text,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.addressTags && response.geoTags.addressTags.length > 0) {
    for (const geoTag of response.geoTags.addressTags) {
      const addressNode = geoTag.addressNodes[geoTag.addressNodes.length - 1];
      if (isAvailableAddressType(addressNode.geoType)) {
        const tag: ISimpleTag = {
          type: addressNode.geoType,
          id: addressNode.id,
          name: geoTag.text,
        };

        tags.push(tag);
      }
    }
  }

  if (response.geoTags.metroTags && response.geoTags.metroTags.length > 0) {
    for (const geoTag of response.geoTags.metroTags) {
      const tag: IUndergroundTag = {
        type: 'underground',
        id: geoTag.id,
        name: geoTag.text,
        color: geoTag.color ? `#${geoTag.color}` : null,
        releaseYear: geoTag.releaseYear || null,
        underConstruction: geoTag.underConstruction,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.polygonTags && response.geoTags.polygonTags.length > 0) {
    for (const geoTag of response.geoTags.polygonTags) {
      const tag: IPolygonTag = {
        type: 'polygon',
        coordinates: geoTag.geoObject.source.geometry.coordinates[0].map(
          (coords: YMaps.TCoord) => coords.map(coord => parseFloat(coord.toFixed(7)).toString()) as [string, string],
        ),
        name: geoTag.text,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.railwayTags && response.geoTags.railwayTags.length > 0) {
    for (const geoTag of response.geoTags.railwayTags) {
      const tag: ISimpleTag = {
        type: 'railway',
        id: geoTag.id,
        name: geoTag.text,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.districtTags && response.geoTags.districtTags.length > 0) {
    for (const geoTag of response.geoTags.districtTags) {
      const tag: ISimpleTag = {
        type: 'district',
        id: geoTag.id,
        name: `район ${geoTag.text}`,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.newobjectTags && response.geoTags.newobjectTags.length > 0) {
    for (const geoTag of response.geoTags.newobjectTags) {
      const tag: ISimpleTag = {
        type: 'residentialComplex',
        id: geoTag.id,
        name: `ЖК \u00AB${geoTag.text}\u00BB`,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.villageTags && response.geoTags.villageTags.length > 0) {
    for (const geoTag of response.geoTags.villageTags) {
      const tag: ISimpleTag = {
        type: 'village',
        id: geoTag.id,
        name: `КП \u00AB${geoTag.text}\u00BB`,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.highwayTags && response.geoTags.highwayTags.length > 0) {
    for (const geoTag of response.geoTags.highwayTags) {
      const tag: ISimpleTag = {
        type: 'highway',
        id: geoTag.id,
        name: geoTag.name,
      };

      tags.push(tag);
    }
  }

  if (response.geoTags.regionalDistrictTags && response.geoTags.regionalDistrictTags.length > 0) {
    for (const geoTag of response.geoTags.regionalDistrictTags) {
      const tag: ISimpleTag = {
        type: 'regional_district_id',
        id: geoTag.id,
        name: geoTag.name,
      };

      tags.push(tag);
    }
  }

  return tags.length > 0 ? tags : null;
}

function isAvailableAddressType(geoType: EGeoType): geoType is EGeoType.Location | EGeoType.Street | EGeoType.House {
  return [EGeoType.Location, EGeoType.Street, EGeoType.House].includes(geoType);
}
