import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { Filter, FilterRow } from '../common/Filter';

export interface ISubsidisedMortgageProps {
  isSubsidisedMortgage: boolean | null;
  label: string;
  caption: string;
  onChange(value: boolean): void;
}

export const SubsidisedMortgage: React.FC<ISubsidisedMortgageProps> = ({
  caption,
  label,
  isSubsidisedMortgage,
  onChange,
}) => {
  return (
    <Filter id="filter_subsidisedMortgage">
      <FilterRow>
        <Switch
          label={label}
          caption={caption}
          topAligned
          checked={isSubsidisedMortgage || false}
          onChange={/* istanbul ignore next */ (event, value) => onChange(Boolean(value))}
        />
      </FilterRow>
    </Filter>
  );
};
