import { TModifier } from '../../../packages/Fields';

export const GEO_CHANGE_ACTIONS: TModifier['action'][] = [
  'selectGeo',
  'setBSCenter',
  'setDistricts',
  'setHighways',
  'setRegion',
  'setUndergrounds',
  'setVillage',
  'setRegionalDistricts',
  'setOfferType',
  'setBuilders',
];
