import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../../../api-models/common/json_query';
import { SuburbanConstructionType } from '../../../components/filters/SuburbanConstructionType';
import { useContext } from '../../../utils/useContext';

export const SuburbanConstructionTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const constructionType = getTermsValue('house_material')(jsonQuery);

  const handleChange = React.useCallback(
    (constructionType: NonEmptyArray<EHouseMaterial> | null) => {
      onChange({ action: 'setConstructionTypes', arguments: [constructionType] });
    },
    [onChange],
  );

  return <SuburbanConstructionType value={constructionType} onChange={handleChange} />;
};
