import { ErrorLogComponent } from '@cian/error-log-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { UiKitRoot } from '@cian/ui-kit';
import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { NotificationsProvider } from '../../../packages/Notifications';
import { IApplicationContext } from '../../types/applicationContext';
import { IReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { PageContainer } from '../PageContainer';
import { SaveSearchModalProvider } from '../SaveSearchModalProvider';
import { SubscriptionsContainer } from '../Subscriptions';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { initialDeviceType },
    microfrontendsRegistry,
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitRoot deviceType={initialDeviceType}>
          <StoreProvider store={reduxStore}>
            <MicrofrontendProvider registry={microfrontendsRegistry}>
              <NotificationsProvider>
                <SaveSearchModalProvider>
                  <SubscriptionsContainer reduxStore={reduxStore} />
                  <PageContainer />
                </SaveSearchModalProvider>
              </NotificationsProvider>
            </MicrofrontendProvider>
          </StoreProvider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
