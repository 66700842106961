import * as React from 'react';

import { getIsSpecialPromo } from './helpers';
import { NonEmptyArray } from '../../../../../Fields';
import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { getTermsValue, getTermValue } from '../../../../../JsonQuery';
import { isPikPromoAvailable } from '../../../../../JsonQuery/utils/isPikPromoAvailable';
import { ResidentialComplexesSpecial } from '../../../components/filters/ResidentialComplexesSpecial';
import { useContext } from '../../../utils/useContext';

export const ResidentialComplexesSpecialContainer: React.FC = () => {
  const { jsonQuery, specialPromo, onChange, config } = useContext();
  const isFromDeveloper = getTermValue('from_developer')(jsonQuery);
  const hasFinishing = getTermValue('has_decoration')(jsonQuery);
  const isSpecialPromo = React.useMemo(() => getIsSpecialPromo(jsonQuery, specialPromo), [jsonQuery, specialPromo]);
  const regionIds = getTermsValue('region')(jsonQuery);
  const salesLeader = getTermValue('is_sales_leader')(jsonQuery);
  const salesStart = getTermValue('is_sales_start')(jsonQuery);
  const discountMortgage = getTermValue('is_discount_mortgage')(jsonQuery);
  const isDiscountMortgageEnabled = config.get<boolean>('newbuildingDiscountMortgage.Enabled') || false;

  const handleFromDeveloperChange = React.useCallback(
    (isFromDeveloper: boolean | null) => {
      onChange({ action: 'setFromDeveloper', arguments: [isFromDeveloper] });
    },
    [onChange],
  );

  const handleFinishingChange = React.useCallback(
    (hasFinishing: boolean | null) => {
      const modifiers: NonEmptyArray<TModifier> = [
        { action: 'setHasFinishing', arguments: [hasFinishing] },
        { action: 'setDecorationsList', arguments: [null] },
      ];
      onChange(...modifiers);
    },
    [onChange],
  );

  const handleSpecialPromoChange = React.useCallback(
    (isSpecialPromo: boolean | null) => {
      if (!specialPromo) {
        return;
      }

      if (isSpecialPromo) {
        const modifiers: NonEmptyArray<TModifier> = [
          { action: 'selectGeo', arguments: [{ type: 'builder', id: specialPromo.developerId }] },
        ];

        if (isPikPromoAvailable(specialPromo.developerId, regionIds)) {
          modifiers.push({ action: 'setPikPromo', arguments: [true] });
        } else {
          modifiers.push({ action: 'setSpecialPromo', arguments: [true] });
        }

        onChange(...modifiers);
      } else {
        const modifiers: NonEmptyArray<TModifier> = [
          { action: 'removeGeo', arguments: [{ type: 'builder', id: specialPromo.developerId }] },
        ];

        if (isPikPromoAvailable(specialPromo.developerId, regionIds)) {
          modifiers.push({ action: 'setPikPromo', arguments: [null] });
        } else {
          modifiers.push({ action: 'setSpecialPromo', arguments: [null] });
        }

        onChange(...modifiers);
      }
    },
    [onChange, specialPromo],
  );

  const handleSalesLeaderChange = React.useCallback(
    (salesLeader: boolean | null) => {
      onChange({ action: 'setSalesLeader', arguments: [salesLeader] });
    },
    [onChange],
  );

  const handleSalesStartChange = React.useCallback(
    (salesStart: boolean | null) => {
      onChange({ action: 'setSalesStart', arguments: [salesStart] });
    },
    [onChange],
  );

  const handleDiscountMortgageChange = React.useCallback(
    (discountMortgage: boolean | null) => {
      onChange({ action: 'setDiscountMortgage', arguments: [discountMortgage] });
    },
    [onChange],
  );

  return (
    <ResidentialComplexesSpecial
      fromDeveloper={isFromDeveloper}
      finishing={hasFinishing}
      specialPromoName={specialPromo && specialPromo.nameGenitive}
      specialPromo={isSpecialPromo}
      salesLeader={salesLeader}
      salesStart={salesStart}
      discountMortgage={discountMortgage}
      onFromDeveloperChange={handleFromDeveloperChange}
      onFinishingChange={handleFinishingChange}
      onSpecialPromoChange={handleSpecialPromoChange}
      onSalesLeaderChange={handleSalesLeaderChange}
      onSalesStartChange={handleSalesStartChange}
      onDiscountMortgageChange={handleDiscountMortgageChange}
      isDiscountMortgageEnabled={isDiscountMortgageEnabled}
    />
  );
};
