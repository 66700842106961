import { useToggleState } from '@cian/react-utils';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';

import { useDiscountPromoSearchTypeOptions, useDiscountPromoSearchTypeValues } from './hooks';
import { NonEmptyArray, getPromoSearchFilterValuesByType, getTermsValue } from '../../../../../JsonQuery';
import { Filter } from '../../../components/filters/common/Filter';
import { FilterChips } from '../../../components/filters/common/FilterChips';
import { FilterChipsCollapsed } from '../../../components/filters/common/FilterChipsCollapsed';
import { NBSP } from '../../../constants/symbols';
import { calculateVisibleOptionsLength } from '../../../utils/promoSearchTypes';
import { useContext } from '../../../utils/useContext';

export const DiscountPromoSearchTypeContainer = () => {
  const deviceType = useDeviceType();
  const { jsonQuery, promoSearchTypes, onChange } = useContext();
  const { state: isExpanded, toggle: toggleExpansion } = useToggleState(false);
  const allValues = getTermsValue('promo_search_types')(jsonQuery);
  const discountValues = useDiscountPromoSearchTypeValues(allValues, promoSearchTypes);
  const discountOptions = useDiscountPromoSearchTypeOptions(promoSearchTypes);
  const isMobile = deviceType === 'phone';

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange({
        action: 'setPromoSearchTypes',
        arguments: [
          {
            allValues,
            newValues,
            availableFilterValues: getPromoSearchFilterValuesByType('discount', promoSearchTypes),
          },
        ],
      });
    },
    [onChange, allValues, promoSearchTypes],
  );

  if (!promoSearchTypes) {
    return null;
  }

  const visibleOptionsLength = calculateVisibleOptionsLength(isMobile, isExpanded, discountOptions.length);

  return (
    <Filter id="filter_discountPromoSearchType" dataName="filter_discountPromoSearchType">
      <FilterChipsCollapsed
        isExpanded={isExpanded}
        isMobile={isMobile}
        chosenFiltersCount={discountValues && discountValues.length}
        onToggleExpansion={toggleExpansion}
        label={`Скидки и акции от${NBSP}застройщиков`}
      >
        <div data-name="DiscountPromoSearchTypeChips">
          <FilterChips
            selectType="multiple"
            options={discountOptions.slice(0, visibleOptionsLength)}
            onChange={handleChange}
            value={discountValues}
          />
        </div>
      </FilterChipsCollapsed>
    </Filter>
  );
};
