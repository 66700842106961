import { IRoomType } from './RoomTypeContainer';
import { ERoomType } from '../../../../../api-models/common/json_query';

const COUNTABLE_ROOM_TYPES: IRoomType[] = [
  { label: '1', value: ERoomType.FlatOneRoom },
  { label: '2', value: ERoomType.FlatTwoRooms },
  { label: '3', value: ERoomType.FlatThreeRooms },
  { label: '4', value: ERoomType.FlatFourRooms },
  { label: '5', value: ERoomType.FlatFiveRooms },
  { label: '6+', value: ERoomType.FlatManyRooms },
];

export const ROOM_TYPES: IRoomType[] = [
  ...COUNTABLE_ROOM_TYPES,
  { label: 'Студия', value: ERoomType.Studio },
  { label: 'Свободная планировка', value: ERoomType.FlatFreeLayout },
];

// список доступного количества комнат для посуточных квартир
export const DAILYRENT_FLAT_ROOM_TYPES: IRoomType[] = [
  ...COUNTABLE_ROOM_TYPES,
  { label: 'Студия', value: ERoomType.Studio },
];
// список доступного количества комнат для посуточных комнат
export const DAILYRENT_ROOM_ROOM_TYPES: IRoomType[] = [...COUNTABLE_ROOM_TYPES];
