import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { RootOfferType } from '../../../components/filters/RootOfferType';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const RootOfferTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const dealType = useDealType();
  const offerType = useOfferType();

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action: 'setOfferType', arguments: [offerType] });
    },
    [onChange],
  );

  return <RootOfferType dealType={dealType} value={offerType} onChange={handleChange} />;
};
