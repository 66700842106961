import * as React from 'react';

import { ESuburbanWCType } from '../../../../../api-models/common/json_query';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IBathroom {
  label: string;
  value: ESuburbanWCType;
}

const BATHROOM_TYPES: IBathroom[] = [
  { label: 'На улице', value: ESuburbanWCType.Outside },
  { label: 'В доме', value: ESuburbanWCType.Inside },
];

interface IBathroomProps {
  value: ESuburbanWCType | null;
  onChange(value: ESuburbanWCType | null): void;
}

export const SuburbanBathroom: React.FC<IBathroomProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_suburbanBathroom" label="Санузел">
      <RadioButtonGroupUnselectable options={BATHROOM_TYPES} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
