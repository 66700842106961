import { Checkbox } from '@cian/ui-kit/checkbox';
import { ArticleParagraph1 } from '@cian/ui-kit/typography';
import * as React from 'react';

import { getSaleTypesList } from './helpers';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray, FOfferType } from '../../../../../JsonQuery';
import { ESaleType } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface ISaleTypeProps {
  offerType: FOfferType;
  saleType: NonEmptyArray<ESaleType> | null;
  mortgageAvailable: boolean | null;
  onSaleTypeChange(value: NonEmptyArray<ESaleType> | null): void;
  onMortgageAvailableChange(value: boolean | null): void;
}

export const SaleType: React.FC<ISaleTypeProps> = ({
  offerType,
  saleType,
  mortgageAvailable,
  onSaleTypeChange,
  onMortgageAvailableChange,
}) => {
  const saleTypes = getSaleTypesList(offerType);

  return (
    <Filter
      id="filter_saleType"
      label="Тип продажи"
      infoTitle="Типы продажи"
      infoContent={
        <>
          <ArticleParagraph1>Свободная продажа — это только продажа, без приобретения нового жилья.</ArticleParagraph1>
          <br />
          <ArticleParagraph1>
            Альтернативная сделка — это одновременная продажа имеющейся недвижимости и покупка нового жилья. Такая
            сделка всегда сложнее.
          </ArticleParagraph1>
        </>
      }
    >
      <CheckboxButtonGroup options={saleTypes} value={saleType} onChange={onSaleTypeChange} />
      {/* Временно скрываем */}
      {false && (
        <Checkbox
          label="Возможна ипотека"
          checked={!!mortgageAvailable}
          onChange={event => onMortgageAvailableChange(event.target.checked ? true : null)}
        />
      )}
    </Filter>
  );
};
