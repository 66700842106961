import { IConstructionType } from './types';
import { FOfferType } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../../../api-models/common/json_query';

const FULL_LIST: IConstructionType[] = [
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
  { label: 'Кирпично-монолитный', value: EHouseMaterial.BrickMonolith },
  { label: 'Панельный', value: EHouseMaterial.Panel },
  { label: 'Блочный', value: EHouseMaterial.Block },
  { label: 'Деревянный', value: EHouseMaterial.Wood },
];

const FLAT_NEW_LIST: IConstructionType[] = [
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
  { label: 'Панельный', value: EHouseMaterial.Panel },
];

export function getConstructionTypesList(offerType: FOfferType): IConstructionType[] {
  if (offerType === FOfferType.FlatNew) {
    return FLAT_NEW_LIST;
  }

  return FULL_LIST;
}
