import { IRegionMeta } from '../../../../../../Fields/shared/types/regionMeta';
import { MOSCOW_ID, MOSCOW_REGION_ID, SPB_ID } from '../../../../constants/regions';

const METRO_MAP_REGIONS: Record<number, number> = {
  [-1]: MOSCOW_ID,
  [MOSCOW_REGION_ID]: MOSCOW_ID,
  [-2]: SPB_ID,
};

export const getRegionIdForMetroMap = (regionMeta: IRegionMeta) => {
  const regionId = regionMeta.parentId || regionMeta.id;

  return METRO_MAP_REGIONS[regionId] || regionId;
};
