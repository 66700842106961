import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { CellGroup, CellSwitch } from '../../../../../Fields/shared/components/CellGroup';
import { Filter } from '../common/Filter';

interface IResidentialComplexesSpecialProps {
  fromDeveloper: boolean | null;
  finishing: boolean | null;
  specialPromoName: string | null;
  specialPromo: boolean | null;
  salesLeader: boolean | null;
  salesStart: boolean | null;
  discountMortgage: boolean | null;
  isDiscountMortgageEnabled: boolean;
  onFromDeveloperChange(value: boolean | null): void;
  onFinishingChange(value: boolean | null): void;
  onSpecialPromoChange(value: boolean | null): void;
  onSalesLeaderChange(value: boolean | null): void;
  onSalesStartChange(value: boolean | null): void;
  onDiscountMortgageChange(value: boolean | null): void;
}

export const ResidentialComplexesSpecial: React.FC<IResidentialComplexesSpecialProps> = ({
  fromDeveloper,
  finishing,
  specialPromoName,
  specialPromo,
  salesLeader,
  discountMortgage,
  salesStart,
  onFromDeveloperChange,
  onFinishingChange,
  onSpecialPromoChange,
  onSalesLeaderChange,
  onSalesStartChange,
  onDiscountMortgageChange,
  isDiscountMortgageEnabled,
}) => {
  /* istanbul ignore next */
  const handleFromDeveloperChange = React.useCallback(() => {
    onFromDeveloperChange(!fromDeveloper || null);
  }, [fromDeveloper, onFromDeveloperChange]);

  /* istanbul ignore next */
  const handleFinishingChange = React.useCallback(() => {
    onFinishingChange(!finishing || null);
  }, [finishing, onFinishingChange]);

  /* istanbul ignore next */
  const handleSpecialPromoChange = React.useCallback(() => {
    onSpecialPromoChange(!specialPromo || null);
  }, [onSpecialPromoChange, specialPromo]);

  /* istanbul ignore next */
  const handleSalesLeaderChange = React.useCallback(() => {
    onSalesLeaderChange(!salesLeader || null);
  }, [onSalesLeaderChange, salesLeader]);

  /* istanbul ignore next */
  const handleSalesStartChange = React.useCallback(() => {
    onSalesStartChange(!salesStart || null);
  }, [onSalesStartChange, salesStart]);

  const handleDiscountMortgageChange = React.useCallback(() => {
    onDiscountMortgageChange(!discountMortgage || null);
  }, [onDiscountMortgageChange, discountMortgage]);

  return (
    <Filter id="filter_residentialComplexesSpecial" contentPadding={false}>
      <CellGroup>
        <CellSwitch checked={fromDeveloper || false} onClick={handleFromDeveloperChange}>
          Только от застройщика
        </CellSwitch>
        <CellSwitch checked={finishing || false} onClick={handleFinishingChange}>
          Только с отделкой
        </CellSwitch>
        {specialPromoName && (
          <CellSwitch checked={specialPromo || false} onClick={handleSpecialPromoChange}>
            <>Только новостройки от {specialPromoName}</>
          </CellSwitch>
        )}
        {isDiscountMortgageEnabled && (
          <CellSwitch checked={discountMortgage || false} onClick={handleDiscountMortgageChange}>
            Только с льготной ипотекой
          </CellSwitch>
        )}
        <CellSwitch checked={salesLeader || false} onClick={handleSalesLeaderChange}>
          <>
            Только лидеры продаж
            <UIText2 color="gray60_100">Большой спрос на квартиры</UIText2>
          </>
        </CellSwitch>
        <CellSwitch checked={salesStart || false} onClick={handleSalesStartChange}>
          <>
            Только старт продаж
            <UIText2 color="gray60_100">Квартиры по стартовой цене</UIText2>
          </>
        </CellSwitch>
      </CellGroup>
    </Filter>
  );
};
//ios_newbuilding_discount_mortgage
