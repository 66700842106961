import { Select } from '@cian/ui-kit/select';
import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { EPublishPeriod } from '../../../../../api-models/common/json_query';
import { Filter, FilterRow } from '../common/Filter';

interface IPublicationDate {
  label: string;
  value: EPublishPeriod;
}

const PUBLICATION_DATE_OPTIONS: IPublicationDate[] = [
  { label: 'Время публикации не важно', value: EPublishPeriod.Any },
  { label: 'За месяц', value: EPublishPeriod.Month },
  { label: 'За неделю', value: EPublishPeriod.Week },
  { label: 'За сегодня', value: EPublishPeriod.Today },
  { label: 'За час', value: EPublishPeriod.Hour },
];

interface IAboutOfferProps {
  publishPeriod: EPublishPeriod | null;
  hasVideo: boolean | null;
  hasPhoto: boolean | null;
  withLayout: boolean | null;
  onPublishPeriodChange(period: EPublishPeriod | null): void;
  onHasVideoChange(hasVideo: boolean | null): void;
  onHasPhotoChange(hasPhoto: boolean | null): void;
  onWithLayoutChange(withLayout: boolean | null): void;
  showMixedFilters: boolean;
}

export const AboutOffer: React.FC<IAboutOfferProps> = ({
  publishPeriod,
  hasVideo,
  hasPhoto,
  withLayout,
  showMixedFilters,
  onPublishPeriodChange,
  onHasVideoChange,
  onHasPhotoChange,
  onWithLayoutChange,
}) => {
  return (
    <Filter label="Об объявлении" dataName="filter_aboutOffer">
      <FilterRow>
        <Select
          options={PUBLICATION_DATE_OPTIONS}
          value={publishPeriod !== null ? publishPeriod : EPublishPeriod.Any}
          onChange={(value: EPublishPeriod) => onPublishPeriodChange(value === EPublishPeriod.Any ? null : value)}
        />
      </FilterRow>
      <FilterRow>
        <Switch label="Есть видео" checked={!!hasVideo} onChange={(_, value) => onHasVideoChange(value || null)} />
      </FilterRow>
      {showMixedFilters && (
        <>
          <FilterRow>
            <Switch label="Есть фото" checked={!!hasPhoto} onChange={(_, value) => onHasPhotoChange(value || null)} />
          </FilterRow>
          <FilterRow>
            <Switch
              label="Есть планировка"
              checked={!!withLayout}
              onChange={(_, value) => onWithLayoutChange(value || null)}
            />
          </FilterRow>
        </>
      )}
    </Filter>
  );
};
