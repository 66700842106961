import { generateOneLinkUrl } from '@cian/appsflyer-onelink';
import { UIHeading4 } from '@cian/ui-kit/typography';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './BannerApplicationDownload.css';
import { NBSP } from '../../constants/symbols';
import { EMobileDeviceType } from '../../types/mobileDeviceType';

interface IApplicationDownloadProps {
  mobileDeviceType: EMobileDeviceType;
  linkAppGallery?: string | null;
  linkAppStore?: string | null;
  linkGooglePlay?: string | null;
  onClick?(): void;
}

const BannerApplicationDownloadComponent: React.FC<IApplicationDownloadProps> = ({
  mobileDeviceType,
  linkAppGallery: linkAppGalleryRaw,
  linkAppStore: linkAppStoreRaw,
  linkGooglePlay: linkGooglePlayRaw,
  onClick,
}) => {
  const [linkAppGallery, setLinkAppGallery] = React.useState(linkAppGalleryRaw);
  const [linkAppStore, setLinkAppStore] = React.useState(linkAppStoreRaw);
  const [linkGooglePlay, setLinkGooglePlay] = React.useState(linkGooglePlayRaw);

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  React.useEffect(() => {
    setLinkAppGallery(generateOneLinkUrl(linkAppGalleryRaw, 'mobile_filters'));
    setLinkAppStore(generateOneLinkUrl(linkAppStoreRaw, 'mobile_filters'));
    setLinkGooglePlay(generateOneLinkUrl(linkGooglePlayRaw, 'mobile_filters'));
  }, [linkAppGalleryRaw, linkAppStoreRaw, linkGooglePlayRaw]);

  if (!linkAppGallery && !linkAppStore && !linkGooglePlay) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <UIHeading4>
          Больше фильтров{NBSP}— в{NBSP}приложении
        </UIHeading4>
      </div>
      <div className={styles['links']}>
        {mobileDeviceType !== EMobileDeviceType.iOS && (
          <>
            {!!linkGooglePlay && (
              <a
                className={classNames(styles['link'], styles['link--google-play'])}
                href={linkGooglePlay}
                rel="nofollow noreferrer"
                onClick={handleClick}
              >
                Загрузите в Google Play
              </a>
            )}
            {!!linkAppGallery && (
              <a
                className={classNames(styles['link'], styles['link--app-gallery'])}
                href={linkAppGallery}
                rel="nofollow noreferrer"
                onClick={handleClick}
              >
                Загрузите в AppGallery
              </a>
            )}
          </>
        )}
        {mobileDeviceType !== EMobileDeviceType.Android && !!linkAppStore && (
          <a
            className={classNames(styles['link'], styles['link--app-store'])}
            href={linkAppStore}
            rel="nofollow noreferrer"
            onClick={handleClick}
          >
            Загрузите в AppStore
          </a>
        )}
      </div>
    </div>
  );
};

export const BannerApplicationDownload = React.memo(BannerApplicationDownloadComponent);
