import * as React from 'react';

import { NumberInputWithButtons } from '../../../../../NumberInputWithButtons';
import { Filter } from '../common/Filter';

export interface IBedsProps {
  value: number;
  onChange(value: number): void;
  min: number;
  max: number;
}

export const Beds: React.FC<IBedsProps> = ({ value, onChange, min, max }) => {
  return (
    <Filter id="filter_beds" label="Количество гостей">
      <NumberInputWithButtons value={value} onChange={onChange} min={min} max={max} />
    </Filter>
  );
};
