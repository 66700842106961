import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { ESuburbanOfferFilter } from '../../../../../api-models/common/json_query';
import { Possessor } from '../../../components/filters/Possessor';
import { HOMEOWNER } from '../../../constants/possessor';
import { useContext } from '../../../utils/useContext';

export const PossessorContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const possessorType = getTermValue('suburban_offer_filter')(jsonQuery) || ESuburbanOfferFilter.Any;
  const isByHomeowner = getTermValue('is_by_homeowner')(jsonQuery);

  const possessor = React.useMemo(() => (isByHomeowner ? HOMEOWNER : possessorType), [isByHomeowner, possessorType]);

  const handleChange = React.useCallback(
    (possessor: ESuburbanOfferFilter | typeof HOMEOWNER) => {
      if (possessor === HOMEOWNER) {
        onChange({ action: 'setIsByHomeowner', arguments: [true] }, { action: 'setPossessor', arguments: [null] });
      } else {
        const value = possessor !== ESuburbanOfferFilter.Any ? possessor : null;

        onChange({ action: 'setPossessor', arguments: [value] }, { action: 'setIsByHomeowner', arguments: [null] });
      }
    },
    [onChange],
  );

  return <Possessor value={possessor} onChange={handleChange} />;
};
