import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EFacilityType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface ILivingConditionsOption {
  label: string;
  value: EFacilityType;
}

const LIVING_CONDITIONS_OPTIONS: ILivingConditionsOption[] = [
  { label: 'Можно с детьми', value: EFacilityType.KidsAllowed },
  { label: 'Можно с животными', value: EFacilityType.PetsAllowed },
];

interface ILivingConditionsProps {
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const LivingConditions: React.FC<ILivingConditionsProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_livingConditions" label="Условия проживания">
      <CheckboxButtonGroup options={LIVING_CONDITIONS_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
