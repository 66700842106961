import { useSelector } from 'react-redux';

import { selectIsDailyRentOtaConversionExperimentActive } from '../../selectors/experiments/selectIsDailyRentOtaConversionExperimentActive';
import { useApplicationContext } from '../applicationContext';

export function useDailyRentOtaConversionExperimentEnabled() {
  const { config } = useApplicationContext();
  const isExperimentActive = useSelector(selectIsDailyRentOtaConversionExperimentActive);
  const isParticipateInExperiment = Boolean(
    config.get<boolean>('Dailyrent.WithoutInstantBookingFilterExperiment.Enabled'),
  );

  return isParticipateInExperiment && isExperimentActive;
}
