import { Select } from '@cian/ui-kit/select';
import * as React from 'react';

import { ESuburbanOfferFilter } from '../../../../../api-models/common/json_query';
import { HOMEOWNER } from '../../../constants/possessor';
import { Filter } from '../common/Filter';

interface IPossessorType {
  label: string;
  value: ESuburbanOfferFilter;
}

const POSSESSOR_TYPES: IPossessorType[] = [
  { label: 'Любой', value: ESuburbanOfferFilter.Any },
  { label: 'От собственника', value: HOMEOWNER },
  { label: 'От застройщика', value: ESuburbanOfferFilter.Builder },
  { label: 'От собственников и агентов', value: ESuburbanOfferFilter.Individual },
];

interface IPossessorProps {
  value: ESuburbanOfferFilter;
  onChange(value: ESuburbanOfferFilter): void;
}

export const Possessor: React.FC<IPossessorProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_possessor" dataName="filter_possessor" label="Продавец">
      <Select options={POSSESSOR_TYPES} value={value} onChange={onChange} title="Продавец" />
    </Filter>
  );
};
