import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IFloorsProps {
  floors: IJsonQueryRangeValue | null;
  onFloorsMinChange(gte: number | null): void;
  onFloorsMaxChange(lte: number | null): void;
}

export const Floors: React.FC<IFloorsProps> = ({ floors, onFloorsMinChange, onFloorsMaxChange }) => {
  const { gte: floorsMin = null, lte: floorsMax = null } = floors || {};

  const handleFloorsMinChange = useDebouncedCallback((gte: number | null) => {
    onFloorsMinChange(gte);
  }, 300);

  const handleFloorsMaxChange = useDebouncedCallback((lte: number | null) => {
    onFloorsMaxChange(lte);
  }, 300);

  return (
    <Filter id="filter_floors" label="Этажей в доме">
      <RangeInput
        min={1}
        max={200}
        thousandsSeparator=""
        joined={false}
        valueMin={floorsMin}
        valueMax={floorsMax}
        onChangeMin={handleFloorsMinChange}
        onChangeMax={handleFloorsMaxChange}
      />
    </Filter>
  );
};
