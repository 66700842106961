import * as React from 'react';

import { CatalogSelectContainer } from './containers/CatalogSelectContainer';
import { GeoSuggestContainer } from './containers/GeoSuggestContainer';
import { RegionalDistrictsContainer } from './containers/RegionalDistrictsContainer';
import { TagsContainer } from './containers/TagsContainer';
import { UndergroundMapContainer } from './containers/UndergroundMapContainer';
import { Geo } from '../../../components/filters/Geo';

export const GeoContainer: React.FC = () => {
  return (
    <Geo
      undergroundMap={<UndergroundMapContainer />}
      catalogSelect={<CatalogSelectContainer />}
      regionalDistricts={<RegionalDistrictsContainer />}
      geoSuggest={<GeoSuggestContainer />}
      tags={<TagsContainer />}
    />
  );
};
