import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { FDealType } from '../../../../../JsonQuery';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IRoomsInDealProps {
  dealType: FDealType;
  rooms: IJsonQueryRangeValue | null;
  onRoomsInDealMinChange(value: number | null): void;
  onRoomsInDealMaxChange(value: number | null): void;
}

export const RoomsInDeal: React.FC<IRoomsInDealProps> = ({
  dealType,
  rooms,
  onRoomsInDealMinChange,
  onRoomsInDealMaxChange,
}) => {
  const { gte: roomsMin = null, lte: roomsMax = null } = rooms || {};
  const label = FDealType.Rent & dealType ? 'Комнат в аренду' : 'Комнат на продажу';

  const handleRoomsInDealMinChange = useDebouncedCallback((gte: number | null) => {
    onRoomsInDealMinChange(gte);
  }, 300);

  const handleRoomsInDealMaxChange = useDebouncedCallback((lte: number | null) => {
    onRoomsInDealMaxChange(lte);
  }, 300);

  return (
    <Filter id="filter_roomsInDeal" label={label}>
      <RangeInput
        min={1}
        max={99}
        thousandsSeparator=""
        joined={false}
        valueMin={roomsMin}
        valueMax={roomsMax}
        onChangeMin={handleRoomsInDealMinChange}
        onChangeMax={handleRoomsInDealMaxChange}
      />
    </Filter>
  );
};
