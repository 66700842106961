import { pathOr } from 'ramda';
import * as React from 'react';

import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { SquareMeterArea } from '../../../components/filters/SquareMeterArea';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';
import { usePlaceholders } from '../../../utils/usePlaceholders';

export const SquareMeterAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const roomArea = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['room_area', 'value'], jsonQuery);
  const kitchenArea = pathOr<IJsonQueryRangeValue>({ gte: undefined, lte: undefined }, ['kitchen', 'value'], jsonQuery);
  const livingArea = pathOr<IJsonQueryRangeValue>(
    { gte: undefined, lte: undefined },
    ['living_area', 'value'],
    jsonQuery,
  );
  const livingAreaPlaceholders = usePlaceholders({ minKey: 'minLivingArea', maxKey: 'maxLivingArea' });
  const kitchenAreaPlaceholders = usePlaceholders({ minKey: 'minKitchenArea', maxKey: 'maxKitchenArea' });

  const handleRoomAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setRoomAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleRoomAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setRoomAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleKitchenAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setKitchenAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleKitchenAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setKitchenAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  const handleLivingAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setLivingAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleLivingAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setLivingAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <SquareMeterArea
      offerType={offerType}
      roomArea={roomArea}
      kitchenArea={kitchenArea}
      livingArea={livingArea}
      kitchenAreaPlaceholders={kitchenAreaPlaceholders}
      livingAreaPlaceholders={livingAreaPlaceholders}
      onRoomAreaMinChange={handleRoomAreaMinChange}
      onRoomAreaMaxChange={handleRoomAreaMaxChange}
      onKitchenAreaMinChange={handleKitchenAreaMinChange}
      onKitchenAreaMaxChange={handleKitchenAreaMaxChange}
      onLivingAreaMinChange={handleLivingAreaMinChange}
      onLivingAreaMaxChange={handleLivingAreaMaxChange}
    />
  );
};
