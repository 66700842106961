import * as React from 'react';

import { getConstructionTypesList } from './helpers';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray, FOfferType } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IConstructionTypeProps {
  offerType: FOfferType;
  value: NonEmptyArray<EHouseMaterial> | null;
  onChange(value: NonEmptyArray<EHouseMaterial> | null): void;
}

export const ConstructionType: React.FC<IConstructionTypeProps> = ({ offerType, value, onChange }) => {
  const constructionTypes = getConstructionTypesList(offerType);

  return (
    <Filter id="filter_constructionType" label="Материал дома">
      <CheckboxButtonGroup options={constructionTypes} value={value} onChange={onChange} />
    </Filter>
  );
};
