import * as React from 'react';

import { ITotalAreaProps, TotalAreaField } from '../../../../../Fields/shared/components/TotalAreaField';
import { SHRUG } from '../../../constants/shrug';
import { Filter } from '../common/Filter';

export const TotalArea: React.FC<ITotalAreaProps> = ({ totalArea, ...restProps }) => {
  const { gte: totalAreaMin = null, lte: totalAreaMax = null } = totalArea || {};

  return (
    <Filter id="filter_totalArea" label={`Общая площадь${totalAreaMin === 0 && totalAreaMax === 0 ? ` ${SHRUG}` : ''}`}>
      <TotalAreaField totalArea={totalArea} {...restProps} />
    </Filter>
  );
};
