import * as React from 'react';

import { INotification } from '../../types/notification';
import { NotificationsContext } from '../../utils/notificationsContext';
import { NotificationsContainer } from '../NotificationsContainer';

export const Provider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);

  const pushNotification = React.useCallback(
    (notification: INotification) => {
      setNotifications([...notifications, notification]);
    },
    [notifications],
  );

  const popNotification = React.useCallback(
    (notification: INotification) => {
      setNotifications(notifications.filter(n => n !== notification));
    },
    [notifications],
  );

  const context = React.useMemo(
    () => ({ notifications, pushNotification, popNotification }),
    [notifications, popNotification, pushNotification],
  );

  return (
    <NotificationsContext.Provider value={context}>
      <NotificationsContainer />
      {children}
    </NotificationsContext.Provider>
  );
};
