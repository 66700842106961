import * as React from 'react';

import { UndergroundMapModalContainer } from './UndergroundMapModalContainer';
import { CellEntry } from '../../../../../../../Fields/shared/components/CellGroup';
import { getRegionUndergroundIcon } from '../../../../../utils/getRegionUndergroundIcon';
import { useUndergroundMapEnabled } from '../../hooks/useUndergroundMapEnabled';
import { useUndergroundMapLocationId } from '../../hooks/useUndergroundMapLocationId';

export function UndergroundMapContainer() {
  const locationId = useUndergroundMapLocationId();
  const isUndergroundMapEnabled = useUndergroundMapEnabled();

  const [open, setOpen] = React.useState(false);

  if (!locationId || !isUndergroundMapEnabled) {
    return null;
  }

  return (
    <>
      <CellEntry icon={getRegionUndergroundIcon([locationId])} onClick={() => setOpen(true)}>
        Метро
      </CellEntry>
      <UndergroundMapModalContainer locationId={locationId} open={open} onClose={() => setOpen(false)} />
    </>
  );
}
