import * as React from 'react';

import { FDealType } from '../../../../../JsonQuery';
import { RentType } from '../../../components/filters/RentType';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';

export const RentTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const dealType = useDealType();

  const handleChange = React.useCallback(
    (dealType: FDealType) => {
      onChange({ action: 'setDealType', arguments: [dealType] });
    },
    [onChange],
  );

  return <RentType value={dealType} onChange={handleChange} />;
};
