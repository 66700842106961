import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EGarageType } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IGarageTypeOption {
  label: string;
  value: EGarageType;
}

const GARAGE_TYPE_OPTIONS: IGarageTypeOption[] = [
  { label: 'Гараж', value: EGarageType.Garage },
  { label: 'Машиноместо', value: EGarageType.CarPlace },
  { label: 'Бокс', value: EGarageType.Box },
];

interface IGarageTypeProps {
  value: NonEmptyArray<EGarageType> | null;
  onChange(value: NonEmptyArray<EGarageType> | null): void;
}

export const GarageType: React.FC<IGarageTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_garageType" label="Тип">
      <CheckboxButtonGroup options={GARAGE_TYPE_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
