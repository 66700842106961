import * as React from 'react';

import { useDailyRentOtaConversionExperimentEnabled } from '../../../../../../shared/utils/experiments/useDailyRentOtaConversionExperimentEnabled';
import { getTermValue, isSuburban } from '../../../../../JsonQuery';
import { OnlineBooking } from '../../../components/filters/OnlineBooking';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const OnlineBookingContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const offerType = useOfferType();
  const isOnlineBooking = Boolean(getTermValue('online_booking')(jsonQuery));
  const isDailyRentOtaConversionExperimentEnabled = useDailyRentOtaConversionExperimentEnabled();

  const handleIsOnlineBookingChange = React.useCallback(
    (value: boolean) => {
      onChange({ action: 'setOnlineBooking', arguments: [value] });
    },
    [onChange],
  );

  const isCompact = !isSuburban(offerType);

  if (isDailyRentOtaConversionExperimentEnabled) {
    return null;
  }

  return (
    <OnlineBooking
      isCompact={isCompact}
      isOnlineBooking={isOnlineBooking}
      onIsOnlineBookingChange={handleIsOnlineBookingChange}
    />
  );
};
