import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackBackClick, trackClearClick } from './tracking';
import { getUncommonProperties, JsonQuery, TJsonQuery } from '../../../../../packages/JsonQuery';
import { changeJsonQuery } from '../../../../actions/filters';
import { Header } from '../../../../components/Filters';
import { IApplicationState, TThunkDispatch } from '../../../../types/redux';

export const HeaderContainer: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const jsonQuery = useSelector<IApplicationState, TJsonQuery>(state => state.filters.jsonQuery);

  const handleBack = React.useCallback(() => {
    trackBackClick();

    window.history.back();
  }, []);

  const handleClear = React.useCallback(() => {
    const uncommonProperties = getUncommonProperties(jsonQuery);
    if (!uncommonProperties) {
      return;
    }

    const jq = new JsonQuery(jsonQuery);

    jq.resetTerms(uncommonProperties);

    dispatch(
      changeJsonQuery({
        prevJsonQuery: jsonQuery,
        appliedModifiers: [{ action: 'resetTerms', arguments: [uncommonProperties] }],
        nextJsonQuery: jq.toJSON(),
      }),
    );

    trackClearClick();
  }, [dispatch, jsonQuery]);

  return <Header onBack={handleBack} onClear={handleClear} />;
};
