import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';
import { IRegistry } from '@cian/mf-registry/shared';
import { ITelemetryLogger } from '@cian/telemetry/shared';
import { TDeviceType } from '@cian/ui-kit/types';

import { EMobileDeviceType } from './mobileDeviceType';
import { CancellationTokenManager } from '../utils/cancellationTokenManager';

export enum EOrigin {
  Map = 'map',
}

export interface IApplicationContextCustom {
  currentYear: number;
  initialDeviceType: TDeviceType;
  isTestMode: boolean;
  mobileDeviceType: EMobileDeviceType;
  origin: EOrigin | null;
  subdomain: string;
}

export interface IApplicationContext {
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
  telemetry: ITelemetryLogger;
  cancellationTokenManager?: CancellationTokenManager;
  custom: IApplicationContextCustom;
  microfrontendsRegistry: IRegistry;
}
