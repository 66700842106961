import { ca } from '@cian/analytics';

export function trackClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'change_offer_type',
    label: 'offices',
  });
}
