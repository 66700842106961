import * as React from 'react';

import { EFacilityType, JsonQuery, NonEmptyArray } from '../../../../../JsonQuery';
import { BathType } from '../../../components/filters/BathType';
import { useContext } from '../../../utils/useContext';

export const BathTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const bathTypes = new JsonQuery(jsonQuery).getBathTypes();

  const handleBathTypesChange = React.useCallback(
    (bathTypes: NonEmptyArray<EFacilityType> | null) => {
      onChange({ action: 'setBathTypes', arguments: [bathTypes] });
    },
    [onChange],
  );

  return <BathType value={bathTypes} onChange={handleBathTypesChange} />;
};
