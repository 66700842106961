export const EXPERIMENT_NEW_FILTERS = 'audience_new_mobile_filters';
export const DAILY_RENT_OTA_CONVERSION = 'dailyrent_web_sut375_online_booking_cr';

export const AUD_GOOD_PRICE_FILTER_EXPERIMENT = 'vx_mobweb_aud_3827_good_price';

export const AUD_MIXED_FILTERS_REDESIGN_EXPERIMENT = 'vx_mobweb_aud_3788_remove_filters';
export const BX_TUNE_AUTH = 'aud_web_aud3770_tune_authorization';

export const EXPERIMENTS: string[] = [
  EXPERIMENT_NEW_FILTERS,
  DAILY_RENT_OTA_CONVERSION,
  AUD_GOOD_PRICE_FILTER_EXPERIMENT,
  AUD_MIXED_FILTERS_REDESIGN_EXPERIMENT,
  BX_TUNE_AUTH,
];
