import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { Filter, FilterRow } from '../common/Filter';

interface IDealTypeProps {
  isByHomeowner: boolean | null;
  isFromDeveloper: boolean | null;
  domRfAvailable?: boolean;
  isByDomRf: boolean | null;
  withDeposit: boolean | null;
  showDepositFilter?: boolean;
  showFromDeveloperFilter?: boolean;
  onIsByHomeownerChange(value: boolean | null): void;
  onIsByDomRfChange(value: boolean | null): void;
  onWithDepositChange(value: boolean | null): void;
  onIsFromDeveloperChange(value: boolean | null): void;
}

export const DealType: React.FC<IDealTypeProps> = ({
  isByHomeowner,
  domRfAvailable: isDomRfAvailable,
  isByDomRf,
  isFromDeveloper,
  withDeposit,
  showDepositFilter,
  showFromDeveloperFilter,
  onIsByHomeownerChange,
  onIsByDomRfChange,
  onWithDepositChange,
  onIsFromDeveloperChange,
}) => {
  const handleWithDepositChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onWithDepositChange(value ? false : null);
    },
    [onWithDepositChange],
  );

  const filterTitle = showDepositFilter ? 'Условия аренды' : 'Тип сделки';
  const isByHomeownerSwitchTitle = showDepositFilter ? 'От собственника' : 'Только от собственника';

  return (
    <Filter id="filter_dealType" label={filterTitle}>
      <FilterRow>
        <Switch
          label={isByHomeownerSwitchTitle}
          checked={!!isByHomeowner}
          onChange={(_, value) => onIsByHomeownerChange(value || null)}
        />
      </FilterRow>

      {showFromDeveloperFilter && (
        <FilterRow>
          <Switch
            label="Только от застройщика"
            checked={!!isFromDeveloper}
            onChange={(_, value) => onIsFromDeveloperChange(value || null)}
          />
        </FilterRow>
      )}

      {isDomRfAvailable && (
        <FilterRow>
          <Switch
            label="Аренда квартир от ДОМ.РФ"
            checked={!!isByDomRf}
            onChange={(_, value) => onIsByDomRfChange(value || null)}
          />
        </FilterRow>
      )}

      {showDepositFilter && (
        <FilterRow>
          <Switch label="Без залога" checked={withDeposit === false} onChange={handleWithDepositChange} />
        </FilterRow>
      )}
    </Filter>
  );
};
