import { ISearchMeta } from '../../../types/searchMeta';
import { getNormalizedSeoPath } from '../containers/FooterContainer';
import { trackSearchClick, trackSearchOnMapClick } from '../containers/FooterContainer/tracking';

interface IMakeSearchParams {
  domain: string;
  searchMeta: ISearchMeta;
}

export function makeSearch({ domain, searchMeta }: IMakeSearchParams) {
  const { seoPath, subdomain, queryString } = searchMeta;
  let hrefSearch = `https://${subdomain}.${domain}/`;

  if (seoPath) {
    hrefSearch += getNormalizedSeoPath(seoPath);
  } else {
    hrefSearch += `cat.php?${queryString}`;
  }

  trackSearchClick(queryString);
  window.location.href = hrefSearch;
}

export function makeSearchOnMap({ domain, searchMeta }: IMakeSearchParams) {
  const { subdomain, queryString } = searchMeta;

  const hrefMap = `https://${subdomain}.${domain}/map/?${queryString}`;

  trackSearchOnMapClick(queryString);
  window.location.href = hrefMap;
}
