import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomsTotal } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IRoom {
  label: string;
  value: ERoomsTotal;
}

const ROOMS: IRoom[] = [
  { label: '1', value: ERoomsTotal.One },
  { label: '2', value: ERoomsTotal.Two },
  { label: '3', value: ERoomsTotal.Three },
  { label: '4', value: ERoomsTotal.Four },
  { label: '5', value: ERoomsTotal.Five },
];

interface IFlatRoomsProps {
  label: string;
  value: NonEmptyArray<ERoomsTotal> | null;
  onChange(value: NonEmptyArray<ERoomsTotal> | null): void;
}

export const FlatRooms: React.FC<IFlatRoomsProps> = ({ label, value, onChange }) => {
  return (
    <Filter id="filter_flatRooms" label={label}>
      <CheckboxButtonGroup options={ROOMS} value={value} onChange={onChange} />
    </Filter>
  );
};
