import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EDecorationType } from '../../../../../JsonQuery/types/jsonQuery/jsonQueryDecorationType';
import { ERepair } from '../../../../../api-models/common/json_query';
import { Renovation } from '../../../components/filters/Renovation';
import { useContext } from '../../../utils/useContext';

export const RenovationContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();

  const repair = getTermsValue('repair')(jsonQuery);

  const decorationsList = getTermsValue('decorations_list')(jsonQuery);

  const handleRepairChange = React.useCallback(
    (repair: NonEmptyArray<ERepair> | null) => {
      onChange({ action: 'setRepair', arguments: [repair] });
    },
    [onChange],
  );

  const handleDecorationsChange = React.useCallback(
    (decorationsList: NonEmptyArray<EDecorationType> | null) => {
      onChange({ action: 'setDecorationsList', arguments: [decorationsList] });
    },
    [onChange],
  );

  return (
    <Renovation
      repairValue={repair}
      decorationValue={decorationsList}
      onRepairChange={handleRepairChange}
      onDecorationChange={handleDecorationsChange}
    />
  );
};
