export function getNormalizedSeoPath(path: string): string {
  /**
   * Добавляем слеш в конец пути только, если это путь не содержит query
   * и если путь уже не содержит слеш в конце
   */
  if (!path.includes('?') && !path.endsWith('/')) {
    return `${path}/`;
  }

  return path;
}
