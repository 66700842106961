import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomType } from '../../../../../api-models/common/json_query';
import { IRoomType } from '../../../containers/filters/RoomTypeContainer';
import { Filter } from '../common/Filter';

interface IRoomTypeProps {
  value: NonEmptyArray<ERoomType> | null;
  roomTypes: IRoomType[];
  onChange(value: NonEmptyArray<ERoomType> | null): void;
}

export const RoomType: React.FC<IRoomTypeProps> = ({ value, roomTypes, onChange }) => {
  return (
    <Filter id="filter_roomType" label="Количество комнат">
      <CheckboxButtonGroup options={roomTypes} value={value} onChange={onChange} />
    </Filter>
  );
};
