import * as React from 'react';

import { FDealType, FOfferType, getTermValue } from '../../../../../JsonQuery';
import { EPublishPeriod } from '../../../../../api-models/common/json_query';
import { AboutOffer } from '../../../components/filters/AboutOffer';
import { useContext } from '../../../utils/useContext';
import { useDealType } from '../../../utils/useDealType';
import { useOfferType } from '../../../utils/useOfferType';

export const AboutOfferContainer: React.FC = () => {
  const { onChange, jsonQuery, isMixedFiltersRedesign } = useContext();

  const dealType = useDealType();
  const offerType = useOfferType();

  const publishPeriod = getTermValue('publish_period')(jsonQuery);
  const hasVideo = getTermValue('has_video')(jsonQuery);
  const hasPhoto = getTermValue('wp')(jsonQuery);
  const withLayout = getTermValue('with_layout')(jsonQuery);

  const handlePublishPeriodChange = React.useCallback(
    (period: EPublishPeriod | null) => {
      onChange({ action: 'setPublishPeriod', arguments: [period] });
    },
    [onChange],
  );

  const handleHasVideoChange = React.useCallback(
    (hasVideo: boolean | null) => {
      onChange({ action: 'setHasVideo', arguments: [hasVideo] });
    },
    [onChange],
  );

  const handleHasPhotoChange = React.useCallback(
    (hasPhoto: boolean | null) => {
      onChange({ action: 'setHasPhoto', arguments: [hasPhoto] });
    },
    [onChange],
  );

  const handleWithLayoutChange = React.useCallback(
    (withLayout: boolean | null) => {
      onChange({ action: 'setWithLayout', arguments: [withLayout] });
    },
    [onChange],
  );

  return (
    <AboutOffer
      publishPeriod={publishPeriod}
      hasVideo={hasVideo}
      hasPhoto={hasPhoto}
      withLayout={withLayout}
      onPublishPeriodChange={handlePublishPeriodChange}
      onHasVideoChange={handleHasVideoChange}
      onHasPhotoChange={handleHasPhotoChange}
      onWithLayoutChange={handleWithLayoutChange}
      showMixedFilters={isMixedFiltersRedesign && dealType === FDealType.Sale && offerType === FOfferType.Flat}
    />
  );
};
