import { Chips } from '@cian/ui-kit';
import * as React from 'react';

import { useFilterChips } from './hooks/useFilterChips';
import { IFilterChipsProps, TFilterType } from './types';

/**
 * Обертка над китовым Chips, для поддержки null и boolean в качестве значения
 *
 * @example // Пример использования
 * <FilterChips
 *   selectType="multiple"
 *   onChange={handleChange}
 *   options={filterOptions}
 *   value={selectedValues}
 * />
 */

export const FilterChips: React.FC<IFilterChipsProps<TFilterType>> = ({
  selectType,
  onChange,
  options: optionsProp,
  value: valueProp,
}) => {
  const { options, value, handleClickChips } = useFilterChips({ selectType, onChange, optionsProp, valueProp });

  return <Chips selectType={selectType} value={value} options={options} size="M" onChipClick={handleClickChips} />;
};
