import { PROPERTIES } from '../properties';
import { TJsonQuery, TJsonQueryKeys } from '../types';

export function isPropertyAvailable(key: TJsonQueryKeys): (jsonQuery: TJsonQuery) => boolean {
  return (jsonQuery: TJsonQuery) => {
    const availabilityFunction = PROPERTIES[key].availability;
    if (availabilityFunction && !availabilityFunction(jsonQuery)) {
      return false;
    }

    return true;
  };
}
