import { ArticleHeading3, ArticleParagraph1, ActionLink } from '@cian/ui-kit';
import { IconActionChevronDownSmall16, IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './FilterChipsCollapsed.css';
import { IFiltersChipsCollapsedProps } from './types';

export const FilterChipsCollapsed: React.FC<React.PropsWithChildren<IFiltersChipsCollapsedProps>> = props => {
  const { children, label, labelNew, chosenFiltersCount, isExpanded, onToggleExpansion, isMobile } = props;
  const buttonText = isExpanded ? 'Скрыть' : 'Смотреть все';
  const ButtonIcon = isExpanded ? IconActionChevronUpSmall16 : IconActionChevronDownSmall16;

  return (
    <>
      <div className={styles['top-content']}>
        {label && <ArticleHeading3>{label}</ArticleHeading3>}
        {labelNew}
      </div>
      {children}
      {isMobile && (
        <div className={styles['bottom-content']}>
          <ActionLink spacingSize="M" onClick={onToggleExpansion} afterIcon={<ButtonIcon color="icon-main-default" />}>
            {buttonText}
          </ActionLink>
          {!isExpanded && !!chosenFiltersCount && (
            <ArticleParagraph1 color="current_color">{`Выбрано ${chosenFiltersCount}`}</ArticleParagraph1>
          )}
        </div>
      )}
    </>
  );
};
