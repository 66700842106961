import { IJsonQueryTerms } from '../../../api-models/common/json_query';

export enum EDecorationType {
  PreFine = 'preFine',
  Fine = 'fine',
  FineWithFurniture = 'fineWithFurniture',
  Without = 'without',
}

export type TDecorationsListTerms = { decorations_list?: IJsonQueryTerms<EDecorationType> };
