/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetGeoTagsModel,
  IGetGeoTagsRequest,
  IMappers,
  IGetGeoTagsResponse,
  IGetGeoTagsResponseError,
  TGetGeoTagsResponse,
} from './types';

const defaultConfig: TGetGeoTagsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'geo-temp-layer',
  pathApi: '/v1/get-geo-tags/',
  hostType: 'api',
} as TGetGeoTagsModel;

function createGetGeoTagsModel(parameters: IGetGeoTagsRequest): TGetGeoTagsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetGeoTagsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetGeoTagsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetGeoTags<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetGeoTagsOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetGeoTagsResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetGeoTagsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetGeoTagsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetGeoTagsResponseError);
    }
  }

  return { response, statusCode } as TGetGeoTagsResponse;
}

export { defaultConfig, createGetGeoTagsModel, fetchGetGeoTags };
