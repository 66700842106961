import * as React from 'react';

import { NonEmptyArray } from '../../../../../../../JsonQuery';
import { IOption, TOptionValue, TUseFilterChips } from '../types';

export const useFilterChips = ({ selectType, onChange, optionsProp, valueProp }: TUseFilterChips) => {
  const isNullValue = valueProp === null;

  const { options, optionsMap } = React.useMemo(() => {
    const options: IOption[] = [];
    const optionsMap: Record<string, TOptionValue | null> = {};

    optionsProp.forEach(item => {
      const value = String(item.value);

      options.push({
        ...item,
        value,
      });

      optionsMap[value] = item.value;
    });

    return {
      options,
      optionsMap,
    };
  }, [optionsProp]);

  const value: (string | number | boolean | null)[] | undefined = React.useMemo(() => {
    if (selectType === 'oneOf') {
      return [String(valueProp)];
    } else {
      // istanbul ignore else
      if (isNullValue) {
        return [String(null)];
      } else {
        return Array.isArray(valueProp) ? valueProp.map(String) : [String(valueProp)];
      }
    }
  }, [isNullValue, selectType, valueProp]);

  const handleClickRadioButtonChips = React.useCallback(
    (chipValue: string) => onChange(optionsMap[chipValue]),
    [onChange, optionsMap],
  );

  const handleClickSingleChips = React.useCallback(
    (chipValue: string) => {
      if (isNullValue) {
        onChange(optionsMap[chipValue]);

        return;
      }

      onChange(value.indexOf(chipValue) !== -1 ? null : optionsMap[chipValue]);
    },
    [isNullValue, onChange, optionsMap, value],
  );

  const handleClickMultipleChips = React.useCallback(
    (chipValue: string) => {
      const optionValue = optionsMap[chipValue];

      // istanbul ignore else
      if (isNullValue) {
        onChange([optionValue]);

        return;
      }

      const valuePropArray = valueProp as NonEmptyArray<TOptionValue>;

      const newValue = valuePropArray.map(String).includes(chipValue)
        ? valuePropArray.filter(v => v !== optionValue)
        : [...valuePropArray, optionValue];

      // istanbul ignore next
      onChange(newValue.includes(null) ? null : (newValue as NonEmptyArray<TOptionValue>));
    },
    [isNullValue, onChange, optionsMap, valueProp],
  );

  const handleClickChips = React.useCallback(
    (chipValue: string) => {
      if (selectType === 'oneOf') {
        handleClickRadioButtonChips(chipValue);
      }
      if (selectType === 'single') {
        handleClickSingleChips(chipValue);
      }
      if (selectType === 'multiple') {
        handleClickMultipleChips(chipValue);
      }
    },
    [handleClickMultipleChips, handleClickRadioButtonChips, handleClickSingleChips, selectType],
  );

  return React.useMemo(() => ({ options, value, handleClickChips }), [options, value, handleClickChips]);
};
