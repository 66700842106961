import * as React from 'react';

import { ERentTime } from '../../../../../JsonQuery';
import { DEFAULT_LONGTERM_RENT_OPTION } from '../../../../../JsonQuery/constants';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IRentalPeriodOption {
  label: string;
  value: ERentTime;
}

const RENTAL_PERIOD_OPTIONS: IRentalPeriodOption[] = [
  { label: 'От года', value: ERentTime.FromYear },
  { label: 'На несколько месяцев', value: ERentTime.FewMonths },
];

interface IRentalPeriodProps {
  value: ERentTime;
  onChange(value: ERentTime): void;
}

export const RentalPeriod: React.FC<IRentalPeriodProps> = ({ value, onChange }) => {
  const handleChange = React.useCallback(
    (value: ERentTime) => {
      onChange(value !== null ? value : DEFAULT_LONGTERM_RENT_OPTION);
    },
    [onChange],
  );

  return (
    <Filter id="filter_rentalPeriod" label="Срок аренды">
      <RadioButtonGroupUnselectable
        options={RENTAL_PERIOD_OPTIONS}
        value={value}
        onChange={handleChange}
        spliced={false}
      />
    </Filter>
  );
};
