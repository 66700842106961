import { TJsonQueryKeys } from '../types';

export const COMMON_PROPERTIES: TJsonQueryKeys[] = [
  '_type',
  'building_status',
  'category',
  'engine_version',
  'for_day',
  'object_type',
  'office_type',
  'region',
  'with_newobject',
];
