import { NonEmptyArray } from '../../Fields';
import { PIK_DEVELOPER_ID } from '../../Filters/shared/constants/pikPromo';
import { PIK_PROMO_REGIONS } from '../constants';

export function isPikPromoAvailable(developerId: number, regionIds: NonEmptyArray<number> | null) {
  if (!regionIds) {
    return false;
  }

  return developerId === PIK_DEVELOPER_ID && regionIds.every(regionId => PIK_PROMO_REGIONS.includes(regionId));
}
