import { useMemo } from 'react';

import { TPromoSearchTypes } from '../../../../../../../shared/types/promoSearchTypes';
import { getPromoSearchFilterValuesByType } from '../../../../../../JsonQuery';

export const useDiscountPromoSearchTypeOptions = (promoSearchTypes: TPromoSearchTypes) => {
  return useMemo(() => {
    if (!promoSearchTypes) {
      return [];
    }

    const promoSearchTypeValues = getPromoSearchFilterValuesByType('discount', promoSearchTypes);

    return [
      ...promoSearchTypeValues.map(value => {
        const label = promoSearchTypes.discount.find(type => type.value === value)?.name ?? '';

        return {
          value,
          label,
        };
      }),
    ];
  }, [promoSearchTypes]);
};
