import * as React from 'react';

import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { EGarageType } from '../../../../../api-models/common/json_query';
import { GarageType } from '../../../components/filters/GarageType';
import { useContext } from '../../../utils/useContext';

export const GarageTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const garageTypes = getTermsValue('garage_type')(jsonQuery);

  const handleChange = React.useCallback(
    (garageTypes: NonEmptyArray<EGarageType> | null) => {
      onChange({ action: 'setGarageTypes', arguments: [garageTypes] });
    },
    [onChange],
  );

  return <GarageType value={garageTypes} onChange={handleChange} />;
};
