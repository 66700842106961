import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EGarageStatus } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IGarageStatusOption {
  label: string;
  value: EGarageStatus;
}

const GARAGE_STATUS_OPTIONS: IGarageStatusOption[] = [
  { label: 'Собственность', value: EGarageStatus.Ownership },
  { label: 'Кооператив', value: EGarageStatus.Cooperative },
  { label: 'По договорённости', value: EGarageStatus.Agreement },
];

interface IGarageStatusProps {
  value: NonEmptyArray<EGarageStatus> | null;
  onChange(value: NonEmptyArray<EGarageStatus> | null): void;
}

export const GarageStatus: React.FC<IGarageStatusProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_garageStatus" label="Статус">
      <CheckboxButtonGroup options={GARAGE_STATUS_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
