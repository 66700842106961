import { updateRegionalDistrictsRaw } from './types';
import { fetchRegionalDistrictsIfNeeded } from '../../services/fetchRegionalDistricts';
import { TThunkAction } from '../../types/redux';

export function updateRegionalDistricts(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      filters: { jsonQuery },
      regionMeta,
    } = getState();

    const regionalDistricts = await fetchRegionalDistrictsIfNeeded(context, {
      jsonQuery,
      locationId: regionMeta.id,
    });

    dispatch(updateRegionalDistrictsRaw(regionalDistricts));
  };
}
