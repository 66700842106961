import * as React from 'react';

import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IOption {
  label: string;
  value: boolean;
}

const options: IOption[] = [
  { label: 'Не показывать', value: false },
  { label: 'Показывать только их', value: true },
];

interface IIsEncumbranceRealtyProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const IsEncumbranceRealty: React.FC<IIsEncumbranceRealtyProps> = ({ value, onChange }) => {
  return (
    <Filter
      id="advancedFilter_is_encumbrance_realty"
      label="Объекты с обременением"
      infoContent="Сделка с такими объектами может быть сложнее: они могут быть в залоге у банка."
    >
      <RadioButtonGroupUnselectable options={options} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
