import * as React from 'react';

import { IUnderground, IUndergroundsAdditinalGrouping } from '../../../../../../../../Fields/shared/types/underground';
import { UndergroundIcon } from '../../../../../../components/UndergroundIcon';
import { ETabType, ICatalogSelectOption, ICatalogSelectOptionsGroup } from '../../../../../../components/filters/Geo';

export function getUndergroundsOptions(
  regionId: number[],
  undergrounds: IUnderground[],
  additionalGroupings?: IUndergroundsAdditinalGrouping[] | null,
): ICatalogSelectOptionsGroup[] {
  const options: ICatalogSelectOptionsGroup[] = [];

  const additionalOptions: ICatalogSelectOption[] = [];
  if (undergrounds.some(u => u.underConstruction)) {
    additionalOptions.push({
      type: ETabType.Undergrounds,
      label: 'Все строящиеся станции',
      value: undergrounds.filter(u => u.underConstruction).map(u => u.id),
    });
  }
  if (additionalGroupings && additionalGroupings.length > 0) {
    const sortedAdditionalGroupings = [...additionalGroupings];
    sortedAdditionalGroupings.sort((a, b) => a.position - b.position);

    sortedAdditionalGroupings
      .filter(g => g.regionId.some(gr => regionId.some(r => gr === r)))
      .forEach(g => {
        additionalOptions.splice(g.position, 0, {
          type: ETabType.Undergrounds,
          label: g.label,
          value: g.value,
        });
      });
  }

  if (additionalOptions.length > 0) {
    options.push({
      options: additionalOptions,
    });
  }

  const sortedUndergrounds = [...undergrounds];
  sortedUndergrounds.sort((a, b) => a.name.localeCompare(b.name));

  options.push({
    options: sortedUndergrounds.map(u => ({
      type: ETabType.Undergrounds,
      label: `${u.name}${u.underConstruction ? ` \u00b7 ${u.releaseYear}` : ''}`,
      image: <UndergroundIcon regionId={regionId} color={u.lineColor} />,
      value: [u.id],
    })),
  });

  return options;
}
