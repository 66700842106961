import * as React from 'react';

import { FOfferType } from '../../../../../JsonQuery';
import { FlatType } from '../../../components/filters/FlatType';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const FlatTypeContainer: React.FC = () => {
  const { onChange } = useContext();
  const offerType = useOfferType();

  const handleChange = React.useCallback(
    (offerType: FOfferType) => {
      onChange({ action: 'setOfferType', arguments: [offerType] });
    },
    [onChange],
  );

  return <FlatType value={offerType} onChange={handleChange} />;
};
