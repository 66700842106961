import { useEventCallback } from '@cian/react-utils';
import { useState } from 'react';

import { trackModalOpen } from '../tracking';

export const useModalState = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useEventCallback(() => {
    setIsOpen(true);

    trackModalOpen();
  });

  const handleClose = useEventCallback(() => {
    setIsOpen(false);
  });

  return { isOpen, onOpen: handleOpen, onClose: handleClose };
};
