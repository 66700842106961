import { MultiSelect } from '@cian/ui-kit/select';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../JsonQuery';
import { EHouseMaterial } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IConstructionType {
  label: string;
  value: Exclude<EHouseMaterial, EHouseMaterial.Stalin>;
}

const CONSTRUCTION_TYPE_OPTIONS: IConstructionType[] = [
  { label: 'Кирпичный', value: EHouseMaterial.Brick },
  { label: 'Монолитный', value: EHouseMaterial.Monolith },
  { label: 'Каркасный', value: EHouseMaterial.Wireframe },
  { label: 'Щитовой', value: EHouseMaterial.Shield },
  { label: 'Деревянный', value: EHouseMaterial.Wood },
  { label: 'Газобетонный блок', value: EHouseMaterial.AerocreteBlock },
  { label: 'Газосиликатный блок', value: EHouseMaterial.GasSilicateBlock },
  { label: 'Пенобетонный блок', value: EHouseMaterial.FoamConcreteBlock },
  { label: 'Не указан', value: EHouseMaterial.None },
];

interface ISuburbanConstructionTypeProps {
  value: NonEmptyArray<EHouseMaterial> | null;
  onChange(value: NonEmptyArray<EHouseMaterial> | null): void;
}

export const SuburbanConstructionType: React.FC<ISuburbanConstructionTypeProps> = ({ value, onChange }) => {
  const handleChange = React.useCallback(
    (constructionType: EHouseMaterial[]) => {
      onChange(constructionType.length > 0 ? (constructionType as NonEmptyArray<EHouseMaterial>) : null);
    },
    [onChange],
  );

  return (
    <Filter dataName="filter_suburbanConstructionType" id="filter_suburbanConstructionType" label="Материал дома">
      <MultiSelect
        options={CONSTRUCTION_TYPE_OPTIONS}
        value={value}
        onChange={handleChange}
        placeholder="Любой"
        title="Материал дома"
        allOption="Все материалы"
      />
    </Filter>
  );
};
