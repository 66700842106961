import * as React from 'react';

import { useContext } from './useContext';
import { getTermValue } from '../../../JsonQuery';
import { ERentTime } from '../../../api-models/common/json_query';
import { trackHotelsBannerClick } from '../tracking/hotels';

export function useHotelsExperiment() {
  const { jsonQuery, config } = useContext();
  const isDailyRent = getTermValue('for_day')(jsonQuery) === ERentTime.Daily;
  const isToggleEnabled = Boolean(config.get<boolean>('Dailyrent.HotelsSearchFrontend.Enabled'));

  const onHotelsClick = React.useCallback(() => {
    trackHotelsBannerClick(jsonQuery);
  }, [jsonQuery]);

  return React.useMemo(
    () => ({
      isHotelsBannerVisible: isToggleEnabled && isDailyRent,
      hotelsLink: '/map/?deal_type=rent&engine_version=2&offer_type=flat&type=2&show_hotels=true',
      onHotelsClick,
    }),
    [isToggleEnabled, isDailyRent, onHotelsClick],
  );
}
