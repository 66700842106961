import { COMMON_PROPERTIES } from '../constants';
import { NonEmptyArray, TJsonQuery, TJsonQueryKeys, TJsonQueryOptionalKeys } from '../types';

export function getUncommonProperties(jsonQuery: TJsonQuery): NonEmptyArray<TJsonQueryOptionalKeys> | null {
  const uncommonProperties = (Object.keys(jsonQuery) as TJsonQueryKeys[]).filter(
    (property): property is TJsonQueryOptionalKeys => !COMMON_PROPERTIES.includes(property),
  );

  return uncommonProperties.length > 0 ? (uncommonProperties as NonEmptyArray<TJsonQueryOptionalKeys>) : null;
}
