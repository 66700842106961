import { IconMapHighway16, IconFunctionalHouse16, ISVGIconProps } from '@cian/ui-kit-design-tokens/icons';

import { getDistrictsOptions } from './getDistrictsOptions';
import { getHighwaysOptions } from './getHighwaysOptions';
import { getUndergroundsOptions } from './getUndergroundsOptions';
import { IDistrict } from '../../../../../../../../Fields/shared/types/districts';
import { IHighwaysGroup } from '../../../../../../../../Fields/shared/types/highways';
import { IUnderground, IUndergroundsAdditinalGrouping } from '../../../../../../../../Fields/shared/types/underground';
import { FOfferType } from '../../../../../../../../JsonQuery';
import { ICatalogSelectTab } from '../../../../../../components/filters/Geo';
import { MOSCOW_ID } from '../../../../../../constants/regions';
import { getRegionUndergroundIcon } from '../../../../../../utils/getRegionUndergroundIcon';

interface IGetCatalogSelectOptionsParameters {
  offerType: FOfferType;
  regionId?: number[] | null;
  undergrounds?: IUnderground[] | null;
  undergroundsAdditionalGroupings?: IUndergroundsAdditinalGrouping[] | null;
  districts?: IDistrict[] | null;
  highways?: IHighwaysGroup[] | null;
}

export interface ICatalogSelectTabWithIcon extends ICatalogSelectTab {
  icon: React.ComponentType<ISVGIconProps>;
}

export function getCatalogSelectOptions({
  offerType,
  regionId,
  undergrounds,
  undergroundsAdditionalGroupings,
  districts,
  highways,
}: IGetCatalogSelectOptionsParameters): ICatalogSelectTabWithIcon[] {
  const options: ICatalogSelectTabWithIcon[] = [];

  if (undergrounds) {
    options.push({
      label: 'Метро',
      icon: getRegionUndergroundIcon(regionId || []),
      options: getUndergroundsOptions(regionId || [MOSCOW_ID], undergrounds, undergroundsAdditionalGroupings),
    });
  }

  if (districts) {
    options.push({
      label: 'Район',
      icon: IconFunctionalHouse16,
      options: getDistrictsOptions(districts),
    });
  }

  if (highways) {
    const highwaysTab: ICatalogSelectTabWithIcon = {
      label: 'Шоссе',
      icon: IconMapHighway16,
      options: getHighwaysOptions(highways),
    };

    if ((offerType & FOfferType.Suburban) !== 0) {
      options.unshift(highwaysTab);
    } else {
      options.push(highwaysTab);
    }
  }

  return options;
}
