import { Select } from '@cian/ui-kit/select';
import * as React from 'react';

import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

enum EBedrooms {
  Any = 0,
  FromOne = 1,
  FromTwo = 2,
  FromThree = 3,
  FromFour = 4,
  FromFive = 5,
  FromSix = 6,
  FromSeven = 7,
}

interface IBedrooms {
  label: string;
  value: EBedrooms;
}

const BEDROOMS: IBedrooms[] = [
  { label: 'Неважно', value: EBedrooms.Any },
  { label: 'от 1', value: EBedrooms.FromOne },
  { label: 'от 2', value: EBedrooms.FromTwo },
  { label: 'от 3', value: EBedrooms.FromThree },
  { label: 'от 4', value: EBedrooms.FromFour },
  { label: 'от 5', value: EBedrooms.FromFive },
  { label: 'от 6', value: EBedrooms.FromSix },
  { label: '7 и более', value: EBedrooms.FromSeven },
];

interface IBedroomsProps {
  value: IJsonQueryRangeValue | null;
  onChange(gte: number | null, lte: number | null): void;
}

export const Bedrooms: React.FC<IBedroomsProps> = ({ value, onChange }) => {
  const { gte: bedroomsMin = null } = value || {};

  const handleChange = React.useCallback(
    (value: EBedrooms) => {
      onChange(value || null, null);
    },
    [onChange],
  );

  return (
    <Filter id="filter_bedrooms" label="Количество спален">
      <Select
        options={BEDROOMS}
        value={bedroomsMin !== null ? (bedroomsMin <= 7 ? bedroomsMin : EBedrooms.FromSeven) : EBedrooms.Any}
        onChange={handleChange}
      />
    </Filter>
  );
};
