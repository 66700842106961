import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { Floors } from '../../../components/filters/Floors';
import { useContext } from '../../../utils/useContext';

export const FloorsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const floors = getRangeValue('floorn')(jsonQuery);

  const handleFloorsMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setFloorsMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleFloorsMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setFloorsMax', arguments: [lte] });
    },
    [onChange],
  );

  return <Floors floors={floors} onFloorsMinChange={handleFloorsMinChange} onFloorsMaxChange={handleFloorsMaxChange} />;
};
