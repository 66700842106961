import * as React from 'react';

import { ENotificationType, INotification } from '../../types/notification';
import { useNotificationsContext } from '../notificationsContext';

type TNotificationWithoutType = Omit<INotification, 'type'>;

interface IUseNotifications {
  notify(notification: INotification): void;
  error(notification: TNotificationWithoutType): void;
  success(notification: TNotificationWithoutType): void;
  warning(notification: TNotificationWithoutType): void;
}

export function useNotifications(): IUseNotifications {
  const { pushNotification } = useNotificationsContext();

  const notify = React.useCallback(
    (notification: INotification) => {
      pushNotification(notification);
    },
    [pushNotification],
  );

  const error = React.useCallback(
    (notification: TNotificationWithoutType) => {
      notify({ type: ENotificationType.Error, ...notification });
    },
    [notify],
  );

  const success = React.useCallback(
    (notification: TNotificationWithoutType) => {
      notify({ type: ENotificationType.Success, ...notification });
    },
    [notify],
  );

  const warning = React.useCallback(
    (notification: TNotificationWithoutType) => {
      notify({ type: ENotificationType.Warning, ...notification });
    },
    [notify],
  );

  return React.useMemo(() => ({ notify, error, success, warning }), [error, notify, success, warning]);
}
