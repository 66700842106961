import { IApplicationState } from '../../types/redux';
import { TTrackingUser, TUser } from '../../types/user';

export function selectUser(state: IApplicationState): TUser {
  return state.user;
}

export const getTrackingUser = (state: IApplicationState): TTrackingUser => {
  const { user } = state;

  if (user.isAuthenticated) {
    return {
      userId: user.userId,
    };
  }

  return {};
};
