import * as React from 'react';

export enum ENotificationType {
  Error = 'error',
  Light = 'light',
  Success = 'success',
  Warning = 'warning',
}

export interface INotification {
  type: ENotificationType;
  image?: React.ReactChild;
  heading?: string;
  message: React.ReactChild;
  timeout?: number;
}
