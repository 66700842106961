import * as React from 'react';

import { getTermsValue } from '../../../../../JsonQuery';
import { ExcludeWords } from '../../../components/filters/ExcludeWords';
import { useContext } from '../../../utils/useContext';

export const ExcludeWordsContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const excludeWords = getTermsValue('description_exclude')(jsonQuery);

  const handleChange = React.useCallback(
    (excludeWords: string[] | null) => {
      onChange({ action: 'setExcludeWords', arguments: [excludeWords] });
    },
    [onChange],
  );

  return <ExcludeWords value={excludeWords} onChange={handleChange} />;
};
