import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackClick } from './tracking';
import { dealTypeFromJsonQuery, FDealType, getTermsValue } from '../../../packages/JsonQuery';
import { EOfficeType } from '../../../packages/api-models/common/json_query';
import { BannerCommercialSearch } from '../../components/BannerCommercialSearch';
import { selectCommercialMeta } from '../../selectors/commercialMeta';
import { selectJsonQuery } from '../../selectors/jsonQuery';
import { useApplicationContext } from '../../utils/applicationContext';

export const BannerCommercialSearchContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const domain = config.get<string>('mobile-filters-frontend.domain') || 'cian.ru';
  const commercialMeta = useSelector(selectCommercialMeta);

  const jsonQuery = useSelector(selectJsonQuery);
  const regionId = React.useMemo(() => getTermsValue('region')(jsonQuery), [jsonQuery]);
  const dealType = React.useMemo(() => dealTypeFromJsonQuery(jsonQuery), [jsonQuery]);

  const offersCount = React.useMemo(() => commercialMeta.meta?.count ?? null, [commercialMeta]);
  const filtersLink = React.useMemo(() => {
    if (commercialMeta.meta) {
      const { subdomain, queryString } = commercialMeta.meta;

      return `https://${subdomain}.${domain}/nd/search/?${queryString}`;
    }

    const query = [
      'engine_version=2',
      `deal_type=${dealType === FDealType.Sale ? 'sale' : 'rent'}`,
      'offer_type=offices',
      `office_type[0]=${EOfficeType.Office}`,
      `office_type[1]=${EOfficeType.TradeArea}`,
      `office_type[2]=${EOfficeType.Warehouse}`,
      `office_type[3]=${EOfficeType.PublicCatering}`,
      `office_type[4]=${EOfficeType.FreeAppointmentObject}`,
      `office_type[5]=${EOfficeType.Manufacture}`,
      `office_type[6]=${EOfficeType.AutoService}`,
      `office_type[7]=${EOfficeType.Building}`,
      `office_type[8]=${EOfficeType.DomesticServices}`,
      regionId ? `region=${regionId[0]}` : null,
    ]
      .filter(Boolean)
      .join('&');

    return `/nd/search/?${query}`;
  }, [commercialMeta, dealType, domain, regionId]);

  const handleClick = React.useCallback(() => {
    trackClick();
  }, []);

  return <BannerCommercialSearch offersCount={offersCount} filtersLink={filtersLink} onClick={handleClick} />;
};
