import { useMemo } from 'react';

import { useUndergroundMapLocationId } from './useUndergroundMapLocationId';
import { useContext } from '../../../../utils/useContext';

export function useUndergroundMapEnabled(): boolean {
  const { config } = useContext();
  const locationId = useUndergroundMapLocationId();

  return useMemo(() => {
    const undergroundMapToggle = config.get<boolean>('newUndergroundMapEnabled') ?? false;

    return undergroundMapToggle && Boolean(locationId);
  }, [config, locationId]);
}
