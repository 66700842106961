import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { ESuburbanUtility, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface ISuburbanUtility {
  label: string;
  value: ESuburbanUtility;
}

const UTILITIES: ISuburbanUtility[] = [
  { label: 'Электричество', value: ESuburbanUtility.Electricity },
  { label: 'Газ', value: ESuburbanUtility.Gas },
  { label: 'Водоснабжение', value: ESuburbanUtility.Water },
  { label: 'Канализация', value: ESuburbanUtility.Drainage },
];

interface ISuburbanUtilitiesProps {
  value: NonEmptyArray<ESuburbanUtility> | null;
  onChange(value: NonEmptyArray<ESuburbanUtility> | null): void;
}

export const SuburbanUtilities: React.FC<ISuburbanUtilitiesProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_suburbanUtilities" label="Наличие коммуникаций">
      <CheckboxButtonGroup options={UTILITIES} value={value} onChange={onChange} />
    </Filter>
  );
};
