import { Button } from '@cian/ui-kit/button';
import * as React from 'react';

import * as styles from './Tags.css';
import { NonEmptyArray } from '../../../../../../../JsonQuery';
import { TTag } from '../../../../../types/geoTags';
import { Tags as TagsComponent, Tag } from '../../../../Tags';
import { UndergroundIcon } from '../../../../UndergroundIcon';

interface ITagsProps {
  regionId: NonEmptyArray<number> | null;
  tags: TTag[];
  onRemove(tag: TTag): void;
  onClear(): void;
}

const MAX_TAGS_TO_SHOW = 4;

export const Tags: React.FC<ITagsProps> = ({ regionId, tags, onRemove, onClear }) => {
  const containerRef = React.createRef<HTMLDivElement>();
  const [isMinimized, setIsMinimized] = React.useState(true);

  const handleMinimize = React.useCallback(() => {
    setIsMinimized(!isMinimized);

    if (!isMinimized) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [containerRef, isMinimized]);

  return (
    <>
      <div ref={containerRef} className={styles['container']}>
        <TagsComponent id="filter_geo_tags">
          {tags.slice(0, isMinimized ? MAX_TAGS_TO_SHOW : Infinity).map((t, i) => (
            <Tag key={`tag_${i}`} onRemove={() => onRemove(t)}>
              {t.type === 'underground' && !!t.color && (
                <div className={styles['icon']}>
                  <UndergroundIcon regionId={regionId || []} color={t.color} />
                </div>
              )}
              <span className={styles['text']}>{t.name}</span>
            </Tag>
          ))}
        </TagsComponent>
      </div>
      {tags.length > MAX_TAGS_TO_SHOW && (
        <div className={styles['buttons']}>
          <Button theme="fill_white_primary" onClick={handleMinimize}>
            {isMinimized ? 'Показать ещё' : 'Скрыть'}
          </Button>
          <Button onClick={onClear} theme="fill_white_primary">
            Очистить
          </Button>
        </div>
      )}
    </>
  );
};
