import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { FlatShare } from '../../../components/filters/FlatShare';
import { useContext } from '../../../utils/useContext';

export const FlatShareContainer: React.FC = () => {
  const { jsonQuery, onChange, config } = useContext();
  const flatShare = getTermValue('flat_share')(jsonQuery);
  const isSearchFlatShareEnabled = config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled');

  const handleChange = React.useCallback(
    (flatShareType: number | null) => {
      onChange({ action: 'setFlatShare', arguments: [flatShareType] });
    },
    [onChange],
  );

  if (isSearchFlatShareEnabled) {
    return <FlatShare value={flatShare} onChange={handleChange} />;
  }

  return null;
};
