import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EParkingType } from '../../../../../api-models/common/json_query';
import { IParking } from '../../../containers/filters/ParkingContainer';
import { Filter } from '../common/Filter';

interface IParkingProps {
  options: IParking[];
  value: NonEmptyArray<EParkingType> | null;
  onChange(value: NonEmptyArray<EParkingType> | null): void;
}

export const Parking: React.FC<IParkingProps> = ({ options, value, onChange }) => {
  return (
    <Filter id="filter_parking" label="Парковка">
      <CheckboxButtonGroup options={options} value={value} onChange={onChange} />
    </Filter>
  );
};
