import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { Switch } from '../../../../../Fields/shared/components/Switch';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EFloor } from '../../../constants/floors';
import { IFloor } from '../../../containers/filters/DailyrentFloorContainer';
import { Filter, FilterRow } from '../common/Filter';

interface IDailyrentFloorProps {
  floorsOptions: IFloor[];
  floorsValue: NonEmptyArray<EFloor> | null;
  hasElevator: boolean;
  onFloorsChange(value: NonEmptyArray<EFloor> | null): void;
  onHasElevatorChange(event: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}

export const DailyrentFloor = ({
  floorsOptions,
  floorsValue,
  hasElevator,
  onFloorsChange,
  onHasElevatorChange,
}: IDailyrentFloorProps) => {
  return (
    <Filter label="Этаж">
      <FilterRow>
        <CheckboxButtonGroup options={floorsOptions} value={floorsValue} onChange={onFloorsChange} spliced={false} />
      </FilterRow>
      <Switch label="Есть лифт" checked={hasElevator} onChange={onHasElevatorChange} />
    </Filter>
  );
};
