import * as React from 'react';

import { getTags } from './helpers';
import { NonEmptyArray } from '../../../../../../../Fields';
import { TModifier } from '../../../../../../../Fields/shared/types/modifier';
import { getTermsValue } from '../../../../../../../JsonQuery';
import { isPikPromoAvailable } from '../../../../../../../JsonQuery/utils/isPikPromoAvailable';
import { Tags } from '../../../../../components/filters/Geo/components/Tags';
import { TTag } from '../../../../../types/geoTags';
import { useContext } from '../../../../../utils/useContext';

export const TagsContainer: React.FC = () => {
  const { jsonQuery, geoTags, specialPromo, onChange } = useContext();
  const regionId = getTermsValue('region')(jsonQuery);
  const tags = React.useMemo(() => getTags(jsonQuery, geoTags, specialPromo), [jsonQuery, geoTags]);

  const handleRemove = React.useCallback(
    (tag: TTag) => {
      const modifiers: TModifier[] = [];

      switch (tag.type) {
        case 'district':
        case 'highway':
        case 'house':
        case 'location':
        case 'railway':
        case 'street':
        case 'underground':
          modifiers.push({
            action: 'removeGeo',
            arguments: [{ type: tag.type, id: tag.id }],
          });
          break;
        case 'village':
          modifiers.push({ action: 'setVillage', arguments: [null] });
          break;
        case 'developer': {
          modifiers.push({ action: 'setBuilders', arguments: [null] });

          if (isPikPromoAvailable(tag.id, regionId)) {
            modifiers.push({ action: 'setPikPromo', arguments: [null] });
          } else {
            modifiers.push({ action: 'setSpecialPromo', arguments: [null] });
          }

          break;
        }
        case 'distance':
          modifiers.push({
            action: 'removeGeo',
            arguments: [{ type: 'distance', center: tag.center, radius: tag.radius, name: tag.name }],
          });
          break;
        case 'polygon':
          modifiers.push({
            action: 'removeGeo',
            arguments: [{ type: 'polygon', coordinates: tag.coordinates, name: tag.name }],
          });
          break;
        case 'residentialComplex':
          modifiers.push({
            action: 'removeGeo',
            arguments: [{ type: 'newobject', id: tag.id }],
          });
          break;
        case 'regional_district_id':
          modifiers.push({
            action: 'removeGeo',
            arguments: [{ type: 'regional_district_id', id: tag.id }],
          });
          break;
      }

      if (modifiers.length > 0) {
        onChange(...(modifiers as NonEmptyArray<TModifier>));
      }
    },
    [onChange],
  );

  const handleClear = React.useCallback(() => {
    onChange({ action: 'clearGeo', arguments: [] });
  }, [onChange]);

  if (tags.length === 0) {
    return null;
  }

  return <Tags regionId={regionId} tags={tags} onRemove={handleRemove} onClear={handleClear} />;
};
