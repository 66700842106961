import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as styles from './Notifications.css';
import { INotification } from '../../types/notification';
import { Notification } from '../Notification';

interface INotificationsProps {
  notifications: INotification[];
  onNotificationClose(notification: INotification): void;
}

export const Notifications: React.FC<INotificationsProps> = ({ notifications, onNotificationClose }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted || notifications.length === 0) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles['container']}>
      {notifications.map((n, i) => (
        <Notification {...n} key={`notification_${i}`} onClose={() => onNotificationClose(n)} />
      ))}
    </div>,
    document.body,
  );
};
