import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import * as React from 'react';

import { TPromoSearchTypes } from '../../../../../shared/types/promoSearchTypes';
import { IRegionalDistrictsList } from '../../../../../shared/types/regionalDistricts';
import { NonEmptyArray, modifyJsonQuery } from '../../../../Fields';
import { TModifier } from '../../../../Fields/shared/types/modifier';
import { IRegionMeta } from '../../../../Fields/shared/types/regionMeta';
import { IRegion } from '../../../../Fields/shared/types/regions';
import { FDealType, FOfferType, isAvailable, TJsonQuery } from '../../../../JsonQuery';
import { IDatesModal } from '../../types/datesModal';
import { IGeoTags } from '../../types/geoTags';
import { TPlaceholders } from '../../types/placeholders';
import { ISpecialPromo } from '../../types/specialPromo';
import { IContext, context as Context } from '../../utils/context';

export interface IOnChangeParameters {
  appliedModifiers: NonEmptyArray<TModifier>;
  nextJsonQuery: TJsonQuery;
}

interface IProviderProps {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
  subdomain: string;
  currentYear: number;
  regions: IRegion[];
  geoTags: IGeoTags;
  jsonQuery: TJsonQuery;
  regionMeta: IRegionMeta;
  specialPromo?: ISpecialPromo | null;
  isVillageMortgageFilterEnabled: boolean;
  onChange(parameters: IOnChangeParameters): void;
  regionalDistricts: IRegionalDistrictsList;
  promoSearchTypes: TPromoSearchTypes;
  datesModal: IDatesModal;
  placeholders: TPlaceholders;
  isGoodPriceFilterEnabled: boolean;
  isMixedFiltersRedesign: boolean;
}

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({
  children,
  logger,
  config,
  httpApi,
  subdomain,
  currentYear,
  regions,
  geoTags,
  jsonQuery,
  regionMeta,
  specialPromo,
  isVillageMortgageFilterEnabled,
  regionalDistricts,
  promoSearchTypes,
  datesModal,
  placeholders,
  isGoodPriceFilterEnabled,
  isMixedFiltersRedesign,
  onChange,
}) => {
  const handleChange = React.useCallback(
    (...modifiers: NonEmptyArray<TModifier>) => {
      onChange({
        appliedModifiers: modifiers,
        nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
      });
    },
    [jsonQuery, onChange],
  );

  const areDailyrentFlatFiltersActive = isAvailable(FDealType.RentDaily, FOfferType.Flat)(jsonQuery);
  const areDailyrentRoomFiltersActive = isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed)(jsonQuery);
  const areDailyrentSuburbanFiltersActive = isAvailable(FDealType.RentDaily, FOfferType.House)(jsonQuery);

  const context = React.useMemo<IContext>(
    () => ({
      logger,
      config,
      httpApi,
      subdomain,
      currentYear,
      regions,
      geoTags,
      jsonQuery,
      specialPromo: specialPromo || null,
      regionMeta,
      isVillageMortgageFilterEnabled,
      onChange: handleChange,
      regionalDistricts,
      datesModal,
      areDailyrentFlatFiltersActive,
      areDailyrentRoomFiltersActive,
      areDailyrentSuburbanFiltersActive,
      promoSearchTypes,
      placeholders,
      isGoodPriceFilterEnabled,
      isMixedFiltersRedesign,
    }),
    [
      logger,
      config,
      httpApi,
      subdomain,
      currentYear,
      regions,
      geoTags,
      jsonQuery,
      specialPromo,
      regionMeta,
      isVillageMortgageFilterEnabled,
      handleChange,
      regionalDistricts,
      datesModal,
      areDailyrentFlatFiltersActive,
      areDailyrentRoomFiltersActive,
      areDailyrentSuburbanFiltersActive,
      promoSearchTypes,
      placeholders,
      isGoodPriceFilterEnabled,
      isMixedFiltersRedesign,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
