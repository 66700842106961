import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { SHRUG } from '../../../constants/shrug';
import { TFieldPlaceholders } from '../../../types/placeholders';
import { Filter } from '../common/Filter';

interface IHouseAreaProps {
  houseArea: IJsonQueryRangeValue | null;
  placeholders?: TFieldPlaceholders;
  onHouseAreaMinChange(value: number | null): void;
  onHouseAreaMaxChange(value: number | null): void;
}

export const HouseArea: React.FC<IHouseAreaProps> = ({
  houseArea,
  placeholders,
  onHouseAreaMinChange,
  onHouseAreaMaxChange,
}) => {
  const { gte: houseAreaMin = null, lte: houseAreaMax = null } = houseArea || {};

  const handleHouseAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onHouseAreaMinChange(gte);
  }, 300);

  const handleHouseAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onHouseAreaMaxChange(lte);
  }, 300);

  return (
    <Filter id="filter_houseArea" label={`Площадь дома${houseAreaMin === 0 && houseAreaMax === 0 ? ` ${SHRUG}` : ''}`}>
      <RangeInput
        min={0}
        max={99999}
        joined={false}
        postfix={'м\u00b2'}
        valueMin={houseAreaMin}
        valueMax={houseAreaMax}
        onChangeMin={handleHouseAreaMinChange}
        onChangeMax={handleHouseAreaMaxChange}
        {...placeholders}
      />
    </Filter>
  );
};
