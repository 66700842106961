import * as React from 'react';

import * as styles from './Filters.css';

interface IFiltersProps {
  header?: React.ReactChild;
  content: React.ReactChild;
  footer?: React.ReactChild;
}

export const Filters: React.FC<IFiltersProps> = ({ header, content, footer }) => {
  return (
    <div className={styles['container']}>
      {header}
      <div className={styles['content']}>{content}</div>
      {footer}
    </div>
  );
};
