import { Input } from '@cian/ui-kit/input';
import { InputAdornmentButton } from '@cian/ui-kit/inputAdornment';
import { UIHeading4 } from '@cian/ui-kit/typography';
import { IconActionClose12, IconMapLocation16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './RegionSelect.css';
import { renderLabel, useAvailableOptions } from './helpers';
import { IRegionSelectOptionsGroup } from './types';
import { Cell, CellGroup } from '../../../../../../../Fields/shared/components/CellGroup';

export interface IRegionSelectProps {
  loading: boolean;
  defaultOptions: IRegionSelectOptionsGroup[] | null;
  options: IRegionSelectOptionsGroup[];
  onQueryChange?(query: string): void;
  onSelect(regionId: number[]): void;
  onGeolocationClick(): void;
}

export const RegionSelect: React.FC<IRegionSelectProps> = ({
  loading,
  defaultOptions,
  options,
  onQueryChange,
  onSelect,
  onGeolocationClick,
}) => {
  const [query, setQuery] = React.useState('');
  const availableOptionGroups = useAvailableOptions(defaultOptions, options, query);

  const handleQueryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setQuery(value);

      if (onQueryChange) {
        onQueryChange(value);
      }
    },
    [onQueryChange],
  );

  const handleQueryClear = React.useCallback(() => {
    setQuery('');

    if (onQueryChange) {
      onQueryChange('');
    }
  }, [onQueryChange]);

  return (
    <div className={styles['container']}>
      <div className={styles['search']}>
        <Input
          autoFocus
          placeholder="Поиск"
          loading={loading}
          value={query}
          rightAdornment={
            query ? (
              <InputAdornmentButton onClick={handleQueryClear}>
                <IconActionClose12 color="icon-secondary-default" />
              </InputAdornmentButton>
            ) : (
              <InputAdornmentButton onClick={onGeolocationClick}>
                <IconMapLocation16 color="icon-main-default" />
              </InputAdornmentButton>
            )
          }
          onChange={handleQueryChange}
        />
      </div>
      <div className={styles['content']}>
        {availableOptionGroups.map((optionsGroup, optionsGroupIndex) => {
          return (
            <React.Fragment key={`optionsGroup_${optionsGroupIndex}`}>
              {optionsGroup.label && (
                <div className={styles['label']}>
                  <UIHeading4>{optionsGroup.label}</UIHeading4>
                </div>
              )}
              <CellGroup>
                {optionsGroup.options.map((option, optionIndex) => (
                  <Cell key={`option_${optionsGroupIndex}_${optionIndex}`} onClick={() => onSelect(option.value)}>
                    {renderLabel(option)}
                  </Cell>
                ))}
              </CellGroup>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
