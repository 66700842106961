import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EFacilityType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IBathType {
  label: string;
  value: EFacilityType;
}

const BATH_TYPES: IBathType[] = [
  { label: 'Ванна', value: EFacilityType.Bath },
  { label: 'Душевая кабина', value: EFacilityType.Shower },
];

interface IBathTypeProps {
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const BathType: React.FC<IBathTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_bathType" label="Ванна/Душ">
      <CheckboxButtonGroup options={BATH_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
