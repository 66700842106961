import * as React from 'react';

import { Switch } from '../../../../../Fields/shared/components/Switch';
import { Filter, FilterRow } from '../common/Filter';

export interface IOnlineBookingProps {
  isCompact?: boolean;
  isOnlineBooking: boolean;
  onIsOnlineBookingChange(value: boolean): void;
}

export const OnlineBooking: React.FC<IOnlineBookingProps> = ({ isOnlineBooking, onIsOnlineBookingChange }) => {
  return (
    <Filter id="filter_onlineBooking" compact>
      <FilterRow>
        <Switch
          label="Мгновенное бронирование"
          caption="Бронируйте без переговоров с владельцем"
          checked={isOnlineBooking}
          onChange={(event, value) => onIsOnlineBookingChange(Boolean(value))}
        />
      </FilterRow>
    </Filter>
  );
};
