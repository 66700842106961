import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { FOfferType } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IFlatTypeOption {
  label: string;
  value: FOfferType;
}

const FLAT_TYPE_OPTIONS: IFlatTypeOption[] = [
  { label: 'Все', value: FOfferType.Flat },
  { label: 'Новостройка', value: FOfferType.FlatNew },
  { label: 'Вторичка', value: FOfferType.FlatOld },
];

interface IFlatTypeProps {
  value: FOfferType;
  onChange(value: FOfferType): void;
}

export const FlatType: React.FC<IFlatTypeProps> = ({ value, onChange }) => {
  return (
    <Filter compact>
      <RadioButtonGroup options={FLAT_TYPE_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
