import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { ESuburbanWCType } from '../../../../../api-models/common/json_query';
import { SuburbanBathroom } from '../../../components/filters/SuburbanBathroom';
import { useContext } from '../../../utils/useContext';

export const SuburbanBathroomContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const suburbanBathroomType = getTermValue('wc_site')(jsonQuery);

  const handleChange = React.useCallback(
    (suburbanBathroomType: ESuburbanWCType | null) => {
      onChange({ action: 'setSuburbanBathroomType', arguments: [suburbanBathroomType] });
    },
    [onChange],
  );

  return <SuburbanBathroom value={suburbanBathroomType} onChange={handleChange} />;
};
