import { IconFunctionalCalendar16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Dates.css';
import { Filter } from '../common/Filter';

export interface IDatesProps {
  value: string;
  onClick(): void;
}

export const Dates: React.FC<IDatesProps> = props => {
  const { value, onClick } = props;

  return (
    <Filter id="filter_dates" label="Даты проживания">
      <button className={styles['button']} onClick={onClick}>
        <span className={styles['icon_wrapper']}>
          <IconFunctionalCalendar16 display="inline-block" color="icon-main-default" />
        </span>

        <span className={classNames(styles['button_text'], !value && styles['button_text_empty'])}>
          {value || 'Выберите даты'}
        </span>
      </button>
    </Filter>
  );
};
