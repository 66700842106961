import { TTag } from './tag';

export enum EGeoTagsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IGeoTags {
  status: EGeoTagsStatus;
  items: TTag[] | null;
}
