import { useSelector } from 'react-redux';

import { ERentTime, getDateRangeValue, getTermValue, TJsonQuery } from '../../../../packages/JsonQuery';
import { selectJsonQuery } from '../../../selectors/jsonQuery';
import { IApplicationState } from '../../../types/redux';
import { useApplicationContext } from '../../../utils/applicationContext';

// Временное решение переходного периода, пока даты не обязательны
export function useNeedSkipDatesModalCookie() {
  const { config } = useApplicationContext();
  const isDatesModalAutoOpenRSEnabled = config.get<boolean>('Dailyrent.DatesAutoOpen.Enabled');
  const jsonQuery = useSelector<IApplicationState, TJsonQuery>(selectJsonQuery);
  const { gte, lt } = getDateRangeValue('dates')(jsonQuery) || {};
  const hasDatesInFilters = Boolean(gte && lt);
  const rentalPeriod = getTermValue('for_day')(jsonQuery);
  const isDailyRent = rentalPeriod === ERentTime.Daily;

  return isDatesModalAutoOpenRSEnabled && isDailyRent && !hasDatesInFilters;
}
