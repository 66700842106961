import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { EDecorationType } from '../../../../../JsonQuery/types/jsonQuery/jsonQueryDecorationType';
import { Filter } from '../common/Filter';

interface IDecorations {
  label: string;
  value: EDecorationType;
}

const DECORATIONS: IDecorations[] = [
  { label: 'Чистовая с мебелью', value: EDecorationType.FineWithFurniture },
  { label: 'Чистовая', value: EDecorationType.Fine },
  { label: 'Предчистовая', value: EDecorationType.PreFine },
  { label: 'Без отделки', value: EDecorationType.Without },
];

interface IFlatRoomsProps {
  value: NonEmptyArray<EDecorationType> | null;
  onChange(value: NonEmptyArray<EDecorationType> | null): void;
}

export const DecorationsList: React.FC<IFlatRoomsProps> = ({ value, onChange }) => {
  return (
    <Filter dataName="filter_decorationsList" id="filter_decorationsList" label="Отделка">
      <CheckboxButtonGroup options={DECORATIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
