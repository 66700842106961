import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './VillageMortgage.css';
import { DESCRIPTION, LABEL } from './constants';
import { Switch } from '../../../../../Fields/shared/components/Switch';
import { Filter } from '../common/Filter';

interface IVillageMortgageProps {
  checked: boolean;
  onChange(value: boolean): void;
}

export const VillageMortgage: React.FC<IVillageMortgageProps> = ({ checked, onChange }) => {
  return (
    <Filter id="filter_villageMortgage" dataName="filter_villageMortgage">
      <Switch label={LABEL} checked={checked} onChange={(_, value) => onChange(value)} />
      <div className={styles['description']}>
        <UIText2 color="gray60_100">{DESCRIPTION}</UIText2>
      </div>
    </Filter>
  );
};
