import * as React from 'react';

import { getCompletionYearOptions } from './helpers';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { ECompletionYearType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface ICompletionYearProps {
  yearOffset: number;
  value: NonEmptyArray<ECompletionYearType> | null;
  onChange(value: NonEmptyArray<ECompletionYearType> | null): void;
}

export const CompletionYear: React.FC<ICompletionYearProps> = ({ yearOffset, value, onChange }) => {
  const completionOptions = getCompletionYearOptions(yearOffset);

  return (
    <Filter id="filter_completionYear" label="Год сдачи ЖК">
      <CheckboxButtonGroup options={completionOptions} value={value} onChange={onChange} />
    </Filter>
  );
};
