import * as React from 'react';

import { getCatalogSelectedOptions, getCatalogSelectOptions } from './helpers';
import { CellEntry } from '../../../../../../../Fields/shared/components/CellGroup';
import { TModifier } from '../../../../../../../Fields/shared/types/modifier';
import { IUndergroundsAdditinalGrouping } from '../../../../../../../Fields/shared/types/underground';
import { abbrLowercaseFirstLetter, capitalizeFirstLetter } from '../../../../../../../Fields/shared/utils/string';
import { getGeoValue, getTermsValue, NonEmptyArray, offerTypeFromJsonQuery } from '../../../../../../../JsonQuery';
import {
  IJsonQueryDistrict,
  IJsonQueryHighway,
  IJsonQueryUnderground,
} from '../../../../../../../api-models/common/json_query';
import { CatalogSelectModal, ETabType, TSelectedOptions } from '../../../../../components/filters/Geo';
import { useContext } from '../../../../../utils/useContext';
import { useUndergroundMapEnabled } from '../../hooks/useUndergroundMapEnabled';

export const CatalogSelectContainer: React.FC = () => {
  const {
    logger,
    config,
    jsonQuery,
    regionMeta: { undergrounds, districts, highways },
    onChange,
  } = useContext();
  const isUndergroundMapEnabled = useUndergroundMapEnabled();
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const regionId = getTermsValue('region')(jsonQuery);
  const geo = getGeoValue()(jsonQuery);
  const undergroundsAdditionalGroupings =
    config.get<IUndergroundsAdditinalGrouping[]>('filters.undergrounds.additionalGroupings') || null;

  const selectedUndergrounds: NonEmptyArray<number> | null = React.useMemo(() => {
    const undergrounds = geo?.filter((g): g is IJsonQueryUnderground => g.type === 'underground').map(g => g.id);

    return undergrounds && undergrounds.length > 0 ? (undergrounds as NonEmptyArray<number>) : null;
  }, [geo]);
  const selectedDistricts: NonEmptyArray<number> | null = React.useMemo(() => {
    const districts = geo?.filter((g): g is IJsonQueryDistrict => g.type === 'district').map(g => g.id);

    return districts && districts.length > 0 ? (districts as NonEmptyArray<number>) : null;
  }, [geo]);
  const selectedHighways: NonEmptyArray<number> | null = React.useMemo(() => {
    const highways = geo?.filter((g): g is IJsonQueryHighway => g.type === 'highway').map(g => g.id);

    return highways && highways.length > 0 ? (highways as NonEmptyArray<number>) : null;
  }, [geo]);

  const catalogSelectOptions = React.useMemo(
    () =>
      getCatalogSelectOptions({
        offerType,
        regionId,
        undergrounds: isUndergroundMapEnabled ? null : undergrounds,
        undergroundsAdditionalGroupings,
        districts,
        highways,
      }),
    [offerType, regionId, isUndergroundMapEnabled, undergrounds, undergroundsAdditionalGroupings, districts, highways],
  );
  const catalogSelectedOptions = React.useMemo(
    () => getCatalogSelectedOptions({ selectedUndergrounds, selectedDistricts, selectedHighways }),
    [selectedUndergrounds, selectedDistricts, selectedHighways],
  );
  const catalogSelectTitle = React.useMemo(
    () => capitalizeFirstLetter(catalogSelectOptions.map(o => abbrLowercaseFirstLetter(o.label)).join(', ')),
    [catalogSelectOptions],
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSelect = React.useCallback(
    (options: TSelectedOptions) => {
      const modifiers: TModifier[] = [];

      if (options[ETabType.Undergrounds]) {
        modifiers.push({
          action: 'setUndergrounds',
          arguments: [
            ...(options[ETabType.Undergrounds].map<IJsonQueryUnderground>(o => ({
              type: 'underground',
              id: o,
            })) as NonEmptyArray<IJsonQueryUnderground>),
          ],
        });
      }

      if (options[ETabType.Districts]) {
        modifiers.push({
          action: 'setDistricts',
          arguments: [
            ...(options[ETabType.Districts].map<IJsonQueryDistrict>(o => ({
              type: 'district',
              id: o,
            })) as NonEmptyArray<IJsonQueryDistrict>),
          ],
        });
      }

      if (options[ETabType.Highways]) {
        modifiers.push({
          action: 'setHighways',
          arguments: [
            ...(options[ETabType.Highways].map<IJsonQueryHighway>(o => ({
              type: 'highway',
              id: o,
            })) as NonEmptyArray<IJsonQueryHighway>),
          ],
        });
      }

      if (modifiers.length > 0) {
        onChange(...(modifiers as NonEmptyArray<TModifier>));
      }

      setIsOpen(false);
    },
    [onChange],
  );

  if (catalogSelectOptions.length < 1) {
    return null;
  }

  return (
    <>
      <CellEntry icon={catalogSelectOptions[0].icon} onClick={() => setIsOpen(true)}>
        {catalogSelectTitle}
      </CellEntry>
      <CatalogSelectModal
        logger={logger}
        title={catalogSelectTitle}
        open={isOpen}
        options={catalogSelectOptions}
        selected={catalogSelectedOptions}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </>
  );
};
