import * as React from 'react';

import { getTermValue } from '../../../../../JsonQuery';
import { VillageMortgage } from '../../../components/filters/VillageMortgage';
import { useContext } from '../../../utils/useContext';

export const VillageMortgageContainer = () => {
  const { jsonQuery, onChange } = useContext();

  const villageMortgageAllowed = getTermValue('village_mortgage_allowed')(jsonQuery);

  const handleChange = React.useCallback(
    (value: boolean) => {
      onChange({ action: 'setVillageMortgageAllowed', arguments: [value || null] });
    },
    [onChange],
  );

  return <VillageMortgage checked={!!villageMortgageAllowed} onChange={handleChange} />;
};
