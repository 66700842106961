import { useMemo } from 'react';

import { IUndergroundJSONDataPath } from '../../../../../../Fields/shared/types/underground';
import { useContext } from '../../../../utils/useContext';
import { getRegionIdForMetroMap } from '../utils/getRegionIdForMetroMap';

export function useUndergroundMapLocationId(): number | undefined {
  const { config, regionMeta } = useContext();
  const regionId = getRegionIdForMetroMap(regionMeta);

  return useMemo(() => {
    const undergroundMapData = config.get<IUndergroundJSONDataPath>('undergroundMapDataV2') ?? {};
    const undergroundMapRegions = Object.keys(undergroundMapData).map(id => Number(id));

    if (!undergroundMapRegions.includes(regionId)) {
      return;
    }

    return regionId;
  }, [config, regionId]);
}
