import * as React from 'react';

import { IStationData } from '../../../../../../../Fields/shared/types/underground';
import { getGeoValue, NonEmptyArray } from '../../../../../../../JsonQuery';
import { IJsonQueryUnderground } from '../../../../../../../api-models/common/json_query';
import { UndergroundMapModal } from '../../../../../components/filters/Geo/components/UndergroundMap';
import { fetchUndergroundMapData } from '../../../../../services/fetchUndergroundMapData/fetchUndergroundMapData';
import { useContext } from '../../../../../utils/useContext';

import type { IUndergroundMapDataV2 } from '@cian/underground-map-widget';

interface IUndergroundMapModalContainerProps {
  locationId: number;
  open: boolean;
  onClose(): void;
}

export function UndergroundMapModalContainer({ locationId, open, onClose }: IUndergroundMapModalContainerProps) {
  const { logger, jsonQuery, config, httpApi, onChange } = useContext();
  const geo = getGeoValue()(jsonQuery);
  const [mapData, setMapData] = React.useState<IUndergroundMapDataV2 | null>(null);

  const selectedUndergrounds = React.useMemo<number[]>(
    () => geo?.filter((g): g is IJsonQueryUnderground => g.type === 'underground').map(g => g.id) || [],
    [geo],
  );

  const [selectedStations, setSelectedStations] = React.useState(selectedUndergrounds);

  const handleAddStations = React.useCallback((stations: IStationData[]) => {
    const stationIds: number[] = stations.map(s => s.id);
    setSelectedStations(stations => stations.concat(stationIds));
  }, []);

  const handleRemoveStations = React.useCallback((stations: IStationData[]) => {
    const stationIds = stations.map(s => s.id);
    setSelectedStations(stations => stations.filter(id => !stationIds.includes(id)));
  }, []);

  const handleReset = React.useCallback(() => {
    setSelectedStations([]);
  }, []);

  const handleSelect = React.useCallback(
    (ids: number[]) => {
      onChange({
        action: 'setUndergrounds',
        arguments: [
          ...(ids.map<IJsonQueryUnderground>(id => ({
            type: 'underground',
            id,
          })) as NonEmptyArray<IJsonQueryUnderground>),
        ],
      });
    },
    [onChange],
  );

  const handleApply = React.useCallback(() => {
    handleSelect(selectedStations);
    onClose();
  }, [handleSelect, onClose, selectedStations]);

  const requestUndergroundMapData = React.useCallback(async () => {
    const data = await fetchUndergroundMapData({ config, httpApi, logger }, { locationId });
    setMapData(data);
  }, [locationId, config, httpApi, logger]);

  React.useEffect(() => {
    if (open) {
      requestUndergroundMapData();
    }
  }, [open, requestUndergroundMapData]);

  return (
    <UndergroundMapModal
      logger={logger}
      title="Метро"
      open={open}
      selectedStations={selectedStations}
      onRemove={handleRemoveStations}
      onAdd={handleAddStations}
      onReset={handleReset}
      onApply={handleApply}
      data={mapData}
      onClose={onClose}
    />
  );
}
