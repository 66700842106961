import { DAILYRENT_FLAT_ROOM_TYPES, DAILYRENT_ROOM_ROOM_TYPES, ROOM_TYPES } from './const';

interface IGetRoomTypesArgs {
  areDailyrentFlatFiltersActive: boolean;
  areDailyrentRoomFiltersActive: boolean;
}

export const getRoomTypes = ({ areDailyrentFlatFiltersActive, areDailyrentRoomFiltersActive }: IGetRoomTypesArgs) => {
  if (areDailyrentFlatFiltersActive) {
    return DAILYRENT_FLAT_ROOM_TYPES;
  } else if (areDailyrentRoomFiltersActive) {
    return DAILYRENT_ROOM_ROOM_TYPES;
  }

  return ROOM_TYPES;
};
