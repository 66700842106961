import * as React from 'react';

import { OfferTypeSelect, IOfferTypeSelectOptionsGroup } from '../../../../../Fields/shared/components/OfferTypeSelect';
import { FOfferType } from '../../../../../JsonQuery';
import { ModalWindow } from '../../../../../ModalWindow';
import { ActionButtons, HeaderBackable } from '../../ModalWindow';

export interface IOfferTypeSelectModalProps {
  banner?: React.ReactNode | null;
  open: boolean;
  options: IOfferTypeSelectOptionsGroup[];
  selected: FOfferType[];
  onClose(): void;
  onSelect(value: FOfferType[]): void;
}

export const OfferTypeSelectModal: React.FC<IOfferTypeSelectModalProps> = ({
  banner,
  open,
  onClose,
  onSelect,
  options,
  selected: initialSelected,
}) => {
  const [selected, setSelected] = React.useState(initialSelected);

  React.useEffect(() => {
    if (open) {
      setSelected(initialSelected);
    }
  }, [initialSelected, open]);

  const handleApply = React.useCallback(() => onSelect(selected), [selected, onSelect]);

  return (
    <ModalWindow
      header={<HeaderBackable title="Тип недвижимости" onBack={onClose} />}
      footer={<ActionButtons onApply={handleApply} />}
      open={open}
      onClose={onClose}
      portal
    >
      {banner}
      <OfferTypeSelect selected={selected} onChange={setSelected} options={options} />
    </ModalWindow>
  );
};
