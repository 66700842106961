import { IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './Tag.css';

export interface ITagProps {
  id?: string;
  onRemove(): void;
}

export const Tag: React.FC<React.PropsWithChildren<ITagProps>> = ({ id, children, onRemove }) => {
  const handleRemove = React.useCallback(() => {
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  return (
    <li id={id} className={styles['container']} aria-labelledby={`${id}_label`}>
      <span id={`${id}_label`}>{children}</span>
      <button
        className={styles['button']}
        title="Удалить тег"
        aria-controls={id}
        aria-describedby={`${id}_label`}
        aria-keyshortcuts="Delete"
        aria-label="Удалить тег"
        onClick={handleRemove}
      >
        <IconActionClose12 display="block" />
      </button>
    </li>
  );
};
