import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { EJsonQueryListingType } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IListingType {
  label: string;
  value: EJsonQueryListingType | null;
}

const LISTING_TYPES: IListingType[] = [
  { label: 'Квартиры', value: EJsonQueryListingType.Offer },
  { label: 'ЖК', value: EJsonQueryListingType.Newbuilding },
];

interface IResidentialComplexesListingTypeProps {
  listingType: EJsonQueryListingType | null;
  onListingTypeChange(value: EJsonQueryListingType | null): void;
}

export const ResidentialComplexesListingType: React.FC<IResidentialComplexesListingTypeProps> = ({
  listingType,
  onListingTypeChange,
}) => {
  const handleListingTypeChange = React.useCallback(
    (value: EJsonQueryListingType | null) => {
      onListingTypeChange(value);
    },
    [onListingTypeChange],
  );

  return (
    <Filter id="filter_listingType" dataName="filter_listingType" label="Показать каталог">
      <RadioButtonGroup
        options={LISTING_TYPES}
        value={listingType || EJsonQueryListingType.Offer}
        onChange={handleListingTypeChange}
      />
    </Filter>
  );
};
