import * as R from 'ramda';

import { IDistrict } from '../../../../../../../../Fields/shared/types/districts';
import { ETabType, ICatalogSelectOptionsGroup } from '../../../../../../components/filters/Geo';

export function getDistrictsOptions(districts: IDistrict[]): ICatalogSelectOptionsGroup[] {
  const options: ICatalogSelectOptionsGroup[] = [];

  const sortedDistricts = R.flatten(districts.map(d => d.childs || d));
  sortedDistricts.sort((a, b) => a.name.localeCompare(b.name));

  options.push({
    options: sortedDistricts.map(d => ({
      type: ETabType.Districts,
      label: d.name,
      value: [d.id],
    })),
  });

  return options;
}
