import { makeContextModule } from '@cian/react-utils';

import { IContext } from '../../types/context';

export const {
  Context: NotificationsContext,
  useContext: useNotificationsContext,
  withContext: withNotificationsContext,
} = makeContextModule<IContext>({
  contextName: 'NotificationsContext',
});
