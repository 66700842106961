import { Button } from '@cian/ui-kit/button';
import { IconActionMap16 } from '@cian/ui-kit-design-tokens/icons';
import { numberToPrettyString, plural } from '@cian/utils';
import * as React from 'react';

import * as styles from './Footer.css';
import { EOrigin } from '../../../../types/applicationContext';
import { ESearchMetaStatus } from '../../../../types/searchMeta';

interface IFooterProps {
  origin: EOrigin | null;
  /**
   * @deprecated Для ужасного фильтра "Скоро в продаже". Не использовать.
   */
  upcomingSale?: boolean;
  status: ESearchMetaStatus;
  offersCount: number;
  showOnlyMapButton: boolean;
  onSearchClick(): void;
  onSearchOnMapClick(): void;
}

export const Footer: React.FC<IFooterProps> = ({
  origin,
  upcomingSale,
  status,
  offersCount,
  showOnlyMapButton,
  onSearchClick,
  onSearchOnMapClick,
}) => {
  const isLoading = React.useMemo(() => status === ESearchMetaStatus.Loading, [status]);
  const isFailed = React.useMemo(() => status === ESearchMetaStatus.Failed, [status]);

  const offersButtonText = React.useMemo(
    () =>
      !isFailed
        ? `${numberToPrettyString(offersCount)} ${plural(offersCount, ['объявление', 'объявления', 'объявлений'])}`
        : null,
    [isFailed, offersCount],
  );
  const buttonDisplayText = showOnlyMapButton ? null : offersButtonText;

  const handleMapClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (onSearchOnMapClick) {
        onSearchOnMapClick();
      }
    },
    [onSearchOnMapClick],
  );

  const handleSearchClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      if (onSearchClick) {
        onSearchClick();
      }
    },
    [onSearchClick],
  );

  if (upcomingSale) {
    return (
      <div className={styles['container']}>
        <Button theme="fill_primary" fullWidth loading={isLoading} onClick={handleSearchClick}>
          Показать ЖК
        </Button>
      </div>
    );
  }

  if (origin === EOrigin.Map || showOnlyMapButton) {
    return (
      <div className={styles['container']}>
        <Button
          data-name="map-search-button"
          theme="fill_primary"
          fullWidth
          loading={isLoading}
          onClick={handleMapClick}
          size="L"
        >
          Показать {buttonDisplayText || 'объявления'}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      <div className={styles['action']}>
        <Button
          data-name="map-search-button"
          theme="fill_secondary"
          beforeIcon={<IconActionMap16 color="current_color" />}
          disabled={isLoading}
          onClick={handleMapClick}
          size="L"
        >
          На карте
        </Button>
      </div>
      <div className={styles['action']}>
        <Button
          data-name="list-search-button"
          theme="fill_primary"
          fullWidth
          loading={isLoading}
          onClick={handleSearchClick}
          size="L"
        >
          {buttonDisplayText || 'Показать объявления'}
        </Button>
      </div>
    </div>
  );
};
