import * as React from 'react';

import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface ICeilingHeight {
  label: string;
  value: number;
}

const CEILING_HEIGHTS: ICeilingHeight[] = [
  { label: 'От 2,5 м', value: 2.5 },
  { label: 'От 2,7 м', value: 2.7 },
  { label: 'От 3 м', value: 3 },
  { label: 'От 3,5 м', value: 3.5 },
  { label: 'От 4 м', value: 4 },
];

interface ICeilingHeightProps {
  value: number | null;
  onChange(value: number): void;
}

export const CeilingHeight: React.FC<ICeilingHeightProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_ceilingHeight" label="Высота потолков">
      <RadioButtonGroupUnselectable options={CEILING_HEIGHTS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
