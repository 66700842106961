import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { ERepair } from '../../../../../api-models/common/json_query';
import { Filter } from '../common/Filter';

interface IRepair {
  label: string;
  value: ERepair;
}

const REPAIRS: IRepair[] = [
  { label: 'Без ремонта', value: ERepair.Without },
  { label: 'Косметический', value: ERepair.Cosmetic },
  { label: 'Евроремонт', value: ERepair.Euro },
  { label: 'Дизайнерский', value: ERepair.Design },
];

interface IRepairProps {
  value: NonEmptyArray<ERepair> | null;
  onChange(value: NonEmptyArray<ERepair> | null): void;
}

export const Repair: React.FC<IRepairProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_repair" label="Ремонт">
      <CheckboxButtonGroup options={REPAIRS} value={value} onChange={onChange} />
    </Filter>
  );
};
