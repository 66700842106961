import * as React from 'react';

import { getRangeValue } from '../../../../../JsonQuery';
import { LandArea } from '../../../components/filters/LandArea';
import { useContext } from '../../../utils/useContext';

export const LandAreaContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const landArea = getRangeValue('site')(jsonQuery);

  const handleLandAreaMinChange = React.useCallback(
    (gte: number | null) => {
      onChange({ action: 'setLandAreaMin', arguments: [gte] });
    },
    [onChange],
  );

  const handleLandAreaMaxChange = React.useCallback(
    (lte: number | null) => {
      onChange({ action: 'setLandAreaMax', arguments: [lte] });
    },
    [onChange],
  );

  return (
    <LandArea
      landArea={landArea}
      onLandAreaMinChange={handleLandAreaMinChange}
      onLandAreaMaxChange={handleLandAreaMaxChange}
    />
  );
};
