import * as React from 'react';

import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EHeatingType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IHeatingTypeOption {
  label: string;
  value: EHeatingType;
}

const HEATING_TYPE_OPTIONS: IHeatingTypeOption[] = [
  { label: 'Центральное газовое', value: EHeatingType.Gas },
  { label: 'Угольное', value: EHeatingType.Coal },
  { label: 'Печь', value: EHeatingType.Furnace },
  { label: 'Камин', value: EHeatingType.Fireplace },
  { label: 'Электрическое', value: EHeatingType.Electric },
  { label: 'Автономное газовое', value: EHeatingType.AutonomousGas },
  { label: 'Дизельное', value: EHeatingType.Diesel },
  { label: 'Твердотопливный котел', value: EHeatingType.SolidFuelBoiler },
  { label: 'Без отопления', value: EHeatingType.None },
];

interface IHeatingTypeProps {
  value: NonEmptyArray<EHeatingType> | null;
  onChange(value: NonEmptyArray<EHeatingType> | null): void;
}

export const HeatingType: React.FC<IHeatingTypeProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_heatingType" label="Тип отопления">
      <CheckboxButtonGroup options={HEATING_TYPE_OPTIONS} value={value} onChange={onChange} />
    </Filter>
  );
};
