import { pathOr } from 'ramda';
import * as React from 'react';

import { NonEmptyArray } from '../../../../../Fields';
import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { EMetroTravelType } from '../../../../../api-models/common/json_query';
import { UndergroundTime } from '../../../components/filters/UndergroundTime';
import { useContext } from '../../../utils/useContext';

export const UndergroundTimeContainer: React.FC = () => {
  const { onChange, jsonQuery } = useContext();

  const travelType = pathOr<EMetroTravelType | null>(null, ['only_foot', 'value'], jsonQuery);
  const travelTime = pathOr<number | null>(null, ['foot_min', 'value', 'lte'], jsonQuery);

  const handleChangeTravelTime = React.useCallback(
    (time: number | null) => {
      const modifiers: NonEmptyArray<TModifier> = [{ action: 'setUndergroundTravelTime', arguments: [time] }];

      if (time !== null) {
        if (!travelType) {
          modifiers.push({ action: 'setUndergroundTravelType', arguments: [EMetroTravelType.MapFoot] });
        }
      } else {
        if (travelType === EMetroTravelType.MapFoot) {
          modifiers.push({ action: 'setUndergroundTravelType', arguments: [null] });
        }
      }

      onChange(...modifiers);
    },
    [onChange, travelType],
  );

  const handleChangeTravelType = React.useCallback(
    (type: EMetroTravelType | null) => {
      if (type === EMetroTravelType.MapFoot && travelTime === null) {
        onChange({ action: 'setUndergroundTravelType', arguments: [null] });

        return;
      }

      onChange({ action: 'setUndergroundTravelType', arguments: [type] });
    },
    [onChange, travelTime],
  );

  return (
    <UndergroundTime
      travelType={travelType}
      travelTime={travelTime}
      onTravelTimeChange={handleChangeTravelTime}
      onTravelTypeChange={handleChangeTravelType}
    />
  );
};
