import { MultiSelect } from '@cian/ui-kit/select';
import { ArticleHeading5, ArticleParagraph2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import { ELandStatusType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface ILandStatusOption {
  label: string;
  value: ELandStatusType;
}

const LAND_STATUS_OPTIONS: ILandStatusOption[] = [
  { label: 'ИЖС', value: ELandStatusType.IndividualHousingConstruction },
  { label: 'Садоводство', value: ELandStatusType.Gardening },
  { label: 'ДНП', value: ELandStatusType.CountryhouseNonCommercialPartnership },
  { label: 'Личное подсобное хозяйство', value: ELandStatusType.PrivateFarm },
  { label: 'Фермерское хозяйство', value: ELandStatusType.Farm },
  { label: 'Земля промназначения', value: ELandStatusType.IndustrialLand },
];

interface ILandStatusProps {
  value: NonEmptyArray<ELandStatusType> | null;
  onChange(value: NonEmptyArray<ELandStatusType> | null): void;
}

export const LandStatus: React.FC<ILandStatusProps> = ({ value, onChange }) => {
  const handleChange = React.useCallback(
    (landStatus: ELandStatusType[]) => {
      onChange(landStatus.length > 0 ? (landStatus as NonEmptyArray<ELandStatusType>) : null);
    },
    [onChange],
  );

  return (
    <Filter
      dataName="filter_landStatus"
      id="filter_landStatus"
      label="Статус участка"
      infoContent={
        <>
          <ArticleHeading5>Индивидуальное жилищное строительство (ИЖС)</ArticleHeading5>
          <ArticleParagraph2>
            Вся инфраструктура рядом, легко получить адрес, прописаться или зарегистрироваться
          </ArticleParagraph2>
          <br />
          <ArticleHeading5>Дачное некоммерческое партнерство (ДНП)</ArticleHeading5>
          <ArticleParagraph2>
            С домом, садом или огородом; обычно в населённом пункте, за его пределами могут быть проблемы с пропиской
          </ArticleParagraph2>
          <br />
          <ArticleHeading5>Садоводство</ArticleHeading5>
          <ArticleParagraph2>
            Обычно есть доступ к инфраструктуре; можно построить дом, но могут быть проблемы с пропиской
          </ArticleParagraph2>
          <br />
          <ArticleHeading5>Личное подсобное хозяйство</ArticleHeading5>
          <ArticleParagraph2>
            Для разведения животных/птиц и сельхозкультур; если участок в населённом пункте, можно построить дом
          </ArticleParagraph2>
          <br />
          <ArticleHeading5>Фермерское хозяйство</ArticleHeading5>
          <ArticleParagraph2>
            Для разведения животных/птиц и сельхозкультур на продажу; можно строить помещения для хранения и обработки
            продукции, но не дом
          </ArticleParagraph2>
        </>
      }
    >
      <MultiSelect
        options={LAND_STATUS_OPTIONS}
        value={value}
        onChange={handleChange}
        placeholder="Любой"
        title="Статус участка"
        allOption="Все статусы"
      />
    </Filter>
  );
};
