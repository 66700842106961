import { fetchExtensionRegionCached } from './fetchExtensionRegion';
import { isSuburban, offerTypeFromJsonQuery, TJsonQuery } from '../../../packages/JsonQuery';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchRegionMetaCached } from '../fetchRegionMeta';

interface IFetchExtensionRegionMetaIfNeededParams {
  regionId: number;
  jsonQuery: TJsonQuery;
  expansionRegionCookie?: string;
}

export const fetchExtensionRegionMetaIfNeeded = async (
  context: IApplicationContext,
  parameters: IFetchExtensionRegionMetaIfNeededParams,
) => {
  const { regionId, jsonQuery, expansionRegionCookie } = parameters;

  if (!isSuburban(offerTypeFromJsonQuery(jsonQuery)) || expansionRegionCookie) {
    return null;
  }

  const extensionRegion = await fetchExtensionRegionCached(context, { regionId });

  return extensionRegion ? fetchRegionMetaCached(context, { locationId: extensionRegion.id }) : null;
};
