import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { FDealType } from '../../../../../JsonQuery';
import { ESpecialDealTypes } from '../../../types/specialDealTypes';
import { Filter } from '../common/Filter';

interface IDealType {
  label: string;
  value: FDealType | ESpecialDealTypes;
}

const DEAL_TYPES: IDealType[] = [
  { label: 'Купить', value: FDealType.Sale },
  { label: 'Снять', value: FDealType.Rent },
  { label: 'Построить', value: ESpecialDealTypes.SuburbanBuildersProjects },
];

interface IRootDealTypeProps {
  value: FDealType;
  onChange(value: FDealType): void;
}

export const RootDealType: React.FC<IRootDealTypeProps> = ({ value, onChange }) => {
  return (
    <Filter dataName="filter_rootDealType" compact>
      <RadioButtonGroup spliced options={DEAL_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
