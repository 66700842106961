import * as React from 'react';

import { RadioButtonGroup } from '../../../../../Fields/shared/components/RadioButtonGroup';
import { FDealType } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface IRentType {
  label: string;
  value: FDealType;
}

const RENT_TYPES: IRentType[] = [
  { label: 'Надолго', value: FDealType.RentLongterm },
  { label: 'Посуточно', value: FDealType.RentDaily },
];

interface IRentTypeProps {
  value: FDealType;
  onChange(value: FDealType): void;
}

export const RentType: React.FC<IRentTypeProps> = ({ value, onChange }) => {
  return (
    <Filter dataName="filter_rentType" compact>
      <RadioButtonGroup spliced options={RENT_TYPES} value={value} onChange={onChange} />
    </Filter>
  );
};
