import * as React from 'react';

import { getRoomTypes } from './utils';
import { getTermsValue, NonEmptyArray } from '../../../../../JsonQuery';
import { ERoomType } from '../../../../../api-models/common/json_query';
import { RoomType } from '../../../components/filters/RoomType';
import { useContext } from '../../../utils/useContext';

export interface IRoomType {
  label: string;
  value: ERoomType;
}

export const RoomTypeContainer: React.FC = () => {
  const { jsonQuery, onChange, areDailyrentFlatFiltersActive, areDailyrentRoomFiltersActive } = useContext();
  const roomType = getTermsValue('room')(jsonQuery);

  const roomTypes = getRoomTypes({
    areDailyrentFlatFiltersActive,
    areDailyrentRoomFiltersActive,
  });

  const handleChange = React.useCallback(
    (roomType: NonEmptyArray<ERoomType> | null) => {
      onChange({ action: 'setRoomTypes', arguments: [roomType] });
    },
    [onChange],
  );

  return <RoomType value={roomType} onChange={handleChange} roomTypes={roomTypes} />;
};
