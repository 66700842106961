import * as React from 'react';

import { NonEmptyArray } from '../../../../../Fields';
import { TModifier } from '../../../../../Fields/shared/types/modifier';
import { getTermValue } from '../../../../../JsonQuery';
import { EJsonQueryListingType } from '../../../../../api-models/common/json_query';
import { ResidentialComplexesListingType } from '../../../components/filters/ResidentialComplexesListingType';
import { useContext } from '../../../utils/useContext';

export const ResidentialComplexesListingTypeContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const listingType = getTermValue('newbuilding_results_type')(jsonQuery);

  const onListingTypeChange = React.useCallback(
    (listingType: EJsonQueryListingType | null) => {
      const modifiers: NonEmptyArray<TModifier> = [{ action: 'setListingType', arguments: [listingType] }];

      onChange(...modifiers);
    },
    [onChange],
  );

  return <ResidentialComplexesListingType listingType={listingType} onListingTypeChange={onListingTypeChange} />;
};
