import * as React from 'react';

import { getTermsValue } from '../../../../../JsonQuery';
import { ContainWords } from '../../../components/filters/ContainWords';
import { useContext } from '../../../utils/useContext';

export const ContainWordsContainer: React.FC = () => {
  const {
    jsonQuery,
    onChange,
    areDailyrentRoomFiltersActive,
    areDailyrentSuburbanFiltersActive,
    areDailyrentFlatFiltersActive,
  } = useContext();

  const isDailyrent =
    areDailyrentRoomFiltersActive || areDailyrentFlatFiltersActive || areDailyrentSuburbanFiltersActive;

  const containWords = getTermsValue('description_include')(jsonQuery);

  const handleChange = React.useCallback(
    (containWords: string[] | null) => {
      onChange({ action: 'setContainWords', arguments: [containWords] });
    },
    [onChange],
  );

  const label = isDailyrent ? 'Слова в описании' : 'Поиск по описанию';
  const placeholder = isDailyrent ? 'Например: море' : 'Например: срочно';
  const subtitle = isDailyrent ? 'Искать со словами' : undefined;

  return (
    <ContainWords
      label={label}
      placeholder={placeholder}
      subtitle={subtitle}
      value={containWords}
      onChange={handleChange}
    />
  );
};
