import * as React from 'react';

import { IPriceFieldProps, PriceField } from '../../../../../Fields/shared/components/PriceField';
import { GoodPrice } from '../GoodPrice';
import { Filter, FilterRow } from '../common/Filter';

interface IPriceFieldComponentProps extends IPriceFieldProps {
  label: string;
  hasGoodPrice: boolean | null;
  showHasGoodPriceFilter: boolean;
  onHasGoodPriceChange: (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => void;
}

export const Price: React.FC<IPriceFieldComponentProps> = ({
  label,
  hasGoodPrice,
  showHasGoodPriceFilter,
  onHasGoodPriceChange,
  ...restProps
}) => {
  return (
    <Filter id="filter_price" label={label}>
      <PriceField {...restProps} />

      {showHasGoodPriceFilter && (
        <FilterRow>
          <GoodPrice value={hasGoodPrice} onChange={onHasGoodPriceChange} />
        </FilterRow>
      )}
    </Filter>
  );
};
