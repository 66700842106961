import * as React from 'react';

import { CellGroup } from '../../../../../Fields/shared/components/CellGroup';
import { Filter } from '../common/Filter';

interface IGeoProps {
  undergroundMap?: React.ReactNode;
  catalogSelect?: React.ReactNode;
  geoSuggest?: React.ReactNode;
  tags?: React.ReactNode;
  regionalDistricts?: React.ReactNode;
}

export const Geo: React.FC<IGeoProps> = ({ tags, undergroundMap, catalogSelect, geoSuggest, regionalDistricts }) => {
  return (
    <Filter id="filter_geo" contentPadding={false} label="Расположение">
      <CellGroup>
        {regionalDistricts}
        {undergroundMap}
        {catalogSelect}
        {geoSuggest}
        {/* <CellEntry onClick={() => {}}>Область на карте</CellEntry> */}
      </CellGroup>
      {tags}
    </Filter>
  );
};
