import * as R from 'ramda';

import {
  FILTERS_RESIDENTIAL_COMPLEXES,
  FILTERS_STATIC,
  FILTERS_SUBURBAN,
  FILTERS_URBAN,
  REDESIGN_FILTERS_URBAN,
  REDUCED_DAILYRENT_FLAT_FILTERS,
  REDUCED_DAILYRENT_ROOM_FILTERS,
  REDUCED_DAILYRENT_SUBURBAN_FILTERS,
} from './constants/filters';
import { FILTERS_GARAGES } from './constants/filters/garages';
import { IFilterList } from './types';
import { FDealType, FOfferType, isAvailable, TJsonQuery } from '../../../../JsonQuery';
import { TFilter } from '../../types/filters';

const FILTERS_LIST: IFilterList[] = [
  ...FILTERS_RESIDENTIAL_COMPLEXES,
  ...FILTERS_URBAN,
  ...FILTERS_SUBURBAN,
  ...FILTERS_GARAGES,
  {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    list: ['containWords', 'excludeWords', 'aboutOffer'],
  },
];

const REDESIGN_FILTERS_LIST: IFilterList[] = [
  ...FILTERS_RESIDENTIAL_COMPLEXES,
  ...REDESIGN_FILTERS_URBAN,
  ...FILTERS_SUBURBAN,
  ...FILTERS_GARAGES,
  {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    list: ['containWords', 'excludeWords', 'aboutOffer'],
  },
];

interface IGetAvailableFiltersParams {
  jsonQuery: TJsonQuery;
  isVillageMortgageFilterEnabled?: boolean;
  areDailyrentRoomFiltersActive: boolean;
  areDailyrentFlatFiltersActive: boolean;
  areDailyrentSuburbanFiltersActive: boolean;
  isMixedFiltersRedesign?: boolean;
}

export function getAvailableFilters({
  jsonQuery,
  isVillageMortgageFilterEnabled,
  areDailyrentRoomFiltersActive,
  areDailyrentFlatFiltersActive,
  areDailyrentSuburbanFiltersActive,
  isMixedFiltersRedesign,
}: IGetAvailableFiltersParams): TFilter[] {
  const filtersList = isMixedFiltersRedesign ? REDESIGN_FILTERS_LIST : FILTERS_LIST;

  const filters = filtersList.find(f => f.availability(jsonQuery));

  let availableFilters: TFilter[] = [];

  if (filters && filters.list) {
    availableFilters = FILTERS_STATIC.concat(filters.list);
  }

  if (areDailyrentFlatFiltersActive) {
    availableFilters = FILTERS_STATIC.concat(REDUCED_DAILYRENT_FLAT_FILTERS);
  }

  if (areDailyrentRoomFiltersActive) {
    availableFilters = FILTERS_STATIC.concat(REDUCED_DAILYRENT_ROOM_FILTERS);
  }

  if (areDailyrentSuburbanFiltersActive) {
    availableFilters = FILTERS_STATIC.concat(REDUCED_DAILYRENT_SUBURBAN_FILTERS);
  }

  if (isVillageMortgageFilterEnabled && isAvailable(FDealType.Sale, FOfferType.Suburban)(jsonQuery)) {
    const priceIndex = availableFilters.indexOf('price');

    if (priceIndex >= 0) {
      return R.insert(priceIndex, 'villageMortgage', availableFilters);
    }
  }

  return availableFilters;
}
