import { ca } from '@cian/analytics';

export function trackSearchClick(queryString: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'search',
    label: queryString,
  });
}

export function trackSearchOnMapClick(queryString: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Mob_refineSearch_popup',
    action: 'search_on_map',
    label: queryString,
  });
}
