import { Input } from '@cian/ui-kit/input';
import { InputAdornmentButton } from '@cian/ui-kit/inputAdornment';
import { ITagOption, Tags } from '@cian/ui-kit/tags';
import cx from 'classnames';
import * as React from 'react';

import * as styles from './TagsInput.css';

interface ITagsInputProps {
  id: string;
  placeholder?: string;
  maxLength?: number;
  value: [string, ...string[]] | null;
  onAdd(value: string): void;
  onRemove(value: string): void;
}

export const TagsInput: React.FC<ITagsInputProps> = ({
  id,
  placeholder,
  maxLength = 30,
  value: values,
  onAdd,
  onRemove,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string>('');

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value },
      } = event;

      setValue(value);
    },
    [setValue],
  );

  const handleAdd = React.useCallback(() => {
    const trimmedValue = value.trim();

    if (!trimmedValue || values?.some(v => v === trimmedValue)) {
      return;
    }

    onAdd(trimmedValue);
    setValue('');

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [values, value, onAdd, setValue]);

  const handleKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleAdd();
      }
    },
    [handleAdd],
  );

  const handleTagClose = React.useCallback(
    (option: ITagOption) => {
      onRemove(option.value);
    },
    [onRemove],
  );

  return (
    <div>
      <div id={`${id}_input`} className={cx(!!values && styles['input-container--with-tags'])}>
        <Input
          ref={inputRef}
          rightAdornment={
            <InputAdornmentButton aria-controls={`${id}_input`} aria-keyshortcuts="Enter" onClick={handleAdd}>
              Добавить
            </InputAdornmentButton>
          }
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
        />
      </div>
      {values && <Tags options={values.map(v => ({ label: v, value: v }))} onClose={handleTagClose} />}
    </div>
  );
};
