import { ILogger } from '@cian/logger';
import loadable from '@loadable/component';
import * as React from 'react';

import { IStationData } from '../../../../../../../../Fields/shared/types/underground';
import { abbrLowercaseFirstLetter } from '../../../../../../../../Fields/shared/utils/string';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../../../../../LoadableHelpers';
import { ModalWindow } from '../../../../../../../../ModalWindow';
import { ActionButtons, HeaderBackable } from '../../../../../ModalWindow';

import type { TUndergroundMapSectionProps } from '../UndergroundMapSection/UndergroundMapSection';

export type TUndergroundMapModalProps = Omit<TUndergroundMapSectionProps, 'onSelect' | 'onRemove'> & {
  logger: ILogger;
  open: boolean;
  title: string;
  onAdd(stations: IStationData[]): void;
  onRemove(stations: IStationData[]): void;
  onApply(): void;
  onReset(): void;
  onClose(): void;
};

const UndergroundMapLoadable = loadable(() => import('../UndergroundMapSection/UndergroundMapSection'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export const UndergroundMapModal: React.FC<TUndergroundMapModalProps> = ({
  logger,
  open,
  title,
  onClose,
  onAdd,
  onReset,
  onApply,
  onRemove,
  ...sharedProps
}) => {
  const modalWindowRef = React.createRef<ModalWindow>();
  const lowercasedTitle = React.useMemo(() => abbrLowercaseFirstLetter(title), [title]);

  const handleRetry = React.useCallback(() => {
    UndergroundMapLoadable.load();
  }, []);

  return (
    <ModalWindow
      fixed
      ref={modalWindowRef}
      header={<HeaderBackable title={`Выберите ${lowercasedTitle}`} onBack={onClose} />}
      footer={<ActionButtons onApply={onApply} onReset={onReset} />}
      open={open}
      onClose={onClose}
      portal
    >
      <LoadableErrorBoundary logger={logger} onRetry={handleRetry}>
        <UndergroundMapLoadable onSelect={onAdd} onRemove={onRemove} {...sharedProps} />
      </LoadableErrorBoundary>
    </ModalWindow>
  );
};
