import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './ContainWords.css';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { TagsInput } from '../../TagsInput';
import { Filter } from '../common/Filter';

interface IContainWordsProps {
  label: string;
  placeholder: string;
  subtitle?: string;
  value: NonEmptyArray<string> | null;
  onChange(value: NonEmptyArray<string> | null): void;
}

export const ContainWords: React.FC<IContainWordsProps> = ({
  label,
  placeholder,
  subtitle,
  value: values,
  onChange,
}) => {
  const handleAdd = React.useCallback(
    (value: string) => {
      const newValues: NonEmptyArray<string> = [value];

      switch (value.toLowerCase()) {
        case 'кот':
        case 'кошка':
        case 'котик':
        case 'коты':
        case 'котики':
          if (!values || !values.includes('🐈')) {
            newValues.push('🐈');
          }

          break;
      }

      onChange(values ? [...values, ...newValues] : newValues);
    },
    [values, onChange],
  );

  const handleRemove = React.useCallback(
    (value: string) => {
      let nextValue = values ? values.filter(v => v !== value) : null;
      if (nextValue && nextValue.length === 0) {
        nextValue = null;
      }

      onChange(nextValue as NonEmptyArray<string>);
    },
    [values, onChange],
  );

  return (
    <Filter id="filter_containWords" label={label}>
      {subtitle && (
        <span className={styles['subtitle']}>
          <UIText2>Искать со словами</UIText2>
        </span>
      )}
      <TagsInput
        id="filter_containWords_tagsInput"
        placeholder={placeholder}
        value={values}
        onAdd={handleAdd}
        onRemove={handleRemove}
      />
    </Filter>
  );
};
