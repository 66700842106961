import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import { ValidationError } from '@cian/peperrors/shared';

import { IUndergroundJSONDataPath } from '../../../../Fields/shared/types/underground';

import type { IUndergroundMapDataV2 } from '@cian/underground-map-widget';

interface IParams {
  locationId: number;
}

interface IContext {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
}

export const fetchUndergroundMapData = async (
  { logger, config, httpApi }: IContext,
  { locationId }: IParams,
): Promise<IUndergroundMapDataV2> => {
  const newMapJsonPath = config.getStrict<IUndergroundJSONDataPath>('undergroundMapDataV2');

  if (!newMapJsonPath.hasOwnProperty(locationId)) {
    throw new ValidationError({
      message: `Для locationId: ${locationId} нет пути к JSON файлу в настройке undergroundMapData`,
      domain: 'fetchUndergroundMapData',
    });
  }

  try {
    const url = new URL(newMapJsonPath[locationId]);

    const response = await httpApi.httpRequest({
      uri: { path: url.pathname, host: url.host },
      method: 'GET',
      withCredentials: false,
    });

    return JSON.parse(response.responseBody) as IUndergroundMapDataV2;
  } catch (e) {
    logger.error(e, {
      domain: 'fetchUndergroundMapData',
    });

    throw e;
  }
};
