import * as React from 'react';

import { ERentTime, getTermValue } from '../../../../../JsonQuery';
import { DEFAULT_LONGTERM_RENT_OPTION } from '../../../../../JsonQuery/constants';
import { RentalPeriod } from '../../../components/filters/RentalPeriod';
import { useContext } from '../../../utils/useContext';

export const RentalPeriodContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  let rentalPeriod = getTermValue('for_day')(jsonQuery);
  if (rentalPeriod === null) {
    rentalPeriod = DEFAULT_LONGTERM_RENT_OPTION;
  }

  const handleChange = React.useCallback(
    (rentalPeriod: ERentTime) => {
      onChange({ action: 'setRentalPeriod', arguments: [rentalPeriod] });
    },
    [onChange],
  );

  return <RentalPeriod value={rentalPeriod} onChange={handleChange} />;
};
