import * as React from 'react';

import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

enum ESuburbanFloors {
  One = 1,
  TwoAndMore = 2,
}

interface ISuburbanFloors {
  label: string;
  value: ESuburbanFloors;
}

const SUBURBAN_FLOORS: ISuburbanFloors[] = [
  { label: '1', value: ESuburbanFloors.One },
  { label: '2 и более', value: ESuburbanFloors.TwoAndMore },
];

interface ISuburbanFloorsProps {
  value: IJsonQueryRangeValue | null;
  onChange(gte: number | null, lte: number | null): void;
}

export const SuburbanFloors: React.FC<ISuburbanFloorsProps> = ({ value: valueRaw, onChange }) => {
  const value = React.useMemo<ESuburbanFloors | null>(() => valueRaw && rangeValueToOption(valueRaw), [valueRaw]);

  const handleChange = useDebouncedCallback((suburbanFloors: ESuburbanFloors | null) => {
    let gte: number | null = null;
    let lte: number | null = null;

    switch (suburbanFloors) {
      case ESuburbanFloors.One:
        gte = 1;
        lte = 1;
        break;
      case ESuburbanFloors.TwoAndMore:
        gte = 2;
        break;
    }

    onChange(gte, lte);
  }, 300);

  return (
    <Filter id="filter_suburbanFloors" label="Этажей в доме">
      <RadioButtonGroupUnselectable options={SUBURBAN_FLOORS} value={value} onChange={handleChange} />
    </Filter>
  );
};

function rangeValueToOption(value: IJsonQueryRangeValue): ESuburbanFloors | null {
  const { gte: floorsMin = null, lte: floorsMax = null } = value;

  switch (true) {
    case floorsMin === 1 && floorsMax === 1:
      return ESuburbanFloors.One;
    case floorsMin === 2 && floorsMax === null:
      return ESuburbanFloors.TwoAndMore;
  }

  return null;
}
