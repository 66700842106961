import { UIText1, UIText2 } from '@cian/ui-kit/typography';
import { IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import { numberToPrettyString, plural } from '@cian/utils';
import { isNil } from 'ramda';
import * as React from 'react';

import * as styles from './BannerCommercialSearch.css';

interface IBannerCommercialSearchProps {
  offersCount?: number | null;
  filtersLink: string;
  onClick?(): void;
}

export const BannerCommercialSearch: React.FC<IBannerCommercialSearchProps> = ({
  offersCount,
  filtersLink,
  onClick,
}) => {
  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <a href={filtersLink} className={styles['container']} onClick={handleClick}>
      <div className={styles['content']}>
        <UIText1>Коммерческая недвижимость</UIText1>
        {!isNil(offersCount) && (
          <UIText2 color="gray60_100">
            {numberToPrettyString(offersCount)} {plural(offersCount, ['объект', 'объекта', 'объектов'])} на Циан
          </UIText2>
        )}
      </div>
      <IconActionChevronRight16 color="gray40_100" />
    </a>
  );
};
