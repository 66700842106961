import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import * as React from 'react';

import { TPromoSearchTypes } from '../../../../shared/types/promoSearchTypes';
import { IRegionalDistrictsList } from '../../../../shared/types/regionalDistricts';
import { NonEmptyArray, TModifier } from '../../../Fields';
import { IRegionMeta } from '../../../Fields/shared/types/regionMeta';
import { IRegion } from '../../../Fields/shared/types/regions';
import { TJsonQuery } from '../../../JsonQuery';
import { IDatesModal } from '../types/datesModal';
import { IGeoTags } from '../types/geoTags';
import { TPlaceholders } from '../types/placeholders';
import { ISpecialPromo } from '../types/specialPromo';

export interface IContext {
  logger: ILogger;
  config: IConfig;
  httpApi: IHttpApi;
  subdomain: string;
  currentYear: number;
  regions: IRegion[];
  geoTags: IGeoTags;
  jsonQuery: TJsonQuery;
  regionMeta: IRegionMeta;
  specialPromo: ISpecialPromo | null;
  isVillageMortgageFilterEnabled: boolean;
  onChange(...modifiers: NonEmptyArray<TModifier>): void;
  regionalDistricts: IRegionalDistrictsList;
  datesModal: IDatesModal;
  areDailyrentFlatFiltersActive: boolean;
  areDailyrentRoomFiltersActive: boolean;
  areDailyrentSuburbanFiltersActive: boolean;
  promoSearchTypes: TPromoSearchTypes;
  placeholders: TPlaceholders;
  isGoodPriceFilterEnabled: boolean;
  isMixedFiltersRedesign: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const context = React.createContext<IContext>(null as any);
