import * as React from 'react';

import { ECompletionYearType, JsonQuery, NonEmptyArray } from '../../../../../JsonQuery';
import { CompletionYear } from '../../../components/filters/CompletionYear';
import { AVAILABLE_COMPLETION_YEAR_TYPES } from '../../../constants/availableCompletionYearTypes';
import { useContext } from '../../../utils/useContext';

export const CompletionYearContainer: React.FC = () => {
  const { currentYear, jsonQuery, onChange } = useContext();
  const completionYearTypes = new JsonQuery(jsonQuery).getCompletionYearTypes({
    yearOffset: currentYear,
    availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
  });

  const handleChange = React.useCallback(
    (completionYearTypes: NonEmptyArray<ECompletionYearType> | null) => {
      onChange({
        action: 'setCompletionYearTypes',
        arguments: [
          {
            completionYearTypes,
            yearOffset: currentYear,
            availableYearTypes: AVAILABLE_COMPLETION_YEAR_TYPES,
          },
        ],
      });
    },
    [currentYear, onChange],
  );

  return <CompletionYear yearOffset={currentYear} value={completionYearTypes} onChange={handleChange} />;
};
