import * as React from 'react';

import { IRegionSelectOption } from '../types';

export function renderLabel(option: IRegionSelectOption): React.ReactChild {
  const { label, matches } = option;

  if (!matches || matches.length < 1) {
    return label;
  }

  const parts: React.ReactChild[] = [];
  matches.forEach((match, index) => {
    const previousLastIndex = (matches[index - 1] && matches[index - 1][1]) ?? -1;
    if (match[0] > previousLastIndex + 1) {
      parts.push(<span>{label.substring(previousLastIndex + 1, match[0])}</span>);
    }

    parts.push(<strong>{label.substring(match[0], match[1] + 1)}</strong>);
  });

  const lastMatchedIndex = matches[matches.length - 1][1];
  if (label.length - 1 > lastMatchedIndex) {
    parts.push(<span>{label.substring(lastMatchedIndex + 1)}</span>);
  }

  return (
    <span>
      {parts.map((item, index) => (
        <React.Fragment key={`token_${index}`}>{item}</React.Fragment>
      ))}
    </span>
  );
}
