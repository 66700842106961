import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FooterContainer } from './containers/FooterContainer';
import { HeaderContainer } from './containers/HeaderContainer';
import { trackRegionExpansion } from './tracking';
import { useMakeSearch } from './utils/useMakeSearch';
import { Filters as FiltersComponent, IOnChangeParameters, Provider } from '../../../packages/Filters';
import { IDatesModal } from '../../../packages/Filters/shared/types/datesModal';
import { isSuburban, offerTypeFromJsonQuery } from '../../../packages/JsonQuery';
import { changeJsonQuery } from '../../actions/filters';
import {
  closeDatesModal as closeDatesModalAction,
  openDatesModal as openDatesModalAction,
} from '../../actions/filters/datesModal';
import { Filters as FiltersWrapper } from '../../components/Filters';
import { EXPANSION_REGION_DISABLE_COOKIE } from '../../constants/region';
import { selectDatesModalState } from '../../selectors/datesModal/selectDatesModalState';
import { selectIsGoodPriceFilterEnabled } from '../../selectors/experiments/selectIsGoodPriceFilterEnabled';
import { selectIsMixedFiltersRedesignExperimentEnabled } from '../../selectors/experiments/selectIsMixedFiltersRedesignExperimentEnabled';
import { selectGeoTags } from '../../selectors/geoTags';
import { selectIsVillageMortgageFilterEnabled, selectJsonQuery } from '../../selectors/jsonQuery';
import { selectPromoSearchTypes } from '../../selectors/promoSearchTypes';
import { selectRegionMeta } from '../../selectors/regionMeta';
import { selectRegionalDistricts } from '../../selectors/regionalDistricts';
import { selectRegions } from '../../selectors/regions';
import { selectCurrentSpecialPromo } from '../../selectors/specialPromos';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { ESearchMetaStatus, ISearchMetaState } from '../../types/searchMeta';
import { useApplicationContext } from '../../utils/applicationContext';
import { getCookie, setCookie } from '../../utils/cookies';
import { BannerApplicationDownloadContainer } from '../BannerApplicationDownloadContainer';
import { BannerCommercialSearchContainer } from '../BannerCommercialSearchContainer';
import { SaveSearchContainer } from '../SaveSearchContainer';

export const FiltersContainer: React.FC = () => {
  const {
    logger,
    config,
    httpApi,
    custom: { subdomain, currentYear },
  } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const geoTags = useSelector(selectGeoTags);
  const jsonQuery = useSelector(selectJsonQuery);
  const regions = useSelector(selectRegions);
  const searchMeta = useSelector<IApplicationState, ISearchMetaState>(state => state.searchMeta);
  const regionMeta = useSelector(selectRegionMeta);
  const specialPromo = useSelector(selectCurrentSpecialPromo);
  const isVillageMortgageFilterEnabled = useSelector(selectIsVillageMortgageFilterEnabled);
  const regionalDistricts = useSelector(selectRegionalDistricts);
  const promoSearchTypes = useSelector(selectPromoSearchTypes);
  const datesModalState = useSelector(selectDatesModalState);
  const makeSearch = useMakeSearch();
  const isGoodPriceFilterEnabled = useSelector(selectIsGoodPriceFilterEnabled);
  const isMixedFiltersRedesignExperimentEnabled = useSelector(selectIsMixedFiltersRedesignExperimentEnabled);

  const openDatesModal = React.useCallback(() => {
    dispatch(openDatesModalAction({ isAutoOpen: false }));
  }, [dispatch]);

  const closeDatesModal = React.useCallback(async () => {
    if (datesModalState.isAutoOpenTriggered) {
      makeSearch({ toMap: true });

      return;
    }

    dispatch(closeDatesModalAction());
  }, [dispatch, datesModalState.isAutoOpenTriggered]);

  const applyDatesModal = React.useCallback(async () => {
    if (!datesModalState.isAutoOpenTriggered) {
      dispatch(closeDatesModalAction());
    }
  }, [dispatch, datesModalState.isAutoOpenTriggered]);

  const handleChange = React.useCallback(
    async ({ nextJsonQuery, appliedModifiers }: IOnChangeParameters) => {
      if (
        appliedModifiers.some(m => m.action === 'setRegion') &&
        isSuburban(offerTypeFromJsonQuery(nextJsonQuery)) &&
        !getCookie(EXPANSION_REGION_DISABLE_COOKIE)
      ) {
        setCookie(EXPANSION_REGION_DISABLE_COOKIE, '1');
      }

      const nextSearchMeta = await dispatch(
        changeJsonQuery({ prevJsonQuery: jsonQuery, appliedModifiers, nextJsonQuery }),
      );
      const searchMetaState = nextSearchMeta
        ? {
            status: nextSearchMeta ? ESearchMetaStatus.Succeed : ESearchMetaStatus.Failed,
            ...(nextSearchMeta || nextSearchMeta),
          }
        : null;

      if (datesModalState.isAutoOpenTriggered && nextSearchMeta) {
        makeSearch({
          toMap: true,
          searchMeta: searchMetaState,
        });
      }
    },
    [dispatch, jsonQuery, datesModalState.isAutoOpenTriggered],
  );

  const datesModalContext: IDatesModal = React.useMemo(
    () => ({
      isOpen: datesModalState.isOpen,
      isLoading: searchMeta.status === ESearchMetaStatus.Loading,
      openModal: openDatesModal,
      closeModal: closeDatesModal,
      onApply: applyDatesModal,
    }),
    [datesModalState.isOpen, openDatesModal, closeDatesModal, searchMeta.status],
  );

  React.useEffect(() => {
    if (regionMeta.expansibleRegion && !getCookie(EXPANSION_REGION_DISABLE_COOKIE)) {
      trackRegionExpansion(regionMeta);
    }
  }, [regionMeta]);

  return (
    <FiltersWrapper
      header={<HeaderContainer />}
      content={
        <>
          <BannerCommercialSearchContainer />
          <Provider
            logger={logger}
            config={config}
            httpApi={httpApi}
            subdomain={subdomain}
            currentYear={currentYear}
            regions={regions}
            geoTags={geoTags}
            jsonQuery={jsonQuery}
            regionMeta={regionMeta}
            specialPromo={specialPromo}
            datesModal={datesModalContext}
            onChange={handleChange}
            isVillageMortgageFilterEnabled={isVillageMortgageFilterEnabled}
            regionalDistricts={regionalDistricts}
            promoSearchTypes={promoSearchTypes}
            placeholders={searchMeta.placeholders}
            isGoodPriceFilterEnabled={isGoodPriceFilterEnabled}
            isMixedFiltersRedesign={isMixedFiltersRedesignExperimentEnabled}
          >
            <FiltersComponent />
          </Provider>
          <SaveSearchContainer />
          <BannerApplicationDownloadContainer />
        </>
      }
      footer={<FooterContainer />}
    />
  );
};
