import { selectExperiments } from './selectExperiments';
import { DAILY_RENT_OTA_CONVERSION } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';
import { isExperimentActive } from '../../utils/experiments';

export const selectIsDailyRentOtaConversionExperimentActive = (state: IApplicationState): boolean => {
  const experiments = selectExperiments(state);

  return isExperimentActive(experiments, DAILY_RENT_OTA_CONVERSION, 'B');
};
