import { IconActionSearch16 } from '@cian/ui-kit-design-tokens/icons';
import loadable from '@loadable/component';
import * as React from 'react';

import { getTitleByOfferType } from './getTitleByOfferType';
import { CellEntry } from '../../../../../../../Fields/shared/components/CellGroup';
import { LoadableErrorBoundary, LoadableFallback } from '../../../../../../../LoadableHelpers';
import { ModalWindow } from '../../../../../../../ModalWindow';
import { HeaderBackable } from '../../../../../components/ModalWindow';
import { useContext } from '../../../../../utils/useContext';

const GeoSuggestModalContentLoadable = loadable(() => import('./GeoSuggestModalContent'), {
  fallback: <LoadableFallback />,
  ssr: false,
});

export function GeoSuggestContainer() {
  const { logger, jsonQuery } = useContext();
  const [open, setOpen] = React.useState(false);

  const title = React.useMemo(() => getTitleByOfferType(jsonQuery), [jsonQuery]);

  const handleRetryLoadContent = React.useCallback(() => {
    GeoSuggestModalContentLoadable.load();
  }, []);

  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <CellEntry icon={IconActionSearch16} onClick={() => setOpen(true)}>
        {title}
      </CellEntry>
      <ModalWindow
        header={<HeaderBackable title={title} onBack={handleClose} />}
        open={open}
        onClose={handleClose}
        portal
      >
        <LoadableErrorBoundary logger={logger} onRetry={handleRetryLoadContent}>
          <GeoSuggestModalContentLoadable onSelect={handleClose} />
        </LoadableErrorBoundary>
      </ModalWindow>
    </>
  );
}
