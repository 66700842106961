/* eslint-disable */
//tslint:disable

export interface IAddressNodeSchema {
  /** Полное имя **/
  fullName: string;
  /** Тип **/
  geoType: EGeoType;
  /** ID **/
  id: number;
  /** Имя **/
  name: string;
}

export enum EGeoType {
  /** Страна **/
  Country = 'country',
  /** Район **/
  District = 'district',
  /** Дом **/
  House = 'house',
  /** Местоположение **/
  Location = 'location',
  /** ЖД **/
  Railway = 'railway',
  /** Шоссе **/
  Road = 'road',
  /** Улица **/
  Street = 'street',
  /** Метро **/
  Underground = 'underground',
}
