import { aboutOffer } from './aboutOffer';
import { apartments } from './apartments';
import { balcony } from './balcony';
import { bathType } from './bathType';
import { bedrooms } from './bedrooms';
import { beds } from './beds';
import { ceilingHeight } from './ceilingHeight';
import { completionYear } from './completionYear';
import { constructionType } from './constructionType';
import { constructionYear } from './constructionYear';
import { containWords } from './containWords';
import { dailyrentFloor } from './dailyrentFloor';
import { dates } from './dates';
import { dealType } from './dealType';
import { decorationsList } from './decorationsList';
import { demolition } from './demolition';
import { discountPromoSearchType } from './discountPromoSearchType';
import { electronicTrading } from './electronicTrading';
import { elevators } from './elevators';
import { excludeWords } from './excludeWords';
import { facilities } from './facilities';
import { finishing } from './finishing';
import { flatRooms } from './flatRooms';
import { flatShare } from './flatShare';
import { flatType } from './flatType';
import { floor } from './floor';
import { floors } from './floors';
import { fromMKAD } from './fromMKAD';
import { garageStatus } from './garageStatus';
import { garageType } from './garageType';
import { garageUtilities } from './garageUtilities';
import { geo } from './geo';
import { heatingType } from './heatingType';
import { houseArea } from './houseArea';
import { isEncumbranceRealty } from './isEncumbranceRealty';
import { kitchenStove } from './kitchenStove';
import { landArea } from './landArea';
import { landStatus } from './landStatus';
import { layout } from './layout';
import { livingConditions } from './livingConditions';
import { mortgagePromoSearchType } from './mortgagePromoSearchType';
import { offerType } from './offerType';
import { onlineBooking } from './onlineBooking';
import { parking } from './parking';
import { possessor } from './possessor';
import { price } from './price';
import { region } from './region';
import { renovation } from './renovation';
import { rentTerms } from './rentTerms';
import { rentType } from './rentType';
import { rentalPeriod } from './rentalPeriod';
import { repair } from './repair';
import { residentialComplexesListingType } from './residentialComplexesListingType';
import { residentialComplexesSpecial } from './residentialComplexesSpecial';
import { residentialComplexesUpcomingSale } from './residentialComplexesUpcomingSale';
import { roomType } from './roomType';
import { roomsInDeal } from './roomsInDeal';
import { rootDealType } from './rootDealType';
import { rootOfferType } from './rootOfferType';
import { saleType } from './saleType';
import { squareMeterArea } from './squareMeterArea';
import { subsidisedMortgage } from './subsidisedMortgage';
import { suburbanBathroom } from './suburbanBathroom';
import { suburbanConstructionType } from './suburbanConstructionType';
import { suburbanFacilities } from './suburbanFacilities';
import { suburbanFloors } from './suburbanFloors';
import { suburbanHouseType } from './suburbanHouseType';
import { suburbanUtilities } from './suburbanUtilities';
import { totalArea } from './totalArea';
import { undergroundTime } from './undergroundTime';
import { urbanBathroom } from './urbanBathroom';
import { villageMortgage } from './villageMortgage';
import { windows } from './windows';
import { IFilter, TFilter } from '../../types/filters';

export const FILTERS: { [key in TFilter]: IFilter } = {
  aboutOffer,
  apartments,
  flatShare,
  balcony,
  bathType,
  bedrooms,
  beds,
  ceilingHeight,
  completionYear,
  constructionType,
  constructionYear,
  containWords,
  dates,
  dealType,
  demolition,
  electronicTrading,
  elevators,
  excludeWords,
  facilities,
  finishing,
  flatRooms,
  flatType,
  floor,
  floors,
  fromMKAD,
  garageStatus,
  garageType,
  garageUtilities,
  geo,
  heatingType,
  houseArea,
  isEncumbranceRealty,
  kitchenStove,
  landArea,
  landStatus,
  layout,
  livingConditions,
  offerType,
  onlineBooking,
  parking,
  possessor,
  price,
  region,
  rentTerms,
  rentalPeriod,
  repair,
  residentialComplexesSpecial,
  residentialComplexesUpcomingSale,
  residentialComplexesListingType,
  roomType,
  roomsInDeal,
  rootDealType,
  rootOfferType,
  saleType,
  squareMeterArea,
  suburbanBathroom,
  suburbanHouseType,
  suburbanConstructionType,
  suburbanFacilities,
  suburbanFloors,
  suburbanUtilities,
  subsidisedMortgage,
  totalArea,
  undergroundTime,
  urbanBathroom,
  windows,
  decorationsList,
  villageMortgage,
  rentType,
  dailyrentFloor,
  discountPromoSearchType,
  mortgagePromoSearchType,
  renovation,
};
