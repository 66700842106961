import * as React from 'react';

import { getSuburbanFacilitiesList } from './helpers';
import { CheckboxButtonGroup } from '../../../../../Fields/shared/components/CheckboxButtonGroup';
import { EFacilityType, FDealType, NonEmptyArray } from '../../../../../JsonQuery';
import { Filter } from '../common/Filter';

interface ISuburbanFacilitiesProps {
  label: string;
  dealType: FDealType;
  value: NonEmptyArray<EFacilityType> | null;
  onChange(value: NonEmptyArray<EFacilityType> | null): void;
}

export const SuburbanFacilities: React.FC<ISuburbanFacilitiesProps> = ({ label, dealType, value, onChange }) => {
  const suburbanFacilities = getSuburbanFacilitiesList(dealType);

  return (
    <Filter id="filter_suburbanFacilities" label={label}>
      <CheckboxButtonGroup options={suburbanFacilities} value={value} onChange={onChange} />
    </Filter>
  );
};
