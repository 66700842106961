import * as React from 'react';

import { RadioButtonGroupUnselectable } from '../../RadioButtonGroupUnselectable';
import { Filter } from '../common/Filter';

interface IDemolitionOption {
  label: string;
  value: boolean;
}

const DEMOLITION_OPTIONS: IDemolitionOption[] = [
  { label: 'Не показывать дома под снос', value: false },
  { label: 'Только дома под снос', value: true },
];

interface IDemolitionProps {
  value: boolean | null;
  onChange(value: boolean | null): void;
}

export const Demolition: React.FC<IDemolitionProps> = ({ value, onChange }) => {
  return (
    <Filter id="filter_demolition" label="Реновация">
      <RadioButtonGroupUnselectable options={DEMOLITION_OPTIONS} value={value} onChange={onChange} spliced={false} />
    </Filter>
  );
};
