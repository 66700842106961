import * as React from 'react';

import { RangeInput } from '../../../../../Fields/shared/components/RangeInput';
import { useDebouncedCallback } from '../../../../../Fields/shared/utils/useDebouncedCallback';
import { IJsonQueryRangeValue } from '../../../../../api-models/common/json_query';
import { SHRUG } from '../../../constants/shrug';
import { Filter } from '../common/Filter';

interface ILandAreaProps {
  landArea: IJsonQueryRangeValue | null;
  onLandAreaMinChange(gte: number | null): void;
  onLandAreaMaxChange(lte: number | null): void;
}

export const LandArea: React.FC<ILandAreaProps> = ({ landArea, onLandAreaMinChange, onLandAreaMaxChange }) => {
  const { gte: landAreaMin = null, lte: landAreaMax = null } = landArea || {};

  const handleLandAreaMinChange = useDebouncedCallback((gte: number | null) => {
    onLandAreaMinChange(gte);
  }, 300);

  const handleLandAreaMaxChange = useDebouncedCallback((lte: number | null) => {
    onLandAreaMaxChange(lte);
  }, 300);

  return (
    <Filter id="filter_landArea" label={`Площадь участка${landAreaMin === 0 && landAreaMax === 0 ? ` ${SHRUG}` : ''}`}>
      <RangeInput
        min={0}
        max={99999}
        joined={false}
        postfix="сот"
        valueMin={landAreaMin}
        valueMax={landAreaMax}
        onChangeMin={handleLandAreaMinChange}
        onChangeMax={handleLandAreaMaxChange}
      />
    </Filter>
  );
};
