import { ECompletionYearType } from '../../../../../JsonQuery/types';
import { AVAILABLE_COMPLETION_YEAR_TYPES } from '../../../constants/availableCompletionYearTypes';

interface ICompletionYearOption {
  label: string;
  value: ECompletionYearType;
}

export function getCompletionYearOptions(yearOffset: number): ICompletionYearOption[] {
  const yearOptions = AVAILABLE_COMPLETION_YEAR_TYPES.map(value => ({
    label: String(yearOffset + (value as number)),
    value,
  }));

  return [
    { label: 'Сдан', value: ECompletionYearType.HandedOver },
    ...yearOptions,
    { label: 'Позднее', value: ECompletionYearType.Later },
  ];
}
